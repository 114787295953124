import PackageCard from "../components/PackageCard";
import premium from "./images/diamond.png";
import Header from "./Header";
import cross from "../components/images/cross.png"
import tickSign from "../components/images/tick-sign.png"

const OnlineCoachingPage = () => {
  return (
    <div>
      <Header fixed={true} />
      <div className="flex p-4 pt-24 justify-evenly flex-wrap bg-gray-100">
        <PackageCard
          price="249 جنيها/شهر"
          type="أساسي"
          bg=" bg-[#660708] "
          shine="bg-red-900"
          signOne={cross}
          signTwo={cross}
          signThree={cross}
        />
        <PackageCard
          price="449 جنيها/شهر"
          type="شائع"
          bg="bg-stone-900"
          shine="bg-stone-700"
          signOne={tickSign}
          signTwo={cross}
          signThree={tickSign}
        />
        <PackageCard
          price="549 جنيها/شهر"
          type="مميز"
          bg="bg-[#0d1b2a]"
          shine="bg-[#1b263b]"
          prem={premium}
          signOne={tickSign}
          signTwo={tickSign}
          signThree={tickSign}
        />
      </div>
    </div>
  );
};

export default OnlineCoachingPage;
