import "./App.css";
import LandingPage from "./Landing/LandingPage";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import PaymentResult from "./components/PaymentResult";
import BlogPage from "./Free/BlogPage";
import OnlineCoachingPage from "./Landing/OnlineCoachingPage";
import AboutPage from "./Free/AboutPage";
import AdminPage from "./premium/Adminpage";
import Workouts from "./render/Workouts";
import Meals from "./render/Meals";
import ProfilePage from "./render/ProfilePage";
import RegisterPage from "./Landing/Register";
import LoginPage from "./Landing/LoginPage";
import ActivationPage from "./Landing/ActivationPage";

function App() {
  return (
      <BrowserRouter>
      <Routes>
        <Route path="/" exact element= {<LandingPage />}/>
        <Route path="/register" element ={<RegisterPage/>} />
        <Route path="/login" element = {<LoginPage/>} />
        <Route path="/auth/activate/:activation_token" element = {<ActivationPage />} />
        <Route path="/workouts/:id" element={<Workouts />} />
        <Route path="/meals" element={<Meals/>} />
        <Route path="/blog" element={<BlogPage/>}/>
        <Route path="/onlinecoaching" element={<OnlineCoachingPage />} />
        <Route path="/result" element={<PaymentResult />} />
        <Route path="/about" element={<AboutPage />} />
        <Route path="/dashboard" element={<AdminPage/>} />
        <Route path="/profile" element={<ProfilePage />} />
      </Routes>
      </BrowserRouter>
  );
}

export default App;
