import { useState } from "react";
import { Link } from "react-router-dom";

const Video = ({ data }) => {
  const [num, setNum] = useState(0);

  const change = (e) => {
    setNum(e.target.id);
    document.querySelectorAll(".btn").forEach((btn) => {
      btn.classList.remove("bg-white");
      btn.classList.add("text-white");

    });
    e.target.classList.add("bg-white");
    e.target.classList.remove("text-white");

  };

  const btns = [];
  for (let i = 0; i < 3; ++i) {
    btns.push(
      <button
        onClick={change}
        id={i}
        className={`${i === 0 && "bg-white text-black"} ${i !== 0 &&"text-white"} bg-stone-800 btn h-[40px] p-2 px-4 rounded-lg`}
      >
        {data[i].Name}
      </button>
    );
  }

  return (
    <div>
      <div className="flex items-center p-4 whitespace-nowrap left-4 bg-stone-950 overflow-scroll">
        {btns.map((btn) => {
          return <div className="mx-6">{btn}</div>;
        })}
        <Link to='/onlinecoaching'>
        <button className="text-white bg-orange-800 h-[40px] mx-20 p-2 px-4 rounded-lg">
          More...
        </button>
        </Link>
      </div>
      <div className="flex flex-wrap md:flex-nowrap justify-center items-center p-4 py-12 bg-gray-200 w-full overflow-hidden">
       <div className="shadow-lg rounded-xl overflow-hidden">{data[num].video}</div>
        <div  dir="rtl" className="p-3 md:ml-6 md:w-[600px] w-[350px] shadow-2xl rounded-xl bg-white">
          <h1 className="text-4xl py-2 mb-6 font-semibold border-b-4border-black text-gray-600 w-[350px]">
            {data[num].Name}
          </h1>
          <p className="text-xl">{data[num].Notes}</p>
        </div>
      </div>
    </div>
  );
};

export default Video;
