import { useState } from "react";
import Signup from "../components/Signup";
import Video from "./Video";
import Header from "../Landing/Header";
import { useParams } from "react-router-dom";
import { leg } from "../LegWorkouts";
import { shoulder } from "../ShoulderWorkouts";
import { biceps } from "../BicepsWorkouts";
import { chest } from "../ChestWorkouts";
import { back } from "../BackWorkouts";
import { tri } from "../TricepsWorkouts";

const VideoPage = () => {
  const { id } = useParams();

  const [active, setActive] = useState(false);
  const [active2, setActive2] = useState(false);

  const toggleActive = () => {
    setActive(!active);
  };
  const toggleActive2 = () => {
    setActive2(!active2);
  };

  return (
    <div className="">
      <Header signClick={toggleActive} fixed={false}/>
      {id === "leg" && <Video data={leg} />}
      {id === "shoulder" && <Video data={shoulder} />}
      {id === "biceps" && <Video data={biceps} />}
      {id === "chest" && <Video data={chest} />}
      {id === "back" && <Video data={back} />}
      {id === "triceps" && <Video data={tri} />}
      <Signup
        isVisible={active}
        close={toggleActive}
        change={() => {
          toggleActive();
          toggleActive2();
        }}
      />
    </div>
  );
};

export default VideoPage;
