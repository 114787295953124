import video0 from "./videos/video - Copy (63)/video.mp4";
import video1 from "./videos/video - Copy (64)/video.mp4";
import video2 from "./videos/video - Copy (65)/video.mp4";
import video3 from "./videos/video - Copy (66)/video.mp4";
import video4 from "./videos/video - Copy (67)/video.mp4";
import video5 from "./videos/video - Copy (68)/video.mp4";
import video6 from "./videos/video - Copy (69)/video.mp4";
import video7 from "./videos/video - Copy (70)/video.mp4";
import video8 from "./videos/video - Copy (71)/video.mp4";
import video9 from "./videos/video - Copy (72)/video.mp4";
import video10 from "./videos/video - Copy (73)/video.mp4";
import video11 from "./videos/video - Copy (74)/video.mp4";
import video12 from "./videos/video - Copy (75)/video.mp4";
import video13 from "./videos/video - Copy (76)/video.mp4";
import video14 from "./videos/video - Copy (77)/video.mp4";
import video15 from "./videos/video - Copy (78)/video.mp4";
import video16 from "./videos/video - Copy (79)/video.mp4";
import video17 from "./videos/video - Copy (80)/video.mp4";
import video18 from "./videos/video - Copy (81)/video.mp4";
import video19 from "./videos/video - Copy (82)/video.mp4";
import video20 from "./videos/video - Copy (83)/video.mp4";
import video21 from "./videos/video - Copy (84)/video.mp4";
import video22 from "./videos/video - Copy (85)/video.mp4";
import video23 from "./videos/video - Copy (86)/video.mp4";
import video24 from "./videos/video - Copy (87)/video.mp4";

export const back = [
  {
    video: (
      <video
        className="min-h-[300px] max-h-[500px] max-w-[400px]"
        src={video0}
        muted
        loop
        autoPlay
      />
    ),
    Name: "Wide Grip Pull Up",
    Notes: (
      <ol>
        1- أمسك البار بقبضة علوية أوسع من عرض الكتفين، مع تمديد المرفقين بالكامل
        عند تعليق ميت.
        <br />
        <br />
        2- مع استقامة الساقين وركبتيك مقفلتين، اسحب نفسك للأعلى على الأقل حتى
        تصبح ذقنك فوق العارضة.
        <br />
        <br /> 3- النزول بطريقة مسيطر عليها.
      </ol>
    ),
  },
  {
    video: (
      <video
        className="min-h-[300px] max-h-[500px] max-w-[400px]"
        src={video1}
        muted
        loop
        autoPlay
      />
    ),
    Name: "Underhand Grip Pull Up",
    Notes: (
      <ol>
        1- أمسك البار بقبضة سفلية بعرض الكتفين، مع تمديد المرفقين بالكامل عند
        تعليق ميت.
        <br />
        <br />
        2- مع فرد الساقين وركبتيك مقفلتين، اسحب نفسك للأعلى على الأقل حتى تصبح
        ذقنك فوق العارضة.
        <br />
        <br /> 3- النزول بطريقة خاضعة للرقابة.
      </ol>
    ),
  },
  {
    video: (
      <video
        className="min-h-[300px] max-h-[500px] max-w-[400px]"
        src={video2}
        muted
        loop
        autoPlay
      />
    ),
    Name: "Wide Grip Lat Pulldown",
    Notes: (
      <ol>
        1- اجلس على مقعد الآلة مع وضع فخذيك تحت وسادات الدعم.
        <br />
        <br />
        2- أمسك المقبض على نطاق أوسع من عرض الكتفين بقبضة علوية.
        <br />
        <br /> 3- ابدأ كل تكرار بمرفقيك مستقيمين ومغلقين بالكامل.
        <br />
        4- اسحب المقبض لأسفل على الأقل حتى يصبح المقبض أسفل ذقنك.
        <br />
        5- احتفظ بالوضعية السفلية للحظة قبل العودة بطريقة مسيطر عليها.
      </ol>
    ),
  },
  {
    video: (
      <video
        className="min-h-[300px] max-h-[500px] max-w-[400px]"
        src={video3}
        muted
        loop
        autoPlay
      />
    ),
    Name: "Neutral Grip Lat Pulldown",
    Notes: (
      <ol>
        1- اجلس على مقعد الآلة مع وضع فخذيك تحت وسادات الدعم.
        <br />
        <br />
        2- أمسك المقبض بعرض الكتفين بقبضة محايدة.
        <br />
        <br /> 3- ابدأ كل تكرار بمرفقيك مستقيمين ومغلقين بالكامل.
        <br />
        4- اسحب المقبض لأسفل على الأقل حتى يصبح المقبض أسفل ذقنك.
        <br />
        5- احتفظ بالوضعية السفلية للحظة قبل العودة بطريقة مسيطر عليها.
      </ol>
    ),
  },
  {
    video: (
      <video
        className="min-h-[300px] max-h-[500px] max-w-[400px]"
        src={video4}
        muted
        loop
        autoPlay
      />
    ),
    Name: "Lat Pulldown, Single Arm",
    Notes: (
      <ol>
        1- اجلس على مقعد الآلة مع وضع فخذيك تحت وسادات الدعم.
        <br />
        <br />
        2- أمسك المقبض بيد واحدة بعرض الكتفين بقبضة علوية.
        <br />
        <br /> 3- ابدأ كل تكرار بمرفقيك مستقيمين ومغلقين بالكامل.
        <br />
        4- اسحب المقبض لأسفل على الأقل حتى يصبح المقبض أسفل ذقنك.
        <br />
        5- احتفظ بالوضعية السفلية للحظة قبل العودة بطريقة مسيطر عليها.
      </ol>
    ),
  },
  {
    video: (
      <video
        className="min-h-[300px] max-h-[500px] max-w-[400px]"
        src={video5}
        muted
        loop
        autoPlay
      />
    ),
    Name: "Overhand Grip Plate Loaded",
    Notes: (
      <ol>
        1- اجلس على مقعد الآلة مع وضع فخذيك تحت وسادات الدعم.
        <br />
        <br />
        2- أمسك المقبض بعرض الكتفين بقبضة علوية.
        <br />
        <br /> 3- ابدأ كل تكرار بمرفقيك مستقيمين ومغلقين بالكامل.
        <br />
        4- اسحب المقبض لأسفل على الأقل حتى يصبح المقبض أسفل ذقنك.
        <br />
        5- احتفظ بالوضعية السفلية للحظة قبل العودة بطريقة مسيطر عليها.
      </ol>
    ),
  },
  {
    video: (
      <video
        className="min-h-[300px] max-h-[500px] max-w-[400px]"
        src={video6}
        muted
        loop
        autoPlay
      />
    ),
    Name: "Seated Cable Row, Overhand Grip",
    Notes: (
      <ol>
        1- اجلس بشكل مستقيم مع تقوس ظهرك ورفع صدرك.
        <br />
        <br />
        2- أمسك المقابض بعرض الكتفين، بقبضة علوية.
        <br />
        <br /> 3- ابدأ التكرار بذراعيك مستقيمتين وممتدتين للأمام.
        <br />
        4- اسحب لوح كتفك واسحب مرفقك للخلف، مع توسيع مرفقيك قليلاً للخارج.
        <br />
        5- قم بإحضار مرفقك إلى الخلف قدر الإمكان، مع الاستمرار في وضع النهاية
        للحظة.
        <br />
        6- العودة مع السيطرة إلى وضع البداية.
      </ol>
    ),
  },
  {
    video: (
      <video
        className="min-h-[300px] max-h-[500px] max-w-[400px]"
        src={video7}
        muted
        loop
        autoPlay
      />
    ),
    Name: "Seated Cable Row, Wide Grip",
    Notes: (
      <ol>
        1- اجلس بشكل مستقيم مع تقوس ظهرك ورفع صدرك.
        <br />
        <br />
        2- أمسك المقابض على نطاق أوسع من عرض الكتفين، بقبضة علوية.
        <br />
        <br /> 3- ابدأ التكرار بذراعيك مستقيمتين وممتدتين للأمام.
        <br />
        4- اسحب لوحي كتفك واسحب مرفقيك للخلف، مع توسيع مرفقيك قليلاً للخارج.
        <br />
        5- قم بإحضار مرفقيك إلى الخلف قدر الإمكان، مع الاستمرار في وضع النهاية
        للحظة.
        <br />
        6- العودة مع السيطرة إلى وضع البداية.
      </ol>
    ),
  },
  {
    video: (
      <video
        className="min-h-[300px] max-h-[500px] max-w-[400px]"
        src={video8}
        muted
        loop
        autoPlay
      />
    ),
    Name: "Seated Cable Row, Neutral Grip",
    Notes: (
      <ol>
        1- اجلس بشكل مستقيم مع تقويس ظهرك ورفع صدرك.
        <br />
        <br />
        2- أمسك المقابض بعرض الكتفين، بقبضة محايدة.
        <br />
        <br /> 3- ابدأ التكرار بذراعيك مستقيمتين وممتدتين للأمام.
        <br />
        4- اسحب لوحي كتفك واسحب مرفقيك إلى الخلف، مع إبقاء مرفقيك قريبين من
        ضلوعك.
        <br />
        5- قم بإرجاع مرفقيك إلى الخلف قدر الإمكان، مع الحفاظ على وضع النهاية
        للحظة.
        <br />
        6- العودة مع السيطرة إلى وضع البداية.
      </ol>
    ),
  },
  {
    video: (
      <video
        className="min-h-[300px] max-h-[500px] max-w-[400px]"
        src={video9}
        muted
        loop
        autoPlay
      />
    ),
    Name: "Seated Cable Row, Single Arm",
    Notes: (
      <ol>
        1- اجلس بشكل مستقيم مع تقوس ظهرك ورفع صدرك.
        <br />
        <br />
        2- ابدأ التكرار بذراعك مستقيمة وممتدة للأمام.
        <br />
        <br /> 3-اسحب لوح كتفك واسحب مرفقك للخلف، مع إبقائه قريبًا من أضلاعك.
        <br />
        4- قم بإحضار مرفقك إلى الخلف قدر الإمكان، مع الاستمرار في وضع النهاية
        للحظة.
        <br />
        5- العودة مع السيطرة إلى وضع البداية.
      </ol>
    ),
  },
  {
    video: (
      <video
        className="min-h-[300px] max-h-[500px] max-w-[400px]"
        src={video10}
        muted
        loop
        autoPlay
      />
    ),
    Name: "Bent Over Barbell Row, Overhand Grip",
    Notes: (
      <ol>
        1- أمسك البار بعرض الكتفين بقبضة علوية.
        <br />
        <br />
        2-اتخذ وضعية الانحناء، مع تقويس ظهرك وضغط لوحي كتفك معًا.
        <br />
        <br /> 3-ابدأ بالشريط المعلق أسفل ركبتيك مباشرةً. ارفع الحديد عن طريق
        ثني مرفقيك ورفعهما إلى أعلى مستوى ممكن، حتى يلامس الحديد بطنك.
        <br />
        4- تجنب استخدام الزخم أو المساعدة في ساقيك. حاول إبقاء جذعك موازيًا
        للأرضية طوال المجموعة بأكملها.
      </ol>
    ),
  },
  {
    video: (
      <video
        className="min-h-[300px] max-h-[500px] max-w-[400px]"
        src={video11}
        muted
        loop
        autoPlay
      />
    ),
    Name: "Bent Over Barbell Row, Underhand Grip        ",
    Notes: (
      <ol>
        1- أمسك البار بعرض الكتفين بقبضة سفلية.
        <br />
        <br />
        2- اتخذ وضعية الانحناء، مع تقويس ظهرك وضغط لوحي كتفك معًا.
        <br />
        <br /> 3-ابدأ بالشريط المعلق أسفل ركبتيك مباشرةً. ارفع الحديد عن طريق
        ثني مرفقيك ورفعهما إلى أعلى مستوى ممكن، حتى يلامس الحديد بطنك.
        <br />
        4- تجنب استخدام الزخم أو المساعدة في ساقيك. حاول إبقاء جذعك موازيًا
        للأرضية طوال المجموعة بأكملها
      </ol>
    ),
  },
  {
    video: (
      <video
        className="min-h-[300px] max-h-[500px] max-w-[400px]"
        src={video12}
        muted
        loop
        autoPlay
      />
    ),
    Name: "Single Arm Dumbbell Row",
    Notes: (
      <ol>
        1- اتكئ على المقعد باستخدام ركبتك وذراعك، مع الحفاظ على جذعك موازيًا
        للأرض.
        <br />
        <br />
        2- احني ظهرك.
        <br />
        <br /> 3- اسحب الدمبل نحو وركك عن طريق رفع مرفقك إلى أعلى مستوى ممكن.
        <br />
        4- جدف مع الدمبل بالقرب من جانبك بهدف إيصاله إلى جيبك.
        <br />
        5- النزول مع السيطرة.
      </ol>
    ),
  },
  {
    video: (
      <video
        className="min-h-[300px] max-h-[500px] max-w-[400px]"
        src={video13}
        muted
        loop
        autoPlay
      />
    ),
    Name: "TBar Row",
    Notes: (
      <ol>
        1- أمسك الحديد باستخدام مقبض الكابل على شكل حرف V الموجود أسفل البار.
        <br />
        <br />
        2- اتخذ وضعية الانحناء، مع تقويس ظهرك وضغط لوحي كتفك معًا.
        <br />
        <br /> 3- ابدأ بمرفقيك بشكل مستقيم. ارفع الحديد عن طريق ثني مرفقيك
        ورفعهما إلى أعلى مستوى ممكن، حتى يلامس الحديد بطنك.
        <br />
        4- تجنب استخدام الزخم أو المساعدة في ساقيك. حاول إبقاء جذعك موازيًا
        للأرضية طوال المجموعة بأكملها.
      </ol>
    ),
  },
  {
    video: (
      <video
        className="min-h-[300px] max-h-[500px] max-w-[400px]"
        src={video14}
        muted
        loop
        autoPlay
      />
    ),
    Name: "Chest Supported Dumbbell Row",
    Notes: (
      <ol>
       1- ضع المقعد على منحدر طفيف، وأمسك الدمبل في وضع قبضة محايد.

        <br />
        <br />
       2- اضغط على لوحي كتفك معًا وقوس ظهرك.

        <br />
        <br />
       3- اسحب الدمبل نحو وركك عن طريق رفع مرفقك إلى أعلى مستوى ممكن.

        <br />
        <br />
       4- جدف مع الدمبل بالقرب من جانبك بهدف إيصاله إلى جيبك.

        <br />
        <br />
       5- النزول مع السيطرة..
      </ol>
    ),
  },
  {
    video: (
      <video
        className="min-h-[300px] max-h-[500px] max-w-[400px]"
        src={video15}
        muted
        loop
        autoPlay
      />
    ),
    Name: "Standing Cable Pullover Rope",
    Notes: (
      <ol>
       1- أمسك الحبل بعرض الكتفين وابتعد عن آلة السحب.

        <br />
        <br />
       2- قف مع مباعدة قدميك بمقدار عرض الكتفين تقريبًا واثني ركبتيك قليلًا.

        <br />
        <br />
       3- ابدأ بالمقبض عند ارتفاع العين. مع مرفقيك مقفلين.

        <br />
        <br />
       4- انحن إلى الأمام وقم بخفض المقبض نحو ركبتيك.

        <br />
        <br />
       5- احتفظ بالوضع السفلي للحظة قبل العودة إلى وضع التحديق.
      </ol>
    ),
  },
  {
    video: (
      <video
        className="min-h-[300px] max-h-[500px] max-w-[400px]"
        src={video16}
        muted
        loop
        autoPlay
      />
    ),
    Name: "Standing Cable Pullover Bar",
    Notes: (
      <ol>
       1- أمسك الشريط بعرض الكتفين وابتعد عن آلة السحب.

        <br />
        <br />
       2- قف مع مباعدة قدميك بمقدار عرض الكتفين تقريبًا واثني ركبتيك قليلاً.

        <br />
        <br />
       3- ابدأ بالمقبض عند ارتفاع العين. مع مرفقيك مقفلين.

        <br />
        <br />
       4- انحن إلى الأمام وقم بخفض المقبض نحو ركبتيك.

        <br />
        <br />
       5- احتفظ بالوضع السفلي للحظة قبل العودة إلى وضع البداية.
      </ol>
    ),
  },
  {
    video: (
      <video
        className="min-h-[300px] max-h-[500px] max-w-[400px]"
        src={video17}
        muted
        loop
        autoPlay
      />
    ),
    Name: "Flat Dumbbell Pullover",
    Notes: (
      <ol>
       1- ابدأ بالمرفقين بشكل مستقيم تقريبًا ممسكين بالدمبل على وجهك.

        <br />
        <br />
       2- اخفض ذراعيك للخلف ببطء حتى تشعر بتمدد في صدرك وبطنك.

        <br />
        <br />
       3- اثبت على وضع النهاية للحظة قبل العودة إلى وضع البداية بطريقة مسيطر عليها.
      </ol>
    ),
  },
  {
    video: (
      <video
        className="min-h-[300px] max-h-[500px] max-w-[400px]"
        src={video18}
        muted
        loop
        autoPlay
      />
    ),
    Name: "Plate Loaded Chest Supported Row Machine, Overhand Grip",
    Notes: (
      <ol>
       1- اضبط الكرسي بحيث تكون المقابض في مستوى صدرك.

        <br />
        <br />
      2- أمسك المقابض بعرض الكتفين بقبضة علوية.

        <br />
        <br />
       3- ابدأ بمرفقيك مقفلين تمامًا وشفرات الكتف فضفاضة وممتدة.

        <br />
        <br />
       4- ابدأ التكرار بالضغط على لوحي كتفك وسحب المقابض إلى الخلف قدر الإمكان.

        <br />
        <br />
       5- شغل موضع النهاية للحظة.

        <br /> <br /> 6- العودة إلى وضع البداية بطريقة بطيئة ومنضبطة.

        <br /> <br /> 7- أثناء التجديف، قم بتوسيع مرفقيك قليلاً للخارج.
      </ol>
    ),
  },
  {
    video: (
      <video
        className="min-h-[300px] max-h-[500px] max-w-[400px]"
        src={video19}
        muted
        loop
        autoPlay
      />
    ),
    Name: "Plate Loaded Chest Supported Row Machine, Neutral Grip, Narrow",
    Notes: (
      <ol>
       1- اضبط الكرسي بحيث تكون المقابض في مستوى صدرك.

        <br />
        <br />
       2- أمسك المقابض بعرض الكتفين بقبضة محايدة.

        <br />
        <br />
       3- ابدأ بمرفقيك مقفلين تمامًا وشفرات الكتف فضفاضة وممتدة.

        <br />
        <br />
       4-  ابدأ التكرار بالضغط على لوحي كتفك وسحب المقابض إلى الخلف قدر الإمكان.

        <br />
        <br />
       5- شغل موضع النهاية للحظة.

        <br /> <br /> 6- العودة إلى وضع البداية بطريقة بطيئة ومنضبطة.

        manner.
        <br /> <br /> 7- أثناء التجديف، أبقِ مرفقيك قريبين من ضلوعك.
      </ol>
    ),
  },
  {
    video: (
      <video
        className="min-h-[300px] max-h-[500px] max-w-[400px]"
        src={video20}
        muted
        loop
        autoPlay
      />
    ),
    Name: "Selector Chest Supported Row Machine, Overhand Grip",
    Notes: (
      <ol>
       1- اضبط الكرسي بحيث تكون المقابض في مستوى صدرك.

        <br />
        <br />
       2- أمسك المقابض بعرض الكتفين بقبضة علوية.

        <br />
        <br />
       3- ابدأ بمرفقيك مقفلين تمامًا وشفرات الكتف فضفاضة وممتدة.

        <br />
        <br />
       4- ابدأ التكرار بالضغط على لوحي كتفك وسحب المقابض إلى الخلف قدر الإمكان.

        <br />
        <br />
       5- شغل موضع النهاية للحظة.

        <br /> <br />
       6- العودة إلى وضع البداية بطريقة بطيئة ومنضبطة.

        <br /> <br />
       7- أثناء التجديف، قم بتوسيع مرفقيك
      </ol>
    ),
  },
  {
    video: (
      <video
        className="min-h-[300px] max-h-[500px] max-w-[400px]"
        src={video21}
        muted
        loop
        autoPlay
      />
    ),
    Name: "Selector Chest Supported Row Machine, Neutral Grip, Narrow",
    Notes: (
      <ol>
        1- اضبط الكرسي بحيث تكون المقابض في مستوى صدرك.

        <br />
        <br />
        2- أمسك المقابض بعرض الكتفين بقبضة علوية.

        <br />
        <br />
        3- ابدأ بمرفقيك مقفلين تمامًا وشفرات الكتف فضفاضة وممتدة.

        <br />
        <br />
        4- ابدأ التكرار بالضغط على لوحي كتفك وسحب المقابض إلى الخلف قدر الإمكان.
        <br />
        <br />
        5- شغل موضع النهاية للحظة.
        <br /> <br />
        6- العودة إلى وضع البداية بطريقة بطيئة ومنضبطة.

        <br />
        <br />
        7- أثناء التجديف، قم بتوسيع مرفقيك
      </ol>
    ),
  },
  {
    video: (
      <video
        className="min-h-[300px] max-h-[500px] max-w-[400px]"
        src={video22}
        muted
        loop
        autoPlay
      />
    ),
    Name: "TBar Row ,wide grip",
    Notes: (
      <ol>
       1- أمسك المقبض الواسع

        <br />
        <br />
       2- اتخذ وضعية الجهاز،  وضغط لوحي كتفك معًا.

        <br />
        <br />
       3- ابدأ بمرفقيك بشكل مستقيم. ارفع الحديد عن طريق ثني مرفقيك ورفعهما إلى أعلى مستوى ممكن، حتى يلامس الحديد  بطنك.
      </ol>
    ),
  },
  {
    video: (
      <video
        className="min-h-[300px] max-h-[500px] max-w-[400px]"
        src={video23}
        muted
        loop
        autoPlay
      />
    ),
    Name: "TBar Row ,neutral grip",
    Notes: (
      <ol>
       1- أمسك المقبض المحايد

        <br />
        <br />
       2- اتخذ وضعية الجهاز،  وضغط لوحي كتفك معًا.

        <br />
        <br />
       3- ابدأ بمرفقيك بشكل مستقيم. ارفع الحديد عن طريق ثني مرفقيك ورفعهما إلى أعلى مستوى ممكن، حتى يلامس الحديد  بطنك.
      </ol>
    ),
  },
  {
    video: (
      <video
        className="min-h-[300px] max-h-[500px] max-w-[400px]"
        src={video24}
        muted
        loop
        autoPlay
      />
    ),
    Name: "TBar Row ,overhand grip",
    Notes: (
      <ol>
       1- أمسك المقبض الضيق

        <br />
        <br />
       2- اتخذ وضعية الجهاز،  وضغط لوحي كتفك معًا.

        <br />
        <br />
       3- ابدأ بمرفقيك بشكل مستقيم. ارفع الحديد عن طريق ثني مرفقيك ورفعهما إلى أعلى مستوى ممكن، حتى يلامس الحديد  بطنك.
      </ol>
    ),
  },
];
