import video0 from "./videos/video - Copy (48)/video.mp4";
import video1 from "./videos/video - Copy (49)/video.mp4";
import video2 from "./videos/video - Copy (50)/video.mp4";
import video3 from "./videos/video - Copy (51)/video.mp4";
import video4 from "./videos/video - Copy (52)/video.mp4";
import video5 from "./videos/video - Copy (53)/video.mp4";
import video6 from "./videos/video - Copy (54)/video.mp4";
import video7 from "./videos/video - Copy (55)/video.mp4";
import video8 from "./videos/video - Copy (56)/video.mp4";
import video9 from "./videos/video - Copy (57)/video.mp4";
import video10 from "./videos/video - Copy (58)/video.mp4";
import video11 from "./videos/video - Copy (59)/video.mp4";
import video12 from "./videos/video - Copy (60)/video.mp4";
import video13 from "./videos/video - Copy (61)/video.mp4";
import video14 from "./videos/video - Copy (62)/video.mp4";

export const chest = [
  {
    video: (
      <video
        className="min-h-[300px] max-h-[500px] max-w-[400px]"
        src={video0}
        muted
        loop
        autoPlay
      />
    ),
    Name: "Barbell Bench Press",
    Notes: (
      <ol>
        1- استلقي مع عضلات المؤخرة والكتفين على اتصال بالمقعد، وقدميك مسطحة على الأرض.

        <br />
        <br />
        2- أمسك الشريط بشكل أوسع قليلاً من عرض الكتفين.

        <br />
        <br /> 3- اضغط على لوحي كتفك معًا.

        <br />
        <br /> 4- قم بخفض البار مع التحكم حتى يتم الاتصال بأسفل صدرك، ثم قم بتمديد مرفقيك حتى يتم قفلهما.

        <br />
        <br />
        5- لا تثني رسغ يدك اثناء النزول بالوزن.
      </ol>
    ),
  },
  {
    video: (
      <video
        className="min-h-[300px] max-h-[500px] max-w-[400px]"
        src={video1}
        muted
        loop
        autoPlay
      />
    ),
    Name: "Incline Barbell Bench Press",
    Notes: (
      <p>
        1- استلقي مع عضلات المؤخرة والكتفين على اتصال بالمقعد، وقدميك مسطحة على الأرض.

        <br />
        <br />
        2- أمسك الشريط بشكل أوسع قليلاً من عرض الكتفين.

        <br />
        <br /> 3- اضغط على لوحي كتفك معًا.

        <br />
        <br /> 4- قم بخفض البار مع التحكم حتى يتم الاتصال بأسفل صدرك، ثم قم بتمديد مرفقيك حتى يتم قفلهما.

        <br />
        <br />
        5- لا تثني رسغ يدك اثناء النزول بالوزن.
      </p>
    ),
  },
  {
    video: (
      <video
        className="min-h-[300px] max-h-[500px] max-w-[400px]"
        src={video2}
        muted
        loop
        autoPlay
      />
    ),
    Name: "Decline Barbell Bench Press",
    Notes: (
      <p>
       1-  استلقي مع عضلات المؤخرة والكتفين على اتصال بالمقعد، وقدميك مسطحة على الأرض.

        <br />
        <br />
        2- أمسك الشريط بشكل أوسع قليلاً من عرض الكتفين.

        <br />
        <br /> 3- اضغط على لوحي كتفك معًا.
        <br />
        <br />
        4- قم بخفض البار مع التحكم حتى يتم الاتصال بأسفل صدرك، ثم قم بتمديد مرفقيك حتى يتم قفلهما.

        <br />
        <br />
        5- لا تثني رسغ يدك اثناء النزول بالوزن.
      </p>
    ),
  },
  {
    video: (
      <video
        className="min-h-[300px] max-h-[500px] max-w-[400px]"
        src={video3}
        muted
        loop
        autoPlay
      />
    ),
    Name: "Dumbbell Fly",
    Notes: (
      <p>
        1- ابدأ بذراعيك ممدودتين فوق صدرك والدمبل معًا، وقدميك مسطحتين على الأرض.

        <br />
        <br />
        2- اضغط لوحي كتفك معًا.

        <br />
        <br />
        3- مع التحكم، ابدأ بإنزال الدمبلز على جانبيك، مع إبقاء مرفقيك مستقيمين تقريبًا.

        <br /> <br />
        4- انزل حتى تشعر بالتمدد في جميع أنحاء الصدر، ثم ارفع الدمبلز ببطء إلى الموضع العلوي.

        <br />
        <br />
        5- تجنب ثني المرفقين أثناء التكرار.
      </p>
    ),
  },
  {
    video: (
      <video
        className="min-h-[300px] max-h-[500px] max-w-[400px]"
        src={video4}
        muted
        loop
        autoPlay
      />
    ),
    Name: "Dumbbell Bench Press",
    Notes: (
      <p>
        1- استلقي مع عضلات المؤخرة والكتفين على اتصال بالمقعد، وقدميك مسطحة على الأرض.

        <br />
        <br />
        2- اضغط على لوحي كتفك معًا.
.
        <br />
        <br />
         3- أنزل الدمبلز مع التحكم حتى تلامس جانب صدرك، ثم قم بمد مرفقيك حتى يتم تثبيتهما.
      </p>
    ),
  },
  {
    video: (
      <video
        className="min-h-[300px] max-h-[500px] max-w-[400px]"
        src={video5}
        muted
        loop
        autoPlay
      />
    ),
    Name: "Incline Dumbbell Bench Press",
    Notes: (
      <p>
        1- اضبط المقعد بزاوية 30-45 درجة.

        <br />
        <br />
        2- استلقي مع عضلات المؤخرة والكتفين على اتصال بالمقعد، وقدميك مسطحة على الأرض.

        <br />
        <br /> 3- اضغط على لوحي كتفك معًا.

        <br />
        <br />
        4- أنزل الدمبلز مع التحكم حتى تلامس جانب صدرك، ثم قم بمد مرفقيك حتى يتم تثبيتهما.
      </p>
    ),
  },
  {
    video: (
      <video
        className="min-h-[300px] max-h-[500px] max-w-[400px]"
        src={video6}
        muted
        loop
        autoPlay
      />
    ),
    Name: "Incline Dumbbell Fly",
    Notes: (
      <p>
        1- اضبط المقعد بزاوية 30-45 درجة.

        <br />
        <br />
        2- ابدأ بذراعيك ممدودتين فوق صدرك والدمبل معًا، وقدميك مسطحتين على الأرض.

        <br />
        <br /> 3- اضغط على لوحي كتفك معًا.
.
        <br />
        <br />
        4- مع التحكم، ابدأ في إنزال الدمبل على جانبيك، مع الحفاظ على مرفقيك مستقيمين تقريبًا.

        <br />
        <br />
        5- انزل حتى تشعر بالتمدد في جميع أنحاء الصدر، ثم ارفع الدمبلز ببطء إلى الموضع العلوي.

        <br />
        <br />
        6- تجنب ثني مرفقيك أثناء التمرين.
      </p>
    ),
  },
  {
    video: (
      <video
        className="min-h-[300px] max-h-[500px] max-w-[400px]"
        src={video7}
        muted
        loop
        autoPlay
      />
    ),
    Name: "Smith Machine Bench Press",
    Notes: (
      <p>
        1- استلقي مع عضلات المؤخرة والكتفين على اتصال بالمقعد، وقدميك مسطحة على الأرض.

        <br />
        <br />
        2- أمسك الشريط بشكل أوسع قليلاً من عرض الكتفين.

        <br />
        <br /> 3- اضغط على لوحي كتفك معًا.

        <br />
        <br />
        4-  اخفض البار مع التحكم حتى يتم الاتصال بالجزء السفلي من الصدر، ثم مد مرفقيك حتى
      </p>
    ),
  },
  {
    video: (
      <video
        className="min-h-[300px] max-h-[500px] max-w-[400px]"
        src={video8}
        muted
        loop
        autoPlay
      />
    ),
    Name: "Medium Cable Fly",
    Notes: (
      <p>
       1- اضبط البكرات على ارتفاع الصدر وأمسك بالمقابض.

        <br /> <br />
       2- حافظ على استقامة ظهرك وشد الجذع، وارفع ذراعيك إلى جانبيك، وراحتي اليدين متجهتين للأمام، وامشي خطوة أو خطوتين للأمام من أجل خلق توتر على الكابلات.

        <br /> <br /> 3- الوقوف مع قدم واحدة أمام الأخرى.

        <br />
        <br />
       4- قم بثني مرفقيك قليلًا، مع التأكد من عدم تركهما خلف كتفيك.

        <br />
        <br />
       5- هذا هو موقع بدايتك.

        <br />
        <br />
       6- اسحب يديك تجاه بعضهما البعض في أقواس واسعة أمامك. توقف مؤقتًا للحظة عندما تتلامس يديك أثناء الضغط على صدرك، ثم عد ببطء إلى وضع البداية.
      </p>
    ),
  },
  {
    video: (
      <video
        className="min-h-[300px] max-h-[500px] max-w-[400px]"
        src={video9}
        muted
        loop
        autoPlay
      />
    ),
    Name: "High Cable Fly",
    Notes: (
      <p>
       1- اضبط البكرات على أعلى مستوى ممكن وأمسك بالمقابض.

        <br />
        <br />
       2- حافظ على استقامة ظهرك وشد الجذع، وارفع ذراعيك إلى جانبيك، وراحتي اليدين متجهتين للأمام، وامشي خطوة أو خطوتين للأمام من أجل خلق توتر على الكابلات.

        <br />
        <br /> 3- الوقوف مع قدم واحدة أمام الأخرى.
.
        <br />
        <br />
       4- قم بثني مرفقيك قليلًا، مع التأكد من عدم تركهما خلف كتفيك.

        <br />
        <br />
       5- هذا هو موقع بدايتك.

        <br />
        <br />
       6- اسحب يديك تجاه بعضهما البعض في أقواس واسعة، موجهًا ذراعيك نحو الأرض أمامك.

        <br />
        <br />
       7- توقف مؤقتًا للحظة عندما تتلامس يديك أثناء الضغط على عضلات الصدر
        <br />
      </p>
    ),
  },
  {
    video: (
      <video
        className="min-h-[300px] max-h-[500px] max-w-[400px]"
        src={video10}
        muted
        loop
        autoPlay
      />
    ),
    Name: "Low Cable Fly",
    Notes: (
      <p>
        1- اضبط البكرات على أدنى مستوى ممكن وأمسك بالمقابض.

        <br />
        <br />
        2- حافظ على استقامة ظهرك وشد الجذع، وارفع ذراعيك إلى جانبيك، وراحتي اليدين متجهتين للأمام، وامشي خطوة أو خطوتين للأمام من أجل خلق توتر على الكابلات.

        <br />
        <br />
        3- قف في وضعية منقسمة، مع وضع وزنك على القدم الأمامية.

        <br />
        <br />
        4- قم بثني مرفقيك قليلاً، مع إبقاء ذراعيك قليلاً على الجانبين بزاوية 45 درجة عند الإبط. هذا هو موقع بدايتك.

        <br />
        <br />
        5- اسحب يديك للأعلى ونحو بعضهما البعض في أقواس واسعة. يتم الوصول إلى الموضع العلوي عندما تلمس يديك ارتفاع الصدر تقريبًا 
      </p>
    ),
  },
  {
    video: (
      <video
        className="min-h-[300px] max-h-[500px] max-w-[400px]"
        src={video11}
        muted
        loop
        autoPlay
      />
    ),
    Name: "Selector Chest Press Machine",
    Notes: (
      <p>
       1- قم بضبط الكرسي بحيث تكون المقابض في مستوى الجزء العلوي من صدرك.

        <br />
        <br />
       2- أبقِ صدرك مرتفعًا وكتفك مضغوطًا خلال المجموعة بأكملها.

        <br />
        <br />
       3- قم بإحضار المقابض إلى الخلف قدر الإمكان، واضغط للأمام حتى يتم قفل المرفقين بالكامل.
      </p>
    ),
  },
  {
    video: (
      <video
        className="min-h-[300px] max-h-[500px] max-w-[400px]"
        src={video12}
        muted
        loop
        autoPlay
      />
    ),
    Name: "Incline Chest Press Machine",
    Notes: (
      <p>
       1- قم بضبط الكرسي بحيث تكون المقابض في مستوى الجزء العلوي من صدرك.

        <br />
        <br />
       2- أبقِ صدرك مرتفعًا وكتفك مضغوطًا خلال المجموعة بأكملها.

        <br />
        <br /> 3- قم بإحضار المقابض إلى الخلف قدر الإمكان، واضغط للأمام حتى يتم قفل المرفقين بالكامل.
      </p>
    ),
  },
  {
    video: (
      <video
        className="min-h-[300px] max-h-[500px] max-w-[400px]"
        src={video13}
        muted
        loop
        autoPlay
      />
    ),
    Name: "Chest Fly Machine",
    Notes: (
      <p>
       1- اضبط الكرسي بحيث يكون مرفقيك في نفس مستوى صدرك

        <br />
        <br />
       2- أبقِ صدرك مرتفعًا وكتفك مضغوطًا خلال المجموعة بأكملها

        <br />
        <br />
       3- أعد المقابض إلى الخلف قدر الإمكان، وثبتها للحظة في الموضع العلوي، وثبتها للحظة واجمع مرفقيك معًا حتى تتلامس الوسادات.
      </p>
    ),
  },
  {
    video: (
      <video
        className="min-h-[300px] max-h-[500px] max-w-[400px]"
        src={video14}
        muted
        loop
        autoPlay
      />
    ),
    Name: "Dips",
    Notes: (
      <p>
       1- ابدأ من الموضع العلوي مع تمديد المرفقين بالكامل.

        <br />
        <br />
       2- اضغط على لوحي كتفك معًا وارفع صدرك.

        <br />
        <br />
       3- انحني قليلًا إلى الأمام وابدأي في الهبوط المتحكم به، مع ثني مرفقيك حتى يصلا إلى زاوية 90 درجة أو أقل.

        <br />
        <br />
       4- مد مرفقيك وادفع نفسك للأعلى حتى تصبح الذراعين مستقيمة.
      </p>
    ),
  },
];
