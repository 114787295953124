import { useState, useEffect } from "react";
import axios from "axios";
const AdminPage = () => {
  const [users, setUsers] = useState([]);
  const [isloading, setIsLoading] = useState(true);
  const [selectedIndex, setSelectedIndex] = useState(null);
  const [error, setError] = useState('');

  const handleClick = (index) => {
    setSelectedIndex(index === selectedIndex ? null : index);
  };
  var serverUrl = "https://api.coachmazen.com";

  const user = JSON.parse(localStorage.getItem('user'));
  useEffect(() => {
    const fetchUsers = async () => {
      try {
        const response = await fetch(`${serverUrl}/auth/getAllUsers`);
        if (!response.ok) {
          throw new Error('Failed to fetch users');
        }
        const data = await response.json();
        setUsers(data);  // Set users state with the fetched data
        setIsLoading(false);  // Set loading state to false after fetching data
      } catch (error) {
        setError('Error fetching users');
        setIsLoading(false);  // Stop loading in case of an error
        console.error('Error fetching users:', error);
      }
    };
    fetchUsers();
  }, []);

  const handleUpdate = (userId) => {
    const updatedPaidStatus = "premium"; // Update to the desired paid status

    // Send the PUT request to update the user's paid status
    axios
      .put(`${serverUrl}/auth/users/${userId}/updatePaidStatusToPremium`, {
        paid: updatedPaidStatus,
      })
      .then((response) => {
        // If the server updates successfully, update the user in the state
        setUsers((prevUsers) => {
          return prevUsers.map((user) => {
            if (user._id === userId) {
              return { ...user, paid: updatedPaidStatus };
            }
            return user;
          });
        });
      })
      .catch((error) => {
        console.error("Error updating user:", error);
      });
  };
  const handleDemote = (userId) => {
    const updatedPaidStatus = "no"; // Update to the desired paid status

    // Send the PUT request to update the user's paid status
    axios
      .put(`${serverUrl}/auth/users/${userId}/demotePaidStatus`, {
        paid: updatedPaidStatus,
      })
      .then((response) => {
        // If the server updates successfully, update the user in the state
        setUsers((prevUsers) => {
          return prevUsers.map((user) => {
            if (user._id === userId) {
              return { ...user, paid: updatedPaidStatus };
            }
            return user;
          });
        });
      })
      .catch((error) => {
        console.error("Error updating user:", error);
      });
  };

  return (
    <>
      {!isloading ? (
        <div className="flex flex-col items-center">
          {user && user.paid === "Admin" ? (
            <>
              <center className="text-xl p-4 border-b w-full">
                Mazen EL_Shahawy's dashboard
              </center>
              <ul className="w-[90%] mt-4 flex flex-col items-evenly rounded-t-lg border overflow-hidden">
                {users.map((user, index) => (
                  <div>
                    <div className="flex items-center justify-evenly md:p-4 p-2 px-1 border border-b-0">
                      <div className="flex items-center">
                        <img
                          className={`border-4 ${user.paid === "no"
                              ? "border-gray-400"
                              : "border-yellow-400"
                            } w-[50px] rounded-full`}
                          src={user.img}
                          alt=""
                        />
                        <p className="ml-4 text-xl">{user.name}</p>
                      </div>
                      <div>
                        {user.paid !== "Admin" &&
                          (user.paid === "no" ? (
                            <button
                              onClick={() => handleUpdate(user._id)}
                              className="border p-2 m-2 text-lg text-blue-400"
                            >
                              Promote
                            </button>
                          ) : (
                            <button
                              onClick={() => handleDemote(user._id)}
                              className="border p-2 m-2 text-lg text-red-400"
                            >
                              Demote
                            </button>
                          ))}
                      </div>
                      <button onClick={() => handleClick(index)} className="font-bold p-4">
                        V
                      </button>
                    </div>
                    <ul
                      dir="rtl"
                      className={`${index === selectedIndex ? "" : "hidden"
                        }  p-4 w-[300px] flex flex-col items-start mt-4 rounded-lg shadow-xl border-b-2 border-blue-400 m-4`}
                    >
                      <li
                        className={`text-stone-700 ${user.paid === "no" ? "" : "text-blue-400"
                          } text-2xl p-2 border mt-2`}
                      >
                        {user.paid === "no" ? "Free" : user.paid}
                      </li>
                      <li className="text-xl mt-1">العمر: {user.test.age}</li>
                      <li className="text-xl mt-1">
                        الوزن بالكيلوجرام: {user.test.weight}
                      </li>
                      <li className="text-xl mt-1">
                        الطول: {user.test.height}
                      </li>
                      <li className="text-xl mt-1">
                        رقم الواتساب: {user.test.whatsapp}
                      </li>
                      <li className="text-xl mt-1">
                        أمراض مزمنة: {user.test.disease}
                      </li>
                      <li className="text-xl mt-1">
                        حساسية من طعام: {user.test.allergy}
                      </li>
                      <li className="text-xl mt-1">
                        المراد تحقيقه: {user.test.goal}
                      </li>
                      <li className="text-xl mt-1">
                        مستوي التدريب: {user.test.levelOfTraining}
                      </li>
                      <li className="text-xl mt-1">
                        عدد أيام التدريب: {user.test.trainingDays}
                      </li>
                      <li className="text-xl mt-1">
                        النظام: {user.test.serviceNeeded}
                      </li>
                      <li className="text-xl mt-1">
                        اصابات: {user.test.injuries}
                      </li>
                    </ul>
                  </div>
                ))}
              </ul>
            </>
          ) : (
            user.paid
          )}
        </div>
      ) : null}
    </>
  );
};

export default AdminPage;
