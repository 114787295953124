import { Link } from "react-router-dom";

const MealsSection = () => {
  const showing = () => {
    document.getElementById("line").classList.add("scale-x-100");
  };
  const disappearing = () => {
    document.getElementById("line").classList.remove("scale-x-100");
  };
  return (
    <div className="relative meals py-24 flex flex-col items-center md:justify-around justify-center flex-wrap">
      <Link className="relative flex flex-col items-center" to="/meals">
      <button
        onMouseLeave={disappearing}
        onMouseEnter={showing}
        className="p-4 z-10 text-2xl text-white"
      >
        الوجبات و الوصفات&#8592;
      </button>
      <div
        id="line"
        className="z-10 w-56 h-[2px] bg-white transition-transform transform scale-x-0 origin-left"
      ></div>
      </Link>
    </div>
  );
};

export default MealsSection;
