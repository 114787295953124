const MealCard = ({ time, ingredientsNum, ingred, pro, cal, fats, carb }) => {

  let list = [];
  for (let i = 0; i < ingredientsNum; ++i) {
    list.push(
      <p className="m-4 p-4 text-white border-2 rounded-3xl z-20">{ingred[i]}</p>
    );
  }
  return (
    <div className="w-[380px] md:w-[500px] z-20 p-4">
      <div className="flex flex-col items-center border rounded-xl">
        <h1
          className={` text-xl ${time === "BREAKFAST" ? "text-green-700" :
            time === "DINNER"
              ? "text-orange-900"
              : time === "LUNCH"
                ? "text-red-600"
                : "text-purple-600"
            }`}
        >
          {time}
        </h1>
        <div className="m-4">
          <div className="flex flex-wrap text-xl">
            {list.map((item) => {
              return item;
            })}
          </div>
        </div>
        <div
          className={`text-white text-lg rounded-lg flex flex-wrap justify-center border-2 border-white w-full ${time === "BREAKFAST"
            ? "bg-green-700"
            : time === "LUNCH"
              ? "bg-red-600":
              time === "DINNER"
              ? "bg-orange-900"
              : "bg-purple-600"
            }`}
        >
          <p className="flex p-4">
            {" "}
            <span className="mr-2"> Protien:</span> {pro}
          </p>
          <p className="flex p-4">
            {" "}
            <span className="mr-2">Carb: </span> {carb}
          </p>
          <p className="flex p-4">
            {" "}
            <span className="mr-2">Fats:</span> {fats}
          </p>
          <p className="flex p-4">
            {" "}
            <span className="mr-2"> Calories: </span> {cal}
          </p>
        </div>
      </div>
    </div>
  );
};

export default MealCard;
