import React from 'react';
import { useState } from 'react';

const LoginPage = () => {
  const [formData, setFormData] = useState({
    email: '',
    password: '',
  });

  const handleChange = (event) => {
    setFormData({ ...formData, [event.target.name]: event.target.value });
  };

  const handleSubmit = async (event) => {
    event.preventDefault();


    try {
      const response = await fetch('https://api.coachmazen.com/auth/login', { // https://coach-mazen-server.vercel.app
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(formData),
        credentials: 'include'
      });

      if (response.ok) {
        const data = await response.json();

        localStorage.setItem('user', JSON.stringify(data.user));

        console.log('Login successful!');
        window.location.href = '/';
      } else {
        const errorMessage = await response.text();
        console.error('Login failed:', errorMessage);
      }
    } catch (error) {
      console.error('Error during login:', error);
    }

  };

  return (
    <div className="flex flex-col justify-center items-center h-screen">
          <form onSubmit={handleSubmit} className="flex flex-col items-center max-w-[600px] shadow shadow-2 p-4 bg-white w-[500px] border-2">
            <div className="w-3/4 flex flex-col">
              <label htmlFor="email">Email</label>
              <input
                type="email"
                id="email"
                name="email"
                className="form-control p-2 border"
                placeholder="Enter Email"
                value={formData.email}
                onChange={handleChange}
              />
            </div>
            <div className="w-3/4 flex flex-col">
              <label htmlFor="password">Password</label>
              <input
                type="password"
                id="password"
                name="password"
                className="form-control p-2 border"
                placeholder="Enter Password"
                value={formData.password}
                onChange={handleChange}
              />
            </div>
            <button type="submit" className="border m-4 px-4 py-2 cursor-pointer bg-cyan-400 text-white">
              Login
            </button>
          </form>
          <p className="mt-4">
            New User? <a href="/register">Register</a>
          </p>
          <p>
            Forgot Password? <a href="/auth/forgot">Reset</a>
          </p>
    </div>
  );
};

export default LoginPage;

// import { Link } from "react-router-dom";
// import axios from "axios";
// import { useState } from "react";

// const LoginPage = () => {

//     const [email, setEmail] = useState('');
//     const [password, setPassword] = useState('');
//     const [error, setError] = useState('');

//     const handleSubmit = async (e) => {
//         e.preventDefault();
      
//         try {
//           const res = await axios.post(
//             `https://coach-mazen-server.vercel.app/api/login-user`,
//             {
//               email,
//               password,
//             },
//             { withCredentials: true }
//           );
      
//           // Redirect to homepage
//           window.location.href = "/";
      
//           // Force reload the page
//         //   window.location.reload(true);
//         } catch (err) {
//           // Display error message
//         }
//       };
    
//     return (
//         <div className="flex justify-center items-center h-screen">
//             <form onSubmit={handleSubmit} className="flex flex-col items-center max-w-[600px] shadow shadow-2 p-4 bg-white w-[500px] border-2" action="">
//                 <br />
//                 <div className="w-3/4 flex flex-col">
//                     <label htmlFor="email">Email</label>
//                     <input className="border outline-none p-2" type="email"
//                         value={email}
//                         onChange={(e) => setEmail(e.target.value)} id="email" />
//                 </div>
//                 <br />
//                 <div className="w-3/4 flex flex-col">
//                     <label htmlFor="password">Password: </label>
//                     <input className="border outline-none p-2" type="password"
//                         value={password}
//                         onChange={(e) => setPassword(e.target.value)}
//                         required id="password" />
//                 </div>
//                 <input type="submit" className="border m-4 px-4 py-2 cursor-pointer bg-cyan-400 text-white" />
//                 <div> Don't have an account?
//                     <Link className="hover:text-cyan-400 font-semibold" to="/register"> Register Now! </Link>
//                 </div>
//             </form>
//         </div>
//     )
// }

// export default LoginPage;
