const TypeCard = ({ icon, title }) => {
  return (
    <div className="m-10 flex flex-col items-center hover:scale-110 duration-200">
      <div className="relative bg-gray-600 rounded-full w-[150px] cursor-pointer">
        <img className="w-[200px]" src={icon} alt="" />
      </div>
      <h1 className="mt-2 text-center">{title}</h1>
    </div>
  );
};

export default TypeCard;
