import { useState, useEffect } from "react";
import axios from "axios";

const PaymentResult = () => {
    // const [result, setResult] = useState('loading');
    // useEffect(()=>{
    //   const url = 'https://554c-105-196-51-39.ngrok-free.app/api/state';
  
    //   const headers = {
    //     'Content-Type': 'application/json', 
    //   };
  
    //   // Make the Axios POST request
    //   axios.get(url)
    //     .then((response) => {
    //       setResult(response.data); // Set the response data in the component's state
    //     })
    //     .catch((error) => {
    //       console.error('Error fetching data:', error);
    //     });
    // }, []);
    return(<div>land</div>)
}

export default PaymentResult;