const Guarantees = () => {
  return (
    <div className="text-center flex-col md:flex-row flex justify-around py-16 bg-transparent gaurantees text-white z-20">
      <div>
        <h1 className="text-[60px]">+100</h1>
        <p className="text-2xl md:max-w-[200px]">Workout Videos</p>
      </div>
      <div>
        <h1 className="text-[60px] mt-16 md:mt-0">+120</h1>
        <p className="text-2xl">Meals</p>
      </div>
      <div>
        <h1 className="text-[60px] mt-16 md:mt-0">24/7</h1>
        <p className="text-2xl">Active</p>
      </div>
    </div>
  );
};

export default Guarantees;
