import ham from "./images/Hamburger.png";
import coffee from "./images/coffee.png";
import icon from "../components/images/name.png";
import { Link } from "react-router-dom";

const BlogPage = () => {
  return (
    <div className="meals-page pt-2 w-full">
      <div
        dir="rtl"
        className="rounded-lg min-w-[400px] w-full flex flex-col items-center justify-center w-full m-2"
      >
        <div className="max-w-[1200px] min-w-[400px] z-[999] bg-gray-200 rounded-lg">
          
          <div className="flex w-full bg-white rounded-t-lg justify-between px-8 text-xl">
            <img className="w-[100px] rounded-lg" src={icon} alt="" />{" "}
            <Link to='/'>
            <button className="p-1 cursor-pointer">رجوع &#8592;</button>
            </Link>
          </div>
          <div className="flex items-center p-4 md:flex-row flex-col">
            <img className="p-4 md:w-2/5 z-100" src={ham} alt="" />
            <p className="p-4 shadow-2xl rounded-lg bg-white  border-t-4 border-blue-400">
              <span className="text-2xl">الطعام من منظور آخر</span>
              <p className="pt-4 text-lg">
                {" "}
                طبق الطعام الذي أمامنا يجب ان نراه بعين مختلفة، فهو بالتأكيد
                أكثر من مجرد لذة حسية لحظية، وأكثر من مجرد نظام غذائي معين نحصل
                من خلاله على جسم رشيق أو حالة صحية معينة، طبق الطعام عبارة عن
                حب، أدب، فهم وجودي، وربما أكثر. أعني، هل هي مجرد مصادفة أن تقترن
                قصة خلق الإنسان الأول وخطيئته الأولى بلقمة طعام!
              </p>
            </p>
          </div>
          <p className="pt-16 p-8">
            <span className="text-2xl">علاقة الانسان بالطعام</span>
            <p className="pt-4 text-lg">
              {" "}
              أما بالنسبة لعلاقتنا بالطعام بشكل عام، فأجد أنها غير مختلفة في
              بعدها عن الطرح الفكري والفلسفي. الكثير من الأحاديث الاجتماعية في
              العصر الحديث والتي تجسد علاقتنا بالطعام تنطلق من مفاهيم تنظر إلى
              الطعام كوسيلة لاكتساب الرشاقة والظهور بمظهر جميل، أو تنظر للطعام
              على أنه مجرد وقود أو وسيلة لنصبح أكثر انتاجية، أو أكثر صحة، أو
              مفاهيم تنظر على أنه مجلبة للذات الحسية اللحظية، وكل هذه المنطلقات
              مفهومة وطبيعية ومشروعة، لكنها تكاد تقول أن تعاملنا مع الطعام ما هو
              إلا تعامل براجماتي بحت؛ نريد أن نحصل على غاية معينة منه، وهذا ما
              يحدد علاقتنا به.
            </p>
          </p>
          <div className="rounded-lg p-4 flex md:flex-row flex-col items-center justify-center pt-8 bg-gray-200">
            <div className="flex flex-col items-center md:w-1/5 w-full">
              {/* <span className="text-2xl p-2 whitespace-nowrap">الإفراط في شرب القهوة</span> */}
              <img className="p-4 w-full" src={coffee} alt="" />
            </div>
            <p className="pt-4 text-lg md:w-1/2 w-full border-b-2 border-blue-500 shadow-2xl p-4 bg-white">
              {" "}
              ان استهلاك الكثير للقهوة يؤدي الي زيادة في معدلات الكولسترول كما
              ان هناك علاقة وثيقة بين الاكثار من المنبهات كالقهوة والشاي وارتفاع
              ضغط الدم وتناقص قدرة القلب علي الانتفاع من الاكسجين
            </p>{" "}
          </div>
          <p className="flex flex-col items-center p-4 bg-stone-700 text-white">
            <span className="text-2xl p-2 bg-stone-900 rounded-full px-8">
              ادراك الجسد
            </span>
            <br />{" "}
            <p className="text-lg p-2">
              {" "}
              لكل فعل رد فعل كما ينص قانون نيوتن جسدك يستحق الرعاية لا تطلب منه
              الكثير وانت تعطيه القليل لا تطلب منه فعل الكثير من المهام علي مدار
              اليوم وانت تعطيه القليل من الطاقة والغذاء والنوم عليك أن تعلم إنك
              جسدك ليس روبوت حتى الروبوت يحتاج إلى صيانة ومصدر للطاقة واهتمام
              لكي يؤدي بكفاءة عالية وكذلك جسدك لا تجعل هدفك وروحك ويجعلك تدمر
              جسدك يجب أن تعطي مصادر الأكل والطاقة المناسبة والصحية ليس اي مصادر
              يجب أن تحرص ما هو نوع الوقود لكي تطلب الكفاءة العالية في إنجاز
              مهامك الروبوت يحتاج إلى صيانة كذلك جسدك يحتاج إلى الاستشفاء والنوم
              والغذاء هم أول خطوة لحياة صحية افهم جسدك واعلم أن هو مثلك تماما
              أنت لك احتياجات وهو أيضا أنت تحب وتكره وهو أيضا أنت من تتحكم في
              إنجاز العمل بدقة وكفاءة عالية عليك أن تدرك مدى أهمية الجسد وما
              يناسبه من الطعام وما يحتاج في كل وقت وكل فعل ونشاط عليك الاهتمام
              بنفسك وجسدك عندما يكون جسدك صحي وقوي سوف تعلم ما هو النجاح الحقيقي
              .
            </p>
          </p>
        </div>
      </div>
    </div>
  );
};

export default BlogPage;
