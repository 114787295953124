import pfp from "./images/profile-pic.png";
import { Link } from "react-router-dom";

const AboutPage = () => {
  return (
    <div className=" mt-24 py-16 bg-gray-200 flex flex-col p-10 justify-center items-center">
      <div className="flex relative  items-center md:flex-row flex-col">
        <div className="md:ml-8 flex flex-col justify-center border md:p-16 p-4 bg-white shadow-2xl rounded-xl">
          <div className="flex items-center">
            <img
              className="rounded-full w-[100px] z-20 relative"
              src={pfp}
              alt=""
            />
            <h1 className="text-black text-2xl md:text-4xl font-semibold mr-4">
              مازن الشهاوي
            </h1>
          </div>
          <p className="mt-4 text-lg md:text-2xl text-start text-black md:max-w-[700px] rounded-lg">
            لاعب كرة قدم ومعد بدني لفرق كرة القدم، مدرب خاص لتطوير وتنمية لاعبي
            كرة القدم، مدرب وأخصائي تغذية معتمد في مجال الصحة والغذاء واللياقة
            البدنية. هدفي تحقيق أهداف العملاء ومساعدتهم على تحقيق أهدافهم بغض
            النظر عن الإمكانيات والظروف. لدي العديد من الشهادات لتوجيه العملاء
            بأحدث الأساليب علمية.
          </p>
          <div>
            <Link to="/about">
              <button className="py-2 border-b-2 border-cyan-400 text-xl text-start mt-4">
                المزيد
              </button>
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AboutPage;
