import { useState, useEffect } from "react";
import running from "./images/running.jpg";
import axios from "axios";
import { Link } from "react-router-dom";

const HomePage = ({ join }) => {
  const [user, setUser] = useState(null);
  // var serverUrl = "https://coach-mazen-server.vercel.app";

  // useEffect(() => {
  //   const getUser = () => {
  //     axios
  //       .get(`${serverUrl}/auth/login/success`, {
  //         headers: {
  //           Accept: "application/json",
  //           "Content-Type": "application/json",
  //         },
  //       })
  //       .then((response) => {
  //         if (response.status === 200) return response.data; // Use response.data instead of response.json()
  //         throw new Error("Authentication has failed!");
  //       })
  //       .then((resObject) => {
  //         setUser(resObject.user);
  //         console.log(user.paid);
  //       })
  //       .catch((err) => {
  //         console.log(err);
  //       });
  //   };
  //   getUser();
  // }, []);

  return (
    <section>
      <div className="bg-white flex lg:flex-row justify-center flex-col p-4 py-24 items-center">
        <div className="flex items-center justify-around md:flex-row flex-col">
          <div className="md:ml-16 w-full flex flex-col justify-around rounded-lg">
            <h3 className="text-cyan-400 text-xl font-semibold border-black py-4 text-start w-[350px]">
              هل انت مستعد للخطوة القادمة؟
            </h3>
            <h1 className="text-5xl font-semibold">أهلا بكم في موقعي</h1>
            <p className="text-xl mt-4">
              {" "}
              رحلة متكاملة للحصول علي جسد و حياة صحيين{" "}
            </p>
            {user && user.paid === "no" ? (
              <Link to="/onlinecoaching">
                <button className="w-full border-y-2 border-blue-400 rounded-lg my-4 p-4 text-lg hover:bg-blue-400 duration-200 hover:text-white">
                  انضم الان في التدريب الأونلاين
                </button>
              </Link>
            ) : (
              <Link to="/onlinecoaching">
                <button className="w-full border-y-2 border-blue-400 rounded-lg m-4 p-4 text-lg hover:bg-blue-400 duration-200 hover:text-white">
                  انضم الان في التدريب الأونلاين
                </button>
              </Link>
            )}
            {user && user.age === 0 ? (
              <div className="bg-red-800 text-white p-4 rounded-lg fixed bottom-2 left-2">
                اكمل الاستبيان الخاص بك!
              </div>
            ) : null}
          </div>
          <img className="rounded-full w-[450px]" src={running} alt="" />
        </div>
      </div>
    </section>
  );
};

export default HomePage;
