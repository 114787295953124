// import google from "./images/google.png";
import { Link } from "react-router-dom";

const Signup = ({ isVisible, close, change }) => {
  var serverUrl = "https://coach-mazen-server.vercel.app";

  if (!isVisible) return null;

  const google = () => {
    window.open(`${serverUrl}/auth/google`, "_self");
  };

  return (
    <>
      <div className="rounded-lg w-[320px] h-[150px] flex justify-center items-center bg-white p-8 fixed left-1/2 top-1/2 transform -translate-x-1/2 -translate-y-1/2 flex overflow-hidden z-40">
        <button onClick={google} type="submit" className="flex items-center text-xl rounded-lg shadow-xl py-2">
          {/* <img src={google} alt="" className="w-6 m-2" /> */}
          <span className="m-2 font-semibold">continue with Google</span>
        </button>
      </div>
      <div
        className="bg-black opacity-70 fixed w-full h-full z-30 top-0"
        onClick={close}
      ></div>
      </>
  );
};

export default Signup;
