import { useState } from "react";

const Videos = ({ data }) => {
  const [num, setNum] = useState(0);

  const selectVideo = (index) => {
    setNum(index);
    handleStyle(index);
  };

  const handleStyle = (index) => {
    document.querySelectorAll(".btn").forEach((btn) => {
      btn.classList.remove("bg-white");
      btn.classList.add("text-white");
    });
    document.getElementById(index).classList.add("bg-white");
    document.getElementById(index).classList.remove("text-white");
  };

  const sub = () => {
    const prevIndex = (num - 1 + data.length) % data.length;
    setNum(prevIndex);
    handleStyle(prevIndex);
  };

  const add = () => {
    const nextIndex = (num + 1) % data.length;
    setNum(nextIndex);
    handleStyle(nextIndex);
  };

  return (
    <div>
      <div className="w-full left-0 flex items-center p-4 whitespace-nowrap left-4 bg-stone-950 overflow-scroll">
        {data.map((video, index) => (
          <div className="mx-6" key={index}>
            <button
              onClick={() => selectVideo(index)}
              id={index}
              className={`${
                num === index ? "bg-white text-black" : "text-white"
              } bg-stone-800 btn h-[40px] p-2 px-4 rounded-lg`}
            >
              {video.Name}
            </button>
          </div>
        ))}
      </div>
      <div className="flex flex-wrap md:flex-nowrap justify-center items-center p-4 py-12 bg-gray-200">
        <div className="shadow-lg rounded-xl overflow-hidden">{data[num].video}</div>
        <div className="p-3 ml-6 max-w-[600px] shadow-2xl rounded-xl bg-white">
          <h1 className="text-4xl py-2 mb-6 font-semibold text-gray-600 w-[350px]">
            {data[num].Name}
          </h1>
          <p className="text-xl">{data[num].Notes}</p>
          <div className="flex justify-between pt-8">
            <button
              onClick={sub}
              className="border border-blue-500 text-blue-500 rounded p-2"
            >
              Back
            </button>{" "}
            <button
              onClick={add}
              className="bg-blue-500 p-2 rounded-lg text-white"
            >
              Next
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Videos;
