export const BreakFastMeals = [
  {
    ingred: ["100gm بطاطس مشوية", " 100gm جبنة قريش", "5gm زيت زيتون"],
    details: {
      protein: <p>12</p>,
      carb: <p>20</p>,
      fats: <p>10</p>,
      calories: <p>210</p>,
    },
  },
  {
    ingred: ["100gm موز", "100gm لبن كامل الدسم", "شوفان 100gm"],
    details: {
      protein: <p>10</p>,
      carb: <p>62</p>,
      fats: <p>6</p>,
      calories: <p>310</p>,
    },
  },
  {
    ingred: [
      "زبدة فول سوداني 15gm",
      "100gm موز",
      "100gm لبن كامل الدسم",
      "شوفان 100gm",
    ],
    details: {
      protein: <p>14</p>,
      carb: <p>65</p>,
      fats: <p>14</p>,
      calories: <p>404</p>,
    },
  },
  {
    ingred: ["1 توست بني", "2 بيض بالخضار"],
    details: {
      protein: <p>15</p>,
      carb: <p>22</p>,
      fats: <p>8</p>,
      calories: <p>230</p>,
    },
  },
  {
    ingred: [" بان كيك شوفان", " موز 100gm", "معلقة صغيرة عسل"],
    details: {
      protein: <p>14</p>,
      carb: <p>60</p>,
      fats: <p>8</p>,
      calories: <p>316</p>,
    },
  },
  {
    ingred: ["1توست بني", " جبنة قريش 50gm", " زيت زيتون 5gm", "كوب شاي اخضر"],
    details: {
      protein: <p>9</p>,
      carb: <p>15</p>,
      fats: <p>7</p>,
      calories: <p>179</p>,
    },
  },
  {
    ingred: ["زيت زيتون 5gm", "فول مدمس 100gm", "١/٢ رغيف"],
    details: {
      protein: <p>13</p>,
      carb: <p>39</p>,
      fats: <p>8</p>,
      calories: <p>272</p>,
    },
  },
  {
    ingred: ["شوفان 50gm", "لبن كامل الدسم 100gm", "موز 100gm", "مكسرات 30gm"],
    details: {
      protein: <p>16</p>,
      carb: <p>68</p>,
      fats: <p>20</p>,
      calories: <p>483</p>,
    },
  },
  {
    ingred: ["بيض أوميلت2", "10gm مشروم"],
    details: {
      protein: <p>22</p>,
      carb: <p>7</p>,
      fats: <p>22</p>,
      calories: <p>301</p>,
    },
  },
  {
    ingred: ["زيت زيتون 5gm", "سلطة خضراء 100gm", "جبنة قريش 100gm"],
    details: {
      protein: <p>12</p>,
      carb: <p>10</p>,
      fats: <p>6</p>,
      calories: <p>125</p>,
    },
  },
  {
    ingred: ["جبنة قريش 50gm", "بيض2", "توست بني 1"],
    details: {
      protein: <p>21</p>,
      carb: <p>15</p>,
      fats: <p>10</p>,
      calories: <p>257</p>,
    },
  },
  {
    ingred: ["سلطة gm100", "1/2 علبة تونة"],
    details: {
      protein: <p>26</p>,
      carb: <p>8</p>,
      fats: <p>18</p>,
      calories: <p>279</p>,
    },
  },
  {
    ingred: ["موز 100gm", "زبادي", "جرانولا 100gm"],
    details: {
      protein: <p>18</p>,
      carb: <p>90</p>,
      fats: <p>25</p>,
      calories: <p>576</p>,
    },
  },
  {
    ingred: ["زبادي", "فاكهة مجففة20gm", "شوفان 50gm"],
    details: {
      protein: <p>14</p>,
      carb: <p>56</p>,
      fats: <p>8</p>,
      calories: <p>371</p>,
    },
  },
  {
    ingred: ["2 توست اسمر", "مافن بيض"],
    details: {
      protein: <p>17</p>,
      carb: <p>40</p>,
      fats: <p>3</p>,
      calories: <p>255</p>,
    },
  },
  {
    ingred: ["بان كيك شوفان بالشوكلاتة "],
    details: {
      protein: <p>13</p>,
      carb: <p>61</p>,
      fats: <p>10</p>,
      calories: <p>370</p>,
    },
  },
  {
    ingred: ["Roast beef and cheese wrap "],
    details: {
      protein: <p>20</p>,
      carb: <p>33</p>,
      fats: <p>13</p>,
      calories: <p>323</p>,
    },
  },
  {
    ingred: ["فرنش توست "],
    details: {
      protein: <p>11</p>,
      carb: <p>45</p>,
      fats: <p>9</p>,
      calories: <p>320</p>,
    },
  },
  {
    ingred: ["رايس كيك 2", "gm10زبدة فول سوداني ", "gmموز 100"],
    details: {
      protein: <p>2</p>,
      carb: <p>30</p>,
      fats: <p>5</p>,
      calories: <p>173</p>,
    },
  },
  {
    ingred: ["خيار ", "جبنة قريش 50gm", "توست بني", "بيض ٣ "],
    details: {
      protein: <p>30</p>,
      carb: <p>19</p>,
      fats: <p>25</p>,
      calories: <p>370</p>,
    },
  },
  {
    ingred: [
      "100gm موز",
      "رشة قرفة",
      "20gm مكسرات",
      "2 معلقة صغيرة بذور شيا",
      "زبادي يوناني",
    ],
    details: {
      protein: <p>16</p>,
      carb: <p>15</p>,
      fats: <p>19</p>,
      calories: <p>428</p>,
    },
  },
  {
    ingred: [
      "كريب الشوفان الحادق(50gm شوفان +50gm لبن خالي الدسم +بيضة )",
      "حبة بطاطس مسلوقة",
      "بقدونس",
    ],
    details: {
      protein: <p>20</p>,
      carb: <p>54</p>,
      fats: <p>12</p>,
      calories: <p>516</p>,
    },
  },
  {
    ingred: ["طبق فول بالبيض", "100 gmسلطة", "1/4 رغيف"],
    details: {
      protein: <p>15</p>,
      carb: <p>27</p>,
      fats: <p>10</p>,
      calories: <p>194</p>,
    },
  },
  {
    ingred: ["1 توست اسمر ", "تشيز كيك دايت", " 10gmجبنة كريمي ", "gm10مربي"],
    details: {
      protein: <p>10</p>,
      carb: <p>26</p>,
      fats: <p>33</p>,
      calories: <p>454</p>,
    },
  },
  {
    ingred: ["دايت المرارة", "gm50خضار", "جبنة قليلة الملح gm50", "1/4 رغيف"],
    details: {
      protein: <p>8</p>,
      carb: <p>11</p>,
      fats: <p>4</p>,
      calories: <p>129</p>,
    },
  }
];

export const DinnerMeals = [
  {
    ingred: [
      "بارفيه الزبادى (كوب زبادى كبير +كوب فاكهة متنوعة +ملعقة بذور الشيا+ملعقة ص مربى او عسل +كبشة مكسرات نية )",
    ],
    details: {
      protein: <p>8</p>,
      carb: <p>39</p>,
      fats: <p>2</p>,
      calories: <p>210</p>,
    },
  },
  {
    ingred: [
      "دايت للغده الدرقيه خالي من الجيلوتين",
      "٣ رايس توست",
      "جبنه قريش 50gm",
      "خضار",
    ],
    details: {
      protein: <p>9</p>,
      carb: <p>30</p>,
      fats: <p>2</p>,
      calories: <p>203</p>,
    },
  },
  {
    ingred: [
      "دايت للغده الدرقيه خالي من الجيلوتين",
      "chia pudding",
      "ثمرة فاكهة",
    ],
    details: {
      protein: <p>4.6</p>,
      carb: <p>16.5</p>,
      fats: <p>4.3</p>,
      calories: <p>120</p>,
    },
  },
  {
    ingred: [" رغيف بلدي1/4", "gm50 جبنه", "2فلافل غير مقليه"],
    details: {
      protein: <p>10.5</p>,
      carb: <p>13.5</p>,
      fats: <p>7</p>,
      calories: <p>200</p>,
    },
  },
  {
    ingred: ["نص ثمره افوكادو", "1 توست بني", "سلطه متنوعه gm50", "بيضه"],
    details: {
      protein: <p>15.2</p>,
      carb: <p>30.5</p>,
      fats: <p>25.7</p>,
      calories: <p>362</p>,
    },
  },
  {
    ingred: ["ثمره فاكهه", "لبن 50gm", "كورن فلكس بدون سكر 100gm"],
    details: {
      protein: <p>9.8</p>,
      carb: <p>44</p>,
      fats: <p>5.8</p>,
      calories: <p>265</p>,
    },
  },
  {
    ingred: ["بصل", "2بيض", "طماطم", "فلفل الوان"],
    details: {
      protein: <p>13.2</p>,
      carb: <p>14.2</p>,
      fats: <p>11</p>,
      calories: <p>215</p>,
    },
  },
  {
    ingred: ["اتنين ثمره فاكهه ", "gm20 مكسرات", "gm50 جرانولا"],
    details: {
      protein: <p>10.6</p>,
      carb: <p>52</p>,
      fats: <p>17</p>,
      calories: <p>399</p>,
    },
  },
  {
    ingred: ["كوب لبن", "3 تمر او بلح", "1 توست", "جبنه"],
    details: {
      protein: <p>9</p>,
      carb: <p>54</p>,
      fats: <p>6</p>,
      calories: <p>331</p>,
    },
  },
  {
    ingred: [
      "كوب هوت شوكليت (كاكاو خام مع لبن ) ",
      "2 كوكيز شوفان صحي",
      "تفاحه",
    ],
    details: {
      protein: <p>4</p>,
      carb: <p>13</p>,
      fats: <p>5</p>,
      calories: <p>213</p>,
    },
  },
  {
    ingred: ["كينوا gm50", "معلقه زبده فول سوداني", "اثنين موزه"],
    details: {
      protein: <p>9</p>,
      carb: <p>19.5</p>,
      fats: <p>11</p>,
      calories: <p>280</p>,
    },
  },
  {
    ingred: [
      "لمرضى القولون العصبي:",
      "زبادي يوناني 150gm",
      "موزه ",
      "10gm بذور كتان",
      "30 جم مكسرات",
    ],
    details: {
      protein: <p>12</p>,
      carb: <p>27</p>,
      fats: <p>21.3</p>,
      calories: <p>347</p>,
    },
  },
  {
    ingred: [" رغيف جيلوتين فري 1/4", "بيضه", "2 خيار"],
    details: {
      protein: <p>13</p>,
      carb: <p>70</p>,
      fats: <p>12</p>,
      calories: <p>391</p>,
    },
  },

  {
    ingred: ["لبنة gm100", "زعتر", "سلطة", "1توست"],
    details: {
      protein: <p>13</p>,
      carb: <p>25.6</p>,
      fats: <p>2</p>,
      calories: <p>189</p>,
    },
  },
  {
    ingred: ["بليلة gm 50", "كوب لبن", "ملعقة قرفة", "٣تمر"],
    details: {
      protein: <p>9</p>,
      carb: <p>51</p>,
      fats: <p>5.6</p>,
      calories: <p>365</p>,
    },
  },
  {
    ingred: ["بطاطا 100gm", "٣ تمرات ", "كبشة مكسرات20gm"],
    details: {
      protein: <p>8</p>,
      carb: <p>63</p>,
      fats: <p>14</p>,
      calories: <p>377</p>,
    },
  },
  {
    ingred: ["نصف مانجو", "٢خوخ بالشوفان بالزبادي"],
    details: {
      protein: <p>19</p>,
      carb: <p>90</p>,
      fats: <p>9</p>,
      calories: <p>498</p>,
    },
  },
  {
    ingred: ["كوب شاي اخضر", "جزرتين ", "١توست كيرى بالزيتون"],
    details: {
      protein: <p>4</p>,
      carb: <p>18</p>,
      fats: <p>4</p>,
      calories: <p>130</p>,
    },
  },
  {
    ingred: ["Fruit salad+زبادى يونانى"],
    details: {
      protein: <p>9</p>,
      carb: <p>28</p>,
      fats: <p>3</p>,
      calories: <p>182</p>,
    },
  }
]

/*   Lunch   */ 

export const LunchMeals = [
  {
    ingred: ["صدور فراخ بدون جلد 250 gm", "فاصوليا خضراء 50gm", "أرز 50gm"],
    details: {
      protein: <p>76</p>,
      carb: <p>19</p>,
      fats: <p>8</p>,
      calories: <p>458</p>,
    },
  },
  {
    ingred: ["مكرونة 50gm", "كفتة مشوية 100gm", "سلطة 50gm"],
    details: {
      protein: <p>26</p>,
      carb: <p>31</p>,
      fats: <p>20</p>,
      calories: <p>356</p>,
    },
  },
  {
    ingred: ["رز 50gm", "سمك مشوي 300gm", "سلطة 50gm"],
    details: {
      protein: <p>64</p>,
      carb: <p>28</p>,
      fats: <p>6.1</p>,
      calories: <p>403</p>,
    },
  },
  {
    ingred: [
      "صينية بطاطس بالحم 50gmبطاطس  و100gm لحمة",
      "أرز 50gm",
      "سلطة 50gm",
    ],
    details: {
      protein: <p>36</p>,
      carb: <p>43</p>,
      fats: <p>13</p>,
      calories: <p>434</p>,
    },
  },
  {
    ingred: [
      "كشري (ارز 50gm",
      "مكرونة 50gm",
      "عدس 50gm ",
      "حمص 10gm",
      "سلطة 50gm",
    ],
    details: {
      protein: <p>9</p>,
      carb: <p>41</p>,
      fats: <p>6</p>,
      calories: <p>222</p>,
    },
  },
  {
    ingred: ["مكرونة بشاميل 50gm", "فراخ مشوية 100gm", "سلطة 50gm"],
    details: {
      protein: <p>47.7</p>,
      carb: <p>78</p>,
      fats: <p>16</p>,
      calories: <p>475.5</p>,
    },
  },
  {
    ingred: ["أرز 100gm ", "لحم مشوي 100gm", "سلطة 50gm ", "خل تفاح 10gm"],
    details: {
      protein: <p>34</p>,
      carb: <p>42</p>,
      fats: <p>10</p>,
      calories: <p>384</p>,
    },
  },
  {
    ingred: ["شوربة خضار 100gm", "صدور فراخ 250 gm "],
    details: {
      protein: <p>76.4</p>,
      carb: <p>9</p>,
      fats: <p>10.5</p>,
      calories: <p>428.5</p>,
    },
  },
  {
    ingred: [
      "2 شريحة بيتزا بيتي",
      "سجق او تونة او لحم مفروم او فراخ ",
      "سلطة ",
    ],
    details: {
      protein: <p>13</p>,
      carb: <p>40</p>,
      fats: <p>12</p>,
      calories: <p>303</p>,
    },
  },
  {
    ingred: ["فريك 100gm", "صدور فراخ  250gm", "خضار سوتيه 50gm"],
    details: {
      protein: <p>87.5</p>,
      carb: <p>64.5</p>,
      fats: <p>4</p>,
      calories: <p>485.5</p>,
    },
  },
  {
    ingred: ["كفتة 100gm", "عدس 100gm", "سلطة 50gm"],
    details: {
      protein: <p>30</p>,
      carb: <p>14</p>,
      fats: <p>18</p>,
      calories: <p>437</p>,
    },
  },
  {
    ingred: ["مسقعة صحية باللحم المفروم 150gm", "رغيف بلدي"],
    details: {
      protein: <p>57</p>,
      carb: <p>69</p>,
      fats: <p>31.1</p>,
      calories: <p>827</p>,
    },
  },
  {
    ingred: ["مكرونة  50gm", "صدور فراخ بدون جلد 250 gm"],
    details: {
      protein: <p>76</p>,
      carb: <p>12</p>,
      fats: <p>8</p>,
      calories: <p>458</p>,
    },
  },
  {
    ingred: ["كفتة 150gm", "سلطة 50gm"],
    details: {
      protein: <p>32.5</p>,
      carb: <p>16</p>,
      fats: <p>27</p>,
      calories: <p>456.5</p>,
    },
  },
  {
    ingred: ["سلطة تونة"],
    details: {
      protein: <p>31</p>,
      carb: <p>16</p>,
      fats: <p>7</p>,
      calories: <p>248</p>,
    },
  },
  {
    ingred: [
      "1 كوردن بلو بطريقة صحية",
      "(شريحة بانيةشريحة جبنة شيدر +١ رومي مدخن )",
      "مكرونة ",
    ],
    details: {
      protein: <p>22</p>,
      carb: <p>19</p>,
      fats: <p>7</p>,
      calories: <p>261</p>,
    },
  },
  {
    ingred: ["سردين بالفرن 100gm", "بطاطس مهروسة 50gm", "رغيف بلدي 1/2"],
    details: {
      protein: <p>27.6</p>,
      carb: <p>36</p>,
      fats: <p>15</p>,
      calories: <p>406</p>,
    },
  },

  {
    ingred: ["ارنب 250gm", "ملوخية 50gm", "ارز بسمتي 100gm"],
    details: {
      protein: <p>84.5</p>,
      carb: <p>28.5</p>,
      fats: <p>10</p>,
      calories: <p>567.5</p>,
    },
  },
  {
    ingred: ["قطعة بيتزا دقيق الشوفان بالخضار والمشروم"],
    details: {
      protein: <p>11</p>,
      carb: <p>39</p>,
      fats: <p>9</p>,
      calories: <p>280</p>,
    },
  },
  {
    ingred: ["شربة عدس وبروكلي", "سلطة خضراء 50gm"],
    details: {
      protein: <p>9</p>,
      carb: <p>17</p>,
      fats: <p>2</p>,
      calories: <p>107</p>,
    },
  },
  {
    ingred: ["جمبري وسبيط مشوي 100gm", "خضار سوتيه 50gm"],
    details: {
      protein: <p>25</p>,
      carb: <p>13</p>,
      fats: <p>1</p>,
      calories: <p>730</p>,
    },
  },
  {
    ingred: [
      "كينوا 50gm",
      "صدور فراخ 250gm",
      "صدور فراخ 250gm",
      "سلطة زبادي بالكرفس والبقدونس 20gm",
    ],
    details: {
      protein: <p>82.4</p>,
      carb: <p>48</p>,
      fats: <p>12</p>,
      calories: <p>730</p>,
    },
  },
  {
    ingred: ["سمك سلمون بدون شوك 150gm", "سلطة خضراء 50gm"],
    details: {
      protein: <p>31.5</p>,
      carb: <p>7</p>,
      fats: <p>5.7</p>,
      calories: <p>240</p>,
    },
  },
  {
    ingred: ["سمك الهلبوت 300gm", "أرز50gm", "سلطة خضراء 50gm"],
    details: {
      protein: <p>68</p>,
      carb: <p>21</p>,
      fats: <p>10</p>,
      calories: <p>454</p>,
    },
  },
  {
    ingred: ["برغل 20gm", "بروكلي 50gm ", "سمك فيليه 100gm", "أرز 50gm"],
    details: {
      protein: <p>21</p>,
      carb: <p>19</p>,
      fats: <p>3</p>,
      calories: <p>193</p>,
    },
  },
  {
    ingred: ["سجق gm100", "فلفل الوان gm20", "gmطماطم 15", "رغيف بلدي ١/٢"],
    details: {
      protein: <p>16</p>,
      carb: <p>14</p>,
      fats: <p>28</p>,
      calories: <p>389</p>,
    },
  },
  {
    ingred: ["كوسة محشية لحمة مفرومة gm150", "سلطة"],
    details: {
      protein: <p>78</p>,
      carb: <p>52</p>,
      fats: <p>33</p>,
      calories: <p>854</p>,
    },
  },
  {
    ingred: ["سمك مشوي gm300", "بطاطس مهروسة gm50", "سلطة خضراء gm50"],
    details: {
      protein: <p>65</p>,
      carb: <p>23</p>,
      fats: <p>4.2</p>,
      calories: <p>393</p>,
    },
  },
  {
    ingred: ["شرايح بانيه مشوي علي الجريل gm100", "مكرونة بالصلصة 50gm"],
    details: {
      protein: <p>32</p>,
      carb: <p>11</p>,
      fats: <p>4</p>,
      calories: <p>310</p>,
    },
  },
  {
    ingred: [
      "شيش طاووق gm100",
      "مكرونة gm100",
      "سلطة خضراء gm50",
      "زيت زيتون 10gm",
    ],
    details: {
      protein: <p>37</p>,
      carb: <p>42</p>,
      fats: <p>13</p>,
      calories: <p>617</p>,
    },
  },
  {
    ingred: ["ورقة لحمة (4قطعة لحمة مع خضار متنوع وبطاطس) ", "أرز gm50"],
    details: {
      protein: <p>42.8</p>,
      carb: <p>46.1</p>,
      fats: <p>3.1</p>,
      calories: <p>441</p>,
    },
  },
  {
    ingred: ["جمبري gm100", "أرز بسمتي 100gm", "سلطة خضراء 50gm"],
    details: {
      protein: <p>22.3</p>,
      carb: <p>33</p>,
      fats: <p>1.7</p>,
      calories: <p>271</p>,
    },
  },
  {
    ingred: ["سمك ماكريل 100gm", "سلطة خضراء 50gm", "زيت زيتون 10gm"],
    details: {
      protein: <p>26</p>,
      carb: <p>7</p>,
      fats: <p>28</p>,
      calories: <p>396.4</p>,
    },
  },
  {
    ingred: ["قرنبيط بالحمة المفرومة ( لحمة مفرومة gm100)", "أرز 50gm"],
    details: {
      protein: <p>31</p>,
      carb: <p>88</p>,
      fats: <p>7.9</p>,
      calories: <p>560</p>,
    },
  },
  {
    ingred: [
      "فراخ مشوية 250",
      "سلطة الكينوا بالأفوكادو (كينوا  50gm +افوكادو١/٢ +فلفل +طماطم +كزبره +ليمون + زيت زيتون 10gm +ملح)",
    ],
    details: {
      protein: <p>85.3</p>,
      carb: <p>27.7</p>,
      fats: <p>33</p>,
      calories: <p>809.5</p>,
    },
  },
  {
    ingred: [
      "سلطة تونة بالمكرونة (ملاعق مكرونة 50gm + علبة تونة مصفاه من الزيت +طماطم +خيار +بصل +زيت زيتون 10gm)",
    ],
    details: {
      protein: <p>28</p>,
      carb: <p>27</p>,
      fats: <p>17.9</p>,
      calories: <p>379</p>,
    },
  },
  {
    ingred: ["محشي كرنب أو ورق عنب 50gm ", "فراخ مشوية 250gm"],
    details: {
      protein: <p>77.7</p>,
      carb: <p>23</p>,
      fats: <p>8</p>,
      calories: <p>452.3</p>,
    },
  },
  {
    ingred: [
      "صدور فراخ مشوي علي الجريل 250gm",
      "سلطة أفوكادو (افوكادو 1/2 + خس +نعناع +زيت الزيتون 10gm+فجل+بصل +لمونة +ملح +فلفل ) ",
      "مكرونة 50gm",
    ],
    details: {
      protein: <p>76.3</p>,
      carb: <p>28</p>,
      fats: <p>17.5</p>,
      calories: <p>630</p>,
    },
  },
  {
    ingred: ["نجرسكو بالفراخ صحية 100gm", "سلطة خضراء 50gm"],
    details: {
      protein: <p>36.6</p>,
      carb: <p>46.9</p>,
      fats: <p>23.2</p>,
      calories: <p>547</p>,
    },
  },
  {
    ingred: [
      "(2كايزر بدقيق اسمر + البرجر لحم مشوي 100gm +طماطم +خس ) ",
      "بطاطس مشويه 50gm",
    ],
    details: {
      protein: <p>33</p>,
      carb: <p>54</p>,
      fats: <p>8</p>,
      calories: <p>443</p>,
    },
  },
  {
    ingred: [
      "فراخ كنتاكي صحية 250gm(ملعقة شوفان مجروش ودقيق الارز ورشة زيت وتسوي في الفرن ) ",
      "كول سلو صحي 100gm ( كرنب ابيض مقطع 10gm+جزر مبشور 10gm+ ملعقة مايونيز + ملعقة مستردة + ملعقة عسل نحل + زبادي + 1/4كوب لبن +ملعقة خل تفاح )",
    ],
    details: {
      protein: <p>79</p>,
      carb: <p>45</p>,
      fats: <p></p>,
      calories: <p>767</p>,
    },
  },
  {
    ingred: ["تورلي بالخضار ", "فراخ 250gm", "فريك 50gm"],
    details: {
      protein: <p>49.7</p>,
      carb: <p>68.3</p>,
      fats: <p>23.2</p>,
      calories: <p>654.5</p>,
    },
  },
  {
    ingred: [
      "فاهيتا فراخ بالفلفل الالوان والجزر وبصل 300gm",
      " أرز بسمتي 50gm",
    ],
    details: {
      protein: <p>30.2</p>,
      carb: <p>27.7</p>,
      fats: <p>11.9</p>,
      calories: <p>336.4</p>,
    },
  },
  {
    ingred: ["فول مدمس بالخلطة 180gm", "توست بني ", "سلطة خضراء 50gm"],
    details: {
      protein: <p>13</p>,
      carb: <p>48</p>,
      fats: <p>5</p>,
      calories: <p>326</p>,
    },
  },
  {
    ingred: ["عجة (بيض3)", "1/2 رغيف بلدي", "باذنجان مشوي 50gm "],
    details: {
      protein: <p>38</p>,
      carb: <p>30.3</p>,
      fats: <p>21</p>,
      calories: <p>384</p>,
    },
  },
  {
    ingred: ["مكرونة بالشوفان 100gm", " لحم مفروم 50gm", "سلطة 50gm"],
    details: {
      protein: <p>33</p>,
      carb: <p>16</p>,
      fats: <p>11</p>,
      calories: <p>482</p>,
    },
  },
  {
    ingred: ["حمام محشي 250gm", " فريك 50gm ", "طاجن بامية"],
    details: {
      protein: <p>53</p>,
      carb: <p>30</p>,
      fats: <p>22</p>,
      calories: <p>752.5</p>,
    },
  },
  {
    ingred: ["رغيف أسمر ١/٢", "جمبري 250gm ", "طحينة 10gm"],
    details: {
      protein: <p>56</p>,
      carb: <p>9</p>,
      fats: <p>9.4</p>,
      calories: <p>345</p>,
    },
  },
  {
    ingred: ["شوربة سي فوود ", "سمك مشوي 100gm", "طحينة 10gm"],
    details: {
      protein: <p>50</p>,
      carb: <p>28</p>,
      fats: <p>44</p>,
      calories: <p>727</p>,
    },
  },
  {
    ingred: [
      "أرز بسمتي 50gm",
      "سمك 100gm",
      "سلطة بطاطس بملعقة زيت الزيتون صغيرة 50gm",
    ],
    details: {
      protein: <p>22.8</p>,
      carb: <p>21.5</p>,
      fats: <p>12</p>,
      calories: <p>294</p>,
    },
  },
  {
    ingred: ["مكرونة بولونيز 100gm", "سلطة خضراء 50gm "],
    details: {
      protein: <p>30</p>,
      carb: <p>55</p>,
      fats: <p>17</p>,
      calories: <p>522</p>,
    },
  },
  {
    ingred: ["أرز بالخلطة 100gm ", " شاورما فراخ بالخضار"],
    details: {
      protein: <p>34.4</p>,
      carb: <p>53</p>,
      fats: <p>9.8</p>,
      calories: <p>509</p>,
    },
  },
  {
    ingred: ["أرز بالخلطة 100gm", "شاورما لحمة بالخضار"],
    details: {
      protein: <p>32.2</p>,
      carb: <p>55</p>,
      fats: <p>10.7</p>,
      calories: <p>522</p>,
    },
  },
  {
    ingred: ["عيش تورتيلا 100gm", "شاورما دجاج 100gm", "بطاطس مشوية 50gm"],
    details: {
      protein: <p>42.4</p>,
      carb: <p>100</p>,
      fats: <p>10.8</p>,
      calories: <p>666</p>,
    },
  },
  {
    ingred: [
      "مكرونة بالتونة والمشروم ",
      "(مكرونة 100gm + مشروم 50gm +علبة تونة مصفاة )",
    ],
    details: {
      protein: <p>31</p>,
      carb: <p>23</p>,
      fats: <p>19</p>,
      calories: <p>352</p>,
    },
  },
  {
    ingred: ["مكرونة بالصلصة 50gm ", "كبدة 100gm"],
    details: {
      protein: <p>26</p>,
      carb: <p>15</p>,
      fats: <p>6</p>,
      calories: <p>200</p>,
    },
  },
  {
    ingred: [
      "رغيف اسمر 1/2",
      "دجاج مشوي 250gm",
      "طبق سلطة 50gm",
      "زيت زيتون 10gm",
    ],
    details: {
      protein: <p>75</p>,
      carb: <p>19.4</p>,
      fats: <p>11</p>,
      calories: <p>580.3</p>,
    },
  },
  {
    ingred: [
      "صينية بطاطس بالفراخ  ",
      "(250gm فراخ ثمرة + بطاطس )",
      "أرز بسمتي 50gm",
    ],
    details: {
      protein: <p>76</p>,
      carb: <p>23</p>,
      fats: <p>2</p>,
      calories: <p>460</p>,
    },
  },
  {
    ingred: ["طاجن بامية باللحمة 300gm ", "رغيف اسمر "],
    details: {
      protein: <p>34</p>,
      carb: <p>45</p>,
      fats: <p>13</p>,
      calories: <p>403</p>,
    },
  },
  {
    ingred: ["سيزر سالاد بالدجاج", "( صدور فراخ 250gm +دريسنج صحي 50gm)"],
    details: {
      protein: <p>26</p>,
      carb: <p>14</p>,
      fats: <p>7</p>,
      calories: <p>427.5</p>,
    },
  },
  {
    ingred: ["قطعة لازانيا 100gm", "سلطة", "فراخ 100gm"],
    details: {
      protein: <p>37</p>,
      carb: <p>22</p>,
      fats: <p>5.9</p>,
      calories: <p>336</p>,
    },
  },
  {
    ingred: [
      "طاجن لسان عصفور باللحمة المفرومة",
      "(4معالق لسان عصفور + لحم مفروم 100)",
    ],
    details: {
      protein: <p>11</p>,
      carb: <p>64</p>,
      fats: <p>1.3</p>,
      calories: <p>316</p>,
    },
  },
];

/* Snacks */

export const Snacks = [
  {
    ingred: ["ترمس gm100 "],
    details: {
      protein: <p>16</p>,
      carb: <p>10</p>,
      fats: <p>3</p>,
      calories: <p>119</p>,
    },
  },
  {
    ingred: ["مكسرات 20gm", " كاكاو بكوب لبن "],
    details: {
      protein: <p>8.9</p>,
      carb: <p>7.7</p>,
      fats: <p>18.8</p>,
      calories: <p>198</p>,
    },
  },
  {
    ingred: ["فشار 30gm "],
    details: {
      protein: <p>3.6</p>,
      carb: <p>23</p>,
      fats: <p>1.3</p>,
      calories: <p>106</p>,
    },
  },
  {
    ingred: ["عصير فاكهة باللبن", "(كوب لبن +ثمرة فاكهة)"],
    details: {
      protein: <p>2</p>,
      carb: <p>11.44</p>,
      fats: <p>1.17</p>,
      calories: <p>49</p>,
    },
  },
  {
    ingred: ["خيار ", "جزر "],
    details: {
      protein: <p>1</p>,
      carb: <p>8.7</p>,
      fats: <p>0.1</p>,
      calories: <p>34.1</p>,
    },
  },
  {
    ingred: ["ثمرة تفاح ", "زبادي صغيره منزوعة الدسم "],
    details: {
      protein: <p>7.4</p>,
      carb: <p>35</p>,
      fats: <p>2.3</p>,
      calories: <p>188</p>,
    },
  },
  {
    ingred: ["ثمره بطاطا "],
    details: {
      protein: <p>2.07</p>,
      carb: <p>26.76</p>,
      fats: <p>0.2</p>,
      calories: <p>114.7</p>,
    },
  },
  {
    ingred: ["فراولة5 ", "كوب لبن"],
    details: {
      protein: <p>2.6</p>,
      carb: <p>10.6</p>,
      fats: <p>1.3</p>,
      calories: <p>46</p>,
    },
  },
  {
    ingred: ["قهوة ", "موزة "],
    details: {
      protein: <p>1.3</p>,
      carb: <p>27</p>,
      fats: <p>0.4</p>,
      calories: <p>92</p>,
    },
  },
  {
    ingred: ["زبادي ", "بذور شيا 5gm", "ثمرة فاكهة"],
    details: {
      protein: <p>3</p>,
      carb: <p>14</p>,
      fats: <p>2</p>,
      calories: <p>172</p>,
    },
  },
  {
    ingred: ["عصير برتقال"],
    details: {
      protein: <p>0.5</p>,
      carb: <p>33.2</p>,
      fats: <p>0</p>,
      calories: <p>134.4</p>,
    },
  },
  {
    ingred: ["قهوة بدون سكر ", "فول سوداني 30gm"],
    details: {
      protein: <p>7</p>,
      carb: <p>4.6</p>,
      fats: <p>14</p>,
      calories: <p>161</p>,
    },
  },
  {
    ingred: ["100gm جيلي"],
    details: {
      protein: <p>0.3</p>,
      carb: <p>46.1</p>,
      fats: <p>0.03</p>,
      calories: <p>179</p>,
    },
  },
  {
    ingred: ["عصير فريش"],
    details: {
      protein: <p>0.7</p>,
      carb: <p>34</p>,
      fats: <p>0</p>,
      calories: <p>140</p>,
    },
  },
  {
    ingred: ["لب ابيض 10gm"],
    details: {
      protein: <p>2.3</p>,
      carb: <p>1.3</p>,
      fats: <p>4.6</p>,
      calories: <p>60</p>,
    },
  },
  {
    ingred: ["عصير ليمون بالنعناع "],
    details: {
      protein: <p>0.9</p>,
      carb: <p>21</p>,
      fats: <p>0</p>,
      calories: <p>61</p>,
    },
  },
  {
    ingred: [
      "سلطة فواكه ",
      "(1/2 تفاحة + 1/2 كوب عنب + كيوي + 1/2 زبادي يوناني )",
    ],
    details: {
      protein: <p>6</p>,
      carb: <p>42</p>,
      fats: <p>4</p>,
      calories: <p>227</p>,
    },
  },
  {
    ingred: ["2ثمرة فاكهة "],
    details: {
      protein: <p>0.8</p>,
      carb: <p>41</p>,
      fats: <p>0</p>,
      calories: <p>150</p>,
    },
  },
  {
    ingred: ["عصير الافوكادو"],
    details: {
      protein: <p>2</p>,
      carb: <p>8.5</p>,
      fats: <p>14.6</p>,
      calories: <p>160</p>,
    },
  },
  {
    ingred: ["سموزي فاكهة"],
    details: {
      protein: <p>3</p>,
      carb: <p>20</p>,
      fats: <p>0</p>,
      calories: <p>90</p>,
    },
  },
  {
    ingred: ["تمرات 3", "كوب لبن "],
    details: {
      protein: <p>2.6</p>,
      carb: <p>17</p>,
      fats: <p>2</p>,
      calories: <p>120</p>,
    },
  },
  {
    ingred: ["كوب لبن", "ملعقة كاكاو خام", "موزة"],
    details: {
      protein: <p>4</p>,
      carb: <p>31</p>,
      fats: <p>5.4</p>,
      calories: <p>272</p>,
    },
  },
  {
    ingred: ["2 Dark chocolate"],
    details: {
      protein: <p>3.1</p>,
      carb: <p>20.9</p>,
      fats: <p>19.5</p>,
      calories: <p>160</p>,
    },
  },
];
