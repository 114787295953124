import video0 from "./videos/video - Copy (88)/video.mp4";
import video1 from "./videos/video - Copy (89)/video.mp4";
import video2 from "./videos/video - Copy (90)/video.mp4";
import video3 from "./videos/video - Copy (91)/video.mp4";
import video4 from "./videos/video - Copy (92)/video.mp4";
import video5 from "./videos/video - Copy (93)/video.mp4";
import video6 from "./videos/video - Copy (94)/video.mp4";
import video7 from "./videos/video - Copy (95)/video.mp4";
import video8 from "./videos/video - Copy (96)/video.mp4";
import video9 from "./videos/video - Copy (97)/video.mp4";
import video10 from "./videos/video - Copy (98)/video.mp4";
import video11 from "./videos/video - Copy (99)/video.mp4";
import video12 from "./videos/video - Copy (100)/video.mp4";
import video13 from "./videos/video - Copy (101)/video.mp4";

export const tri = [
    {
        video: (
          <video
            className="min-h-[300px] max-h-[500px] max-w-[400px]"
            src={video0}
            muted
            loop
            autoPlay
          />
        ),
        Name: "Wide Grip Pull Up",
        Notes: (
          <ol>
           1- أمسك البار بقبضة علوية أوسع من عرض الكتفين، مع تمديد المرفقين بالكامل عند تعليق ميت.

            <br />
            <br />
           2- مع استقامة الساقين وركبتيك مقفلتين، اسحب نفسك للأعلى على الأقل حتى تصبح ذقنك فوق العارضة.

            <br />
            <br /> 3- النزول بطريقة مسيطر عليها.
          </ol>
        ),
      },{
        video: (
          <video
            className="min-h-[300px] max-h-[500px] max-w-[400px]"
            src={video1}
            muted
            loop
            autoPlay
          />
        ),
        Name: "Underhand Grip Pull Up",
        Notes: (
          <ol>
           1- أمسك البار بقبضة سفلية بعرض الكتفين، مع تمديد المرفقين بالكامل عند تعليق ميت.

            <br />
            <br />
           2- مع فرد الساقين وركبتيك مقفلتين، اسحب نفسك للأعلى على الأقل حتى تصبح ذقنك فوق العارضة.

            <br />
            <br /> 3- النزول بطريقة خاضعة للرقابة.
          </ol>
        ),
      },{
        video: (
          <video
            className="min-h-[300px] max-h-[500px] max-w-[400px]"
            src={video2}
            muted
            loop
            autoPlay
          />
        ),
        Name: "Wide Grip Lat Pulldown",
        Notes: (
          <ol>
           1- اجلس على مقعد الآلة مع وضع فخذيك تحت وسادات الدعم.

            <br />
            <br />
           2- أمسك المقبض على نطاق أوسع من عرض الكتفين بقبضة علوية.

            <br />
            <br /> 3- ابدأ كل تكرار بمرفقيك مستقيمين ومغلقين بالكامل.

            <br />
           4- اسحب المقبض لأسفل على الأقل حتى يصبح المقبض أسفل ذقنك.
           <br />
           5- احتفظ بالوضعية السفلية للحظة قبل العودة بطريقة مسيطر عليها.

          </ol>
        ),
      },{
        video: (
          <video
            className="min-h-[300px] max-h-[500px] max-w-[400px]"
            src={video3}
            muted
            loop
            autoPlay
          />
        ),
        Name: "Neutral Grip Lat Pulldown",
        Notes: (
          <ol>
           1- اجلس على مقعد الآلة مع وضع فخذيك تحت وسادات الدعم.

            <br />
            <br />
           2- أمسك المقبض بعرض الكتفين بقبضة محايدة.

            <br />
            <br /> 3- ابدأ كل تكرار بمرفقيك مستقيمين ومغلقين بالكامل.
            <br />
           4- اسحب المقبض لأسفل على الأقل حتى يصبح المقبض أسفل ذقنك.
           <br />
           5- احتفظ بالوضعية السفلية للحظة قبل العودة بطريقة مسيطر عليها.
          </ol>
        ),
      },{
        video: (
          <video
            className="min-h-[300px] max-h-[500px] max-w-[400px]"
            src={video4}
            muted
            loop
            autoPlay
          />
        ),
        Name: "Lat Pulldown, Single Arm",
        Notes: (
          <ol>
           1- اجلس على مقعد الآلة مع وضع فخذيك تحت وسادات الدعم.

            <br />
            <br />
           2- أمسك المقبض بيد واحدة بعرض الكتفين بقبضة علوية.

            <br />
            <br /> 3- ابدأ كل تكرار بمرفقيك مستقيمين ومغلقين بالكامل.
            <br />
           4- اسحب المقبض لأسفل على الأقل حتى يصبح المقبض أسفل ذقنك.
           <br />
           5- احتفظ بالوضعية السفلية للحظة قبل العودة بطريقة مسيطر عليها.
          </ol>
        ),
      },{
        video: (
          <video
            className="min-h-[300px] max-h-[500px] max-w-[400px]"
            src={video5}
            muted
            loop
            autoPlay
          />
        ),
        Name: "Overhand Grip Plate Loaded",
        Notes: (
          <ol>
           1- اجلس على مقعد الآلة مع وضع فخذيك تحت وسادات الدعم.

            <br />
            <br />
           2- أمسك المقبض بعرض الكتفين بقبضة علوية.

            <br />
            <br /> 3- ابدأ كل تكرار بمرفقيك مستقيمين ومغلقين بالكامل.
            <br />
           4- اسحب المقبض لأسفل على الأقل حتى يصبح المقبض أسفل ذقنك.
           <br />
           5- احتفظ بالوضعية السفلية للحظة قبل العودة بطريقة مسيطر عليها.
          </ol>
        ),
      },{
        video: (
          <video
            className="min-h-[300px] max-h-[500px] max-w-[400px]"
            src={video6}
            muted
            loop
            autoPlay
          />
        ),
        Name: "Seated Cable Row, Overhand Grip",
        Notes: (
          <ol>
           1- اجلس بشكل مستقيم مع تقوس ظهرك ورفع صدرك.

            <br />
            <br />
           2- أمسك المقابض بعرض الكتفين، بقبضة علوية.

            <br />
            <br /> 3- ابدأ التكرار بذراعيك مستقيمتين وممتدتين للأمام.
            <br />
           4- اسحب لوح كتفك واسحب مرفقك للخلف، مع توسيع مرفقيك قليلاً للخارج.
           <br />
           5- قم بإحضار مرفقك إلى الخلف قدر الإمكان، مع الاستمرار في وضع النهاية للحظة.
           <br />
           6- العودة مع السيطرة إلى وضع البداية.
          </ol>
        ),
      },{
        video: (
          <video
            className="min-h-[300px] max-h-[500px] max-w-[400px]"
            src={video7}
            muted
            loop
            autoPlay
          />
        ),
        Name: "Seated Cable Row, Wide Grip",
        Notes: (
          <ol>
           1- اجلس بشكل مستقيم مع تقوس ظهرك ورفع صدرك.

            <br />
            <br />
           2- أمسك المقابض على نطاق أوسع من عرض الكتفين، بقبضة علوية.

            <br />
            <br /> 3- ابدأ التكرار بذراعيك مستقيمتين وممتدتين للأمام.
            <br />
           4- اسحب لوحي كتفك واسحب مرفقيك للخلف، مع توسيع مرفقيك قليلاً للخارج.
           <br />
           5- قم بإحضار مرفقيك إلى الخلف قدر الإمكان، مع الاستمرار في وضع النهاية للحظة.
           <br />
           6- العودة مع السيطرة إلى وضع البداية.
          </ol>
        ),
      },{
        video: (
          <video
            className="min-h-[300px] max-h-[500px] max-w-[400px]"
            src={video8}
            muted
            loop
            autoPlay
          />
        ),
        Name: "Seated Cable Row, Neutral Grip",
        Notes: (
          <ol>
           1- اجلس بشكل مستقيم مع تقويس ظهرك ورفع صدرك.

            <br />
            <br />
           2- أمسك المقابض بعرض الكتفين، بقبضة محايدة.

            <br />
            <br /> 3- ابدأ التكرار بذراعيك مستقيمتين وممتدتين للأمام.
            <br />
           4- اسحب لوحي كتفك واسحب مرفقيك إلى الخلف، مع إبقاء مرفقيك قريبين من ضلوعك.
           <br />
           5- قم بإرجاع مرفقيك إلى الخلف قدر الإمكان، مع الحفاظ على وضع النهاية للحظة.
           <br />
           6- العودة مع السيطرة إلى وضع البداية.
          </ol>
        ),
      },{
        video: (
          <video
            className="min-h-[300px] max-h-[500px] max-w-[400px]"
            src={video9}
            muted
            loop
            autoPlay
          />
        ),
        Name: "Seated Cable Row, Single Arm",
        Notes: (
          <ol>
           1- اجلس بشكل مستقيم مع تقوس ظهرك ورفع صدرك.

            <br />
            <br />
           2- ابدأ التكرار بذراعك مستقيمة وممتدة للأمام.

            <br />
            <br /> 3-اسحب لوح كتفك واسحب مرفقك للخلف، مع إبقائه قريبًا من أضلاعك.
            <br />
           4- قم بإحضار مرفقك إلى الخلف قدر الإمكان، مع الاستمرار في وضع النهاية للحظة.
           <br />
           5- العودة مع السيطرة إلى وضع البداية.
          </ol>
        ),
      },{
        video: (
          <video
            className="min-h-[300px] max-h-[500px] max-w-[400px]"
            src={video10}
            muted
            loop
            autoPlay
          />
        ),
        Name: "Bent Over Barbell Row, Overhand Grip",
        Notes: (
          <ol>
           1- أمسك البار بعرض الكتفين بقبضة علوية.

            <br />
            <br />
           2-اتخذ وضعية الانحناء، مع تقويس ظهرك وضغط لوحي كتفك معًا.

            <br />
            <br /> 3-ابدأ بالشريط المعلق أسفل ركبتيك مباشرةً. ارفع الحديد عن طريق ثني مرفقيك ورفعهما إلى أعلى مستوى ممكن، حتى يلامس الحديد بطنك.
            <br />
           4- تجنب استخدام الزخم أو المساعدة في ساقيك. حاول إبقاء جذعك موازيًا للأرضية طوال المجموعة بأكملها.
          </ol>
        ),
      },{
        video: (
          <video
            className="min-h-[300px] max-h-[500px] max-w-[400px]"
            src={video11}
            muted
            loop
            autoPlay
          />
        ),
        Name: "Bent Over Barbell Row, Underhand Grip        ",
        Notes: (
          <ol>
           1- أمسك البار بعرض الكتفين بقبضة سفلية.

            <br />
            <br />
           2- اتخذ وضعية الانحناء، مع تقويس ظهرك وضغط لوحي كتفك معًا.

            <br />
            <br /> 3-ابدأ بالشريط المعلق أسفل ركبتيك مباشرةً. ارفع الحديد عن طريق ثني مرفقيك ورفعهما إلى أعلى مستوى ممكن، حتى يلامس الحديد بطنك.
            <br />
           4- تجنب استخدام الزخم أو المساعدة في ساقيك. حاول إبقاء جذعك موازيًا للأرضية طوال المجموعة بأكملها
          </ol>
        ),
      },{
        video: (
          <video
            className="min-h-[300px] max-h-[500px] max-w-[400px]"
            src={video12}
            muted
            loop
            autoPlay
          />
        ),
        Name: "Single Arm Dumbbell Row",
        Notes: (
          <ol>
           1- اتكئ على المقعد باستخدام ركبتك وذراعك، مع الحفاظ على جذعك موازيًا للأرض.

            <br />
            <br />
           2- احني ظهرك.

            <br />
            <br /> 3- اسحب الدمبل نحو وركك عن طريق رفع مرفقك إلى أعلى مستوى ممكن.
            <br />
           4- جدف مع الدمبل بالقرب من جانبك بهدف إيصاله إلى جيبك.
           <br />
           5- النزول مع السيطرة.
          </ol>
        ),
      },{
        video: (
          <video
            className="min-h-[300px] max-h-[500px] max-w-[400px]"
            src={video13}
            muted
            loop
            autoPlay
          />
        ),
        Name: "TBar Row",
        Notes: (
          <ol>
           1- أمسك الحديد باستخدام مقبض الكابل على شكل حرف V الموجود أسفل البار.

            <br />
            <br />
           2- اتخذ وضعية الانحناء، مع تقويس ظهرك وضغط لوحي كتفك معًا.

            <br />
            <br /> 3- ابدأ بمرفقيك بشكل مستقيم. ارفع الحديد عن طريق ثني مرفقيك ورفعهما إلى أعلى مستوى ممكن، حتى يلامس الحديد بطنك.
            <br />
           4- تجنب استخدام الزخم أو المساعدة في ساقيك. حاول إبقاء جذعك موازيًا للأرضية طوال المجموعة بأكملها.
          </ol>
        ),
      },
]

