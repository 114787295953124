import Video0 from "./videos/video/video.mp4";
import video1 from "./videos/video - Copy/video.mp4";
import video2 from "./videos/video - Copy (2)/video.mp4";
import video3 from "./videos/video - Copy (3)/video.mp4";
import video4 from "./videos/video - Copy (4)/video.mp4";
import video5 from "./videos/video - Copy (5)/video.mp4";
import video6 from "./videos/video - Copy (6)/video.mp4";
import video7 from "./videos/video - Copy (7)/video.mp4";
import video8 from "./videos/video - Copy (8)/video.mp4";
import video9 from "./videos/video - Copy (9)/video.mp4";
import video10 from "./videos/video - Copy (10)/video.mp4";
import video11 from "./videos/video - Copy (11)/video.mp4";
import video12 from "./videos/video - Copy (12)/video.mp4";
import video13 from "./videos/video - Copy (13)/video.mp4";
import video14 from "./videos/video - Copy (14)/video.mp4";
import video15 from "./videos/video - Copy (15)/video.mp4";
import video16 from "./videos/video - Copy (16)/video.mp4";
import video17 from "./videos/video - Copy (17)/video.mp4";

export const leg = [
  {
    video: (
      <video
        className="min-h-[300px] max-h-[500px] max-w-[400px]"
        src={Video0}
        muted
        loop
        autoPlay
      />
    ),
    Name: "Abduction machine",
    Notes: (
      <p>
        1- اجلس بظهرك وضغط على الزناد
        <br />
        <br />
        2- ضع وسادات الركبة في اقصى الفخذ، ويفضل ان تكون على ركبتك.
        <br />
        <br /> 3- اشبك فخذيك الداخليين بجمع ركبتيك معا، ثم امسك بموضع النهاية
        للحظة وعد بالتحكم.
      </p>
    ),
  },
  {
    video: (
      <video
        className="min-h-[300px] max-h-[500px] max-w-[400px]"
        src={video1}
        muted
        loop
        autoPlay
      />
    ),
    Name: "Calf raises machine",
    Notes: (
      <p>
        1- ضع قدميك على مسند القدم، مع رفع الكعب عن مسند القدم وتمدد عضلات
        الساقين وتمديد الكاحلين. <br />
        2- احتفظ بالوضع النهائي للحظة قبل العودة إلى الوضع الأولي.
        <br /> 3- قم بالحركة بحركة مسيطرة وتجنب ارتداد الوزن.
      </p>
    ),
  },
  {
    video: (
      <video
        className="min-h-[300px] max-h-[500px] max-w-[400px]"
        src={video2}
        muted
        loop
        autoPlay
      />
    ),
    Name: "calf raise",
    Notes: (
      <p>
        1- وضع البداية، قف مع وضع مقدمة قدميك على سطح مرتفع.
        <br />
        2- كعبيك معلقين عن السطح، مع تمديد ساقيك.
        <br /> 3- ضع البار علي كتفك .
        <br />
        4- مد كاحليك وارفع كعبك إلى أعلى مستوى ممكن.
        <br />
        5- والثبات ثم النزول لاسفل السطح المرتفع والتحكم في الوزن.
      </p>
    ),
  },
  {
    video: (
      <video
        className="min-h-[300px] max-h-[500px] max-w-[400px]"
        src={video3}
        muted
        loop
        autoPlay
      />
    ),
    Name: "Barbell deadlift",
    Notes: (
      <p>
        1- قف مع ساقيك بعرض مفصل الورك مع ثني الركبتين قليلاً
        <br />
        2- أمسك البار بعرض الكتف ومد عمودك الفقري بتقويس ظهرك
        <br />
        فعندما يلمس البار ساقيكم ويزن على كعبيكم، ارفعوا البار الى ان تُقفل
        ركبتيكم وورككم تمام -3
        <br />
        4- لا تثني مرفقيك في أي وقت أثناء الرفع.{" "}
      </p>
    ),
  },
  {
    video: (
      <video
        className="min-h-[300px] max-h-[500px] max-w-[400px]"
        src={video4}
        muted
        loop
        autoPlay
      />
    ),
    Name: "Lying Leg Curl Machine",
    Notes: (
      <p>
        ضع الوسادة فوق كاحليك -1 اثني ركبتيك حتى تصل إلى الأرداف/الفخذين بوسادة
        الكاحل.
        <br />
        مد ركبتك ببطء مع الحفاظ على السيطرة الكاملة -2{" "}
      </p>
    ),
  },
  {
    video: (
      <video
        className="min-h-[300px] max-h-[500px] max-w-[400px]"
        src={video5}
        muted
        loop
        autoPlay
      />
    ),
    Name: "Single Leg Lying Leg Curl Machine",
    Notes: (
      <p>
        1- ضعي الوسادة فوق كاحلك مباشرة.
        <br />
        2- اثني ركبتك حتى تصل إلى الأرداف/الفخذين بوسادة الكاحل.
        <br /> 3- مد ركبتك ببطء مع الحفاظ على السيطرة الكاملة.
      </p>
    ),
  },
  {
    video: (
      <video
        className="min-h-[300px] max-h-[500px] max-w-[400px]"
        src={video6}
        muted
        loop
        autoPlay
      />
    ),
    Name: "Roman Chair Back Extension",
    Notes: (
      <p>
        1- ضع نفسك بحيث تكون الوسادة ضد فخذيك، أسفل فخذيك مباشرة.
        <br />
        2- اذا كان ظهرك مقوَّسا، اثنوا وركَيكم واخفضوا جذعكم الى ان تشعروا
        بامتداد في اوتار الركبة.
        <br /> 3- ابق في الأعلى للحظة قبل أن تهبط مرة أخرى
      </p>
    ),
  },
  {
    video: (
      <video
        className="min-h-[300px] max-h-[500px] max-w-[400px]"
        src={video7}
        muted
        loop
        autoPlay
      />
    ),
    Name: "Barbell Hip Thrust",
    Notes: (
      <p>
        1- اتكئي على مقعد مع عظام ذراعيك
        <br />
        2- ضع قدميك على مسافة من الكتفين، وإتجاه أصابع قدميك إلى الخارج قليلا.
        <br />
        <br />
        3- ضع الأثقال على فخذيك
        {
          /////////
        }
        <br />
        Squeeze your glutes at the top and hold for a moment before descending
      </p>
    ),
  },
  {
    video: (
      <video
        className="min-h-[300px] max-h-[500px] max-w-[400px]"
        src={video8}
        muted
        loop
        autoPlay
      />
    ),
    Name: "Leg Extension",
    Notes: (
      <p>
        1- وسادة القدم على الجزء العلوي من قدميك عند مفصل الكاحل. ضعي وسادة
        الركبة على فخذك، أعلى قليلاً من ركبتك.
        <br />
        2- استخدم نطاق كامل من الحركة
        <br /> 3- أمسك الموضع العلوي للحظة قبل خفض الحمل.
      </p>
    ),
  },
  {
    video: (
      <video
        className="min-h-[300px] max-h-[500px] max-w-[400px]"
        src={video9}
        muted
        loop
        autoPlay
      />
    ),
    Name: "Leg Press Selector Machine",
    Notes: (
      <p>
        1- ابحث عن وضع مريح مع عرض القدمين تقريباً الكتفين منفصلين ومسطحين على
        لوحة القدم. اثنوا ركبتيكم قدر الامكان فيما حافظوا على حياد العمود
        الفقري، ثم امددوا ركبتيكم وركيكم بالكامل
        <br />
        2- أبقي ظهرك مستويًا على الوسادة أثناء الحركة بأكملها
      </p>
    ),
  },
  {
    video: (
      <video
        className="min-h-[300px] max-h-[500px] max-w-[400px]"
        src={video10}
        muted
        loop
        autoPlay
      />
    ),
    Name: "Hack Squat",
    Notes: (
      <p>
        1- خذ موقفاً مرتاحاً بحيث يكون عرض قدميك متباعدين عن بعضهما مع الإشارة
        إلى أصابع قدميك.
        <br />
        2- ارفعي صدرك الى الاعلى عن طريق ضغط كتفيك معا وحني اسفل ظهرك.
        <br /> 3- ثني ركبتيك ببطء قدر الإمكان مع الحفاظ على الجذع المستقيم، ومد
        الظهر، والحفاظ على قدميك مستوية على لوحة القدم.
        <br />
        4- (تيكيل) إذهب إلى أسفل قدر ما تستطيع، ثم تراجع حتى يتم تمديد ركبتيك
        بالكامل.
        <br />
        5- أبق شفرات كتفك وضغطها على الوسادة
      </p>
    ),
  },
  {
    video: (
      <video
        className="min-h-[300px] max-h-[500px] max-w-[400px]"
        src={video11}
        muted
        loop
        autoPlay
      />
    ),
    Name: "Leg Press",
    Notes: (
      <p>
        1- يجب أن يكون وضعك مريح بحيث تكون القدمين بعرض الكتفين متباعدين ومسطحين
        على لوحة القدم. اثنوا ركبتيكم قدر الامكان فيما حافظوا على حياد العمود
        الفقري، ثم امددوا ركبتيكم وركيكم بالكامل.
        <br />
        2- أبقي ظهرك مستويًا على الوسادة أثناء التصوير بأكمله
      </p>
    ),
  },
  {
    video: (
      <video
        className="min-h-[300px] max-h-[500px] max-w-[400px]"
        src={video12}
        muted
        loop
        autoPlay
      />
    ),
    Name: "Smith Machine Squat",
    Notes: (
      <p>
        1- ضعوا الأثقال على كتفيك، أمسكوا البار بقبضة ضيقة ولكنها مريحة.
        <br />
        2- اتخذوا وقفة مريحة بحيث يكون عرض قدميكم متباعدين عن بعضهما مع الإشارة
        إلى أصابع قدميكم.
        <br /> 3- ادفع صدرك الى الاعلى بضغط كتفيك معا وحني اسفل ظهرك.
        <br />
        4- اثنِ ركبتيك ببطء قدر المستطاع فيما تبقي جذعك مستقيما، ممدا الى الخلف،
        ومسطّحا على الارض.
        <br />
        5- انخفضوا قدر استطاعتكم، ثم تراجعوا حتى تمددوا ركبتيكم بالكامل.
      </p>
    ),
  },
  {
    video: (
      <video
        className="min-h-[300px] max-h-[500px] max-w-[400px]"
        src={video13}
        muted
        loop
        autoPlay
      />
    ),
    Name: "Dumbbell Bulgarian Lunge  ",
    Notes: (
      <p>
        1- امسك الدمبل من جانبك
        <br />
        2- اعثر على عرض مريح
        <br /> 3- ضع الساق الخلفية على مقعد \ ركبة سطح مريح للغاية
        <br /> 4- اخفضوا وركَيكم حتى تصلوا الى الارض بركبتكم الخلفية مع إبقاء
        الركبة الامامية بزاوية ٩٠ درجة.
        <br />
        5- حافظ على جذعك المستقيم وأبقِ قدمك الأمامية مستوية على الأرض طوال
        التمرين
      </p>
    ),
  },
  {
    video: (
      <video
        className="min-h-[300px] max-h-[500px] max-w-[400px]"
        src={video14}
        muted
        loop
        autoPlay
      />
    ),
    Name: "Dumbbell Lunge",
    Notes: (
      <p>
        1- امسك الدمبل من جانبك
        <br />
        2- ابحث عن عرض وضع مريح، أخفض وركيك حتى تلمس ركبتك الخلفية الأرض
        <br /> 3- في الموضع السفلي يجب أن تشكل ركبتك الأمامية زاوية 90 درجة
        <br />
        4- ارجع الى وضع الوقوف بمد ركبتك الى ان يتم تثبيتها تماما.
        <br />
        5- حافظ على جذعك المستقيم وأبقِ قدمك الأمامية مستوية على الأرض طوال
        التمرين.
      </p>
    ),
  },
  {
    video: (
      <video
        className="min-h-[300px] max-h-[500px] max-w-[400px]"
        src={video15}
        muted
        loop
        autoPlay
      />
    ),
    Name: "Goblet Squat",
    Notes: (
      <p>
        1- ضع الدمبل بالقرب من صدرك في وضع الكأس.
        <br />
        2- اتخذوا وقفة مريحة بحيث يكون عرض قدميكم متباعدين عن بعضهما مع الإشارة
        إلى أصابع قدميكم.
        <br /> ادفع صدرك للأعلى بتقويس أسفل ظهرك 3- ادفع صدرك للأعلى بتقويس أسفل
        ظهرك
        <br />
        4- اثنِ ركبتيك ببطء قدر المستطاع فيما تبقي جذعك مستقيما، ممدا الى الخلف،
        ومسطّحا على الارض.
        <br />
        5- انخفضوا قدر استطاعتكم، ثم تراجعوا حتى تمددوا ركبتيكم بالكامل .
      </p>
    ),
  },
  {
    video: (
      <video
        className="min-h-[300px] max-h-[500px] max-w-[400px]"
        src={video16}
        muted
        loop
        autoPlay
      />
    ),
    Name: "Barbell Back Squat",
    Notes: (
      <p>
        1- ضعوا الأثقال على أكتافكم، وأمسكوا البار بعرض قبضة ضيق ومريح.
        <br />
        2- اتخذوا وقفة مريحة بحيث يكون عرض قدميكم متباعدين عن بعضهما مع الإشارة
        إلى أصابع قدميكم.
        <br /> 3- ادفع صدرك الى الاعلى بضغط كتفيك معا وحني اسفل ظهرك.
        <br />
        4- اثنِ ركبتيك ببطء قدر المستطاع فيما تبقي جذعك مستقيما، ممدا الى الخلف،
        ومسطّحا على الارض.
        <br />
        5- اثبتوا في مكانكم العلوي للحظة، ثم تراجعوا الى ان تُمدَّد ركبتيكم
        كاملا.
      </p>
    ),
  },
  {
    video: (
      <video
        className="min-h-[300px] max-h-[500px] max-w-[400px]"
        src={video17}
        muted
        loop
        autoPlay
      />
    ),
    Name: "single Leg Extension",
    Notes: (
      <p>
        1- ضع وسادة القدم على الجزء العلوي من قدميك عند مفصل الكاحل. ضعي وسادة
        الركبة على فخذك، أعلى قليلاً من ركبتك. 2- استخدم نطاق كامل من الحركة
        <br />
        <br />
        4- أمسك الموضع العلوي للحظة قبل خفض الحمل.
      </p>
    ),
  },
];
