import { useState, useEffect } from "react";
import axios from "axios";
import { Link, redirect } from "react-router-dom";

const ProfilePage = () => {
  const [update, setUpdate] = useState(false);
  var serverUrl = "http://localhost:3006";
  // https://api.coachmazen.com"

  const handleLogout = async () => {
    localStorage.removeItem('user');
    window.location.href = '/';

    // axios
    //   .get(`${serverUrl}/api/logout`, { withCredentials: true })
    //   .then((res) => {
    //     // window.location.reload(true);
    //     setTimeout(() => {
    //       navigate("/");
    //   }, 200);
    //   })
    //   .catch((error) => {
    //     console.log(error.response.data.message);
    //   });
  };

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const user = JSON.parse(localStorage.getItem('user'));
  console.log(user)

  const [testData, setTestData] = useState({
    age: null,
    weight: null,
    height: null,
    whatsapp: null,
    disease: null,
    allergy: null,
    dislikedFood: null,
    goal: null,
    levelOfTraining: null,
    trainingDays: null,
    serviceNeeded: null,
    injuries: null,
    bmr: null,
  });

  const [gender, setGender] = useState("male");

  const handleGenderChange = (e) => {
    setGender(e.target.value);
    console.log(gender);
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setTestData((prevTestData) => {
      const updatedTestData = {
        ...prevTestData,
        [name]: value,
      };

      // Recalculate BMR based on weight, height, age, and gender
      if (["weight", "height", "age", "gender"].some((key) => key === name)) {
        const { weight, height, age, gender } = updatedTestData;
        if (weight && height && age) {
          updatedTestData.bmr =
            gender === "male"
              ? weight * 10 + height * 6.25 - age * 5 + 5
              : weight * 10 + height * 6.25 - age * 5 - 161;
        }
      }

      // Adjust BMR based on training days
      if (["trainingDays", "bmr"].some((key) => key === name)) {
        const { trainingDays } = updatedTestData;
        if (trainingDays && updatedTestData.bmr) {
          if (trainingDays >= 3) {
            updatedTestData.bmr *= 1.3;
          } else if (trainingDays > 3 && trainingDays <= 5) {
            updatedTestData.bmr *= 1.4;
          } else if (trainingDays === 6) {
            updatedTestData.bmr *= 1.5;
          } else if (trainingDays === 7) {
            updatedTestData.bmr *= 1.7;
          }
        }
      }

      return updatedTestData;
    });
  };

  const handleSubmit = async (userId) => {
    try {
      const response = await axios.put(
        `${serverUrl}/auth/test/${userId}`, // Update with your server's endpoint
        testData,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      console.log("Server Response:", response.data);
      // Optionally, you can update the user state or perform any other actions after a successful update
    } catch (error) {
      console.error("Error submitting test data:", error);
      // Handle errors appropriately (e.g., show an error message to the user)
    }
  };

  return (
    <div dir="rtl">
      {user ? (
        <div className="flex flex-col items-center">
          <div className="flex justify-between p-4 w-full border-b shadow-md  bg-white fixed z-30">
            <Link to="/">
              <button className="text-xl">رجوع</button>
            </Link>
            <button
              onClick={handleLogout}
              className="text-xl border rounded-full px-4 py-2 bg-stone-600 text-white"
            >
              تسجيل الخروج
            </button>
          </div>
          <center className="mt-[100px]">
            <img className="w-[150px] rounded-full" src={user.img} alt="" />
            <h1 className="text-2xl">{user.name}</h1>
          </center>
          <center>
            {/*{user.test.age && user.test.age !== 0 ? (
              <div>
                <ul className=" p-4 w-[300px] flex flex-col items-start mt-4 rounded-lg shadow-xl border-b-2 border-blue-400 m-4">
                  <li className={`text-stone-700 ${user.paid === "no" ? "" : "text-blue-400"} text-2xl p-2 border mt-2`}>
                    {user.paid === "no" ? "Free" : user.paid}
                  </li>
                  <li className="text-xl mt-1">العمر: {user.test.age && user.test.age}</li>
                  <li className="text-xl mt-1">
                    الوزن بالكيلوجرام: {user.test.weight}
                  </li>
                  <li className="text-xl mt-1">الطول: {user.test.height && user.test.height}</li>
                  <li className="text-xl mt-1">
                    رقم الواتساب: {user.test.whatsapp}
                  </li>
                  <li className="text-xl mt-1">
                    أمراض مزمنة: {user.test.disease}
                  </li>
                  <li className="text-xl mt-1">
                    حساسية من طعام: {user.test.allergy}
                  </li>
                  <li className="text-xl mt-1">
                    المراد تحقيقه: {user.test.goal}
                  </li>
                  <li className="text-xl mt-1">
                    مستوي التدريب: {user.test.levelOfTraining}
                  </li>
                  <li className="text-xl mt-1">
                    عدد أيام التدريب: {user.test.trainingDays}
                  </li>
                  <li className="text-xl mt-1">
                    النظام: {user.test.serviceNeeded}
                  </li>
                  <li className="text-xl mt-1">اصابات: {user.test.injuries}</li>
                </ul>
                <button
                  onClick={() => {
                    setUpdate(true);
                    setTimeout(() => {
                      window.scrollTo({
                        top: window.scrollY + 400,
                        behavior: "smooth",
                      });
                    }, 300);
                  }}
                  className="m-4 bg-blue-400 text-white text-lg rounded-lg p-4"
                >
                  تعديل
                </button>
                <form
                  className={`${
                    !update && "hidden"
                  } flex flex-wrap flex-col justify-evenly rounded-lg w-[300px] md:w-[700px] p-8 mt-10 border-y-2 shadow-xl text-lg m-4 border-t-blue-400 border-b-blue-400`}
                >
                  <div className="flex flex-wrap w-full">
                    <div className="p-2 flex flex-col items-start w-full md:w-1/2">
                      <label htmlFor="age"> العمر: </label>
                      <input
                        className="border outline-none p-2 w-full bg-gray-50 mt-1"
                        id="age"
                        name="age"
                        value={testData.age}
                        onChange={handleInputChange}
                        type="text"
                        required
                      />
                    </div>
                    <div className="p-2 flex flex-col items-start w-full md:w-1/2">
                      <label htmlFor="whatsapp">رقم الواتس اب: </label>
                      <input
                        className="border outline-none p-2 w-full bg-gray-50 mt-1"
                        id="whatsapp"
                        name="whatsapp"
                        value={testData.whatsapp}
                        onChange={handleInputChange}
                        type="text"
                        required
                      />
                    </div>
                  </div>
                  <div className="flex flex-wrap w-full">
                    <div className="p-2 flex flex-col items-start w-full md:w-1/2">
                      <label htmlFor="weight">الوزن بالكيلو جرام: </label>
                      <input
                        className="border outline-none p-2 w-full bg-gray-50 mt-1"
                        id="weight"
                        name="weight"
                        value={testData.weight}
                        onChange={handleInputChange}
                        type="text"
                        required
                      />
                    </div>
                    <div className="p-2 flex flex-col items-start w-full md:w-1/2">
                      <label htmlFor="height">الطول بالسنتي متر: </label>
                      <input
                        className="border outline-none p-2 w-full bg-gray-50 mt-1"
                        id="height"
                        name="height"
                        value={testData.height}
                        onChange={handleInputChange}
                        type="text"
                        required
                      />
                    </div>
                  </div>
                  <div className="p-2 flex flex-col items-start w-full">
                    <label htmlFor="disease">أمراض مزمنة (اختياري): </label>
                    <input
                      className="border outline-none w-full bg-gray-50 p-2 mt-1"
                      id="disease"
                      name="disease"
                      value={testData.disease}
                      onChange={handleInputChange}
                      type="text"
                    />
                  </div>
                  <div className="p-2 flex flex-col items-start w-full">
                    <label htmlFor="allergy">
                      طعام لديك حساسية ضده (اختياري):{" "}
                    </label>
                    <input
                      className="border outline-none w-full bg-gray-50 p-2 mt-1"
                      id="allergy"
                      name="allergy"
                      value={testData.allergy}
                      onChange={handleInputChange}
                      type="text"
                    />
                  </div>
                  <div className="p-2 flex flex-col items-start w-full">
                    <label htmlFor="injuries"> اصابات (اختياري): </label>
                    <input
                      className="border outline-none w-full bg-gray-50 p-2 mt-1"
                      id="injuries"
                      name="injuries"
                      value={testData.injuries}
                      onChange={handleInputChange}
                      type="text"
                    />
                  </div>
                  <div className="block md:flex justify-between">
                    <div className="p-2 flex flex-col items-start">
                      <label for="goal">المراد تحقيقه:</label>
                      <select
                        name="goal"
                        id="goal"
                        value={testData.goal}
                        onChange={handleInputChange}
                        className="border p-2 rounded-full mt-1"
                      >
                        <option value="أخري">أخري</option>
                        <option value="خسارة الوزن">خسارة الوزن</option>
                        <option value="اكتساب الوزن">اكتساب الوزن</option>
                        <option value="اكتساب لياقة">اكتساب لياقة</option>
                      </select>
                    </div>
                    <div className="p-2 flex flex-col items-start">
                      <label for="levelOfTraining">مستواك التدريبي:</label>
                      <select
                        className="border p-2 rounded-full mt-1"
                        name="levelOfTraining"
                        id="levelOfTraining"
                        value={testData.levelOfTraining}
                        onChange={handleInputChange}
                      >
                        <option value="أخري">أخري</option>
                        <option value=" مبتدئ">مبتدئ</option>
                        <option value="متوسط">متوسط</option>
                        <option value=" محترف">محترف</option>
                      </select>
                    </div>
                  </div>
                  <div className="block md:flex justify-between">
                    <div className="p-2 flex flex-col items-start">
                      <label htmlFor="trainingDays">عدد أيام التدريب: </label>
                      <select
                        value={testData.trainingDays}
                        onChange={handleInputChange}
                        className="border p-2 rounded-full mt-1"
                        name="trainingDays"
                        id="trainingDays"
                      >
                        <option value="1">1</option>
                        <option value="2">2</option>
                        <option value="3">3</option>
                        <option value="4">4</option>
                        <option value="5">5</option>
                        <option value="6">6</option>
                      </select>
                    </div>
                    <div className="p-2 flex flex-col items-start">
                      <label htmlFor="serviceNeeded">كيف تريد الخدمة: </label>
                      <select
                        value={testData.serviceNeeded}
                        onChange={handleInputChange}
                        className="border p-2 rounded-full mt-1"
                        name="serviceNeeded"
                        id="serviceNeeded"
                      >
                        <option value="تدريب">تدريب</option>
                        <option value="تغذية">تغذية</option>
                        <option value="تدريب و تغذية">تدريب و تغذية</option>
                      </select>{" "}
                      <label htmlFor="gender">الجنس: </label>
                      <select
                        value={gender}
                        onChange={handleGenderChange}
                        className="border p-2 rounded-md mt-1"
                        name="gender"
                        id="gender"
                      >
                        <option id="male" value="female">
                          ذكر
                        </option>
                        <option id="female" value="male">
                          أنثي
                        </option>
                      </select>
                    </div>
                  </div>
                  <div>
                    <button
                      onClick={() => handleSubmit(user._id)}
                      className="bg-blue-400 text-white p-2 px-4 text-lg"
                    >
                      ارسال
                    </button>
                  </div>
                </form>
              </div>
            ) : (
              <form className="flex flex-wrap flex-col justify-evenly rounded-lg w-[300px] md:w-[700px] p-8 mt-10 border-y-2 shadow-xl text-lg m-4 border-t-blue-400 border-b-blue-400">
                <div className="flex flex-wrap w-full">
                  <div className="p-2 flex flex-col items-start w-full md:w-1/2">
                    <label htmlFor="age"> العمر: </label>
                    <input
                      className="border outline-none p-2 w-full bg-gray-50 mt-1"
                      id="age"
                      name="age"
                      value={testData.age}
                      onChange={handleInputChange}
                      required
                      type="text"
                    />
                  </div>
                  <div className="p-2 flex flex-col items-start w-full md:w-1/2">
                    <label htmlFor="whatsapp">رقم الواتس اب: </label>
                    <input
                      className="border outline-none p-2 w-full bg-gray-50 mt-1"
                      id="whatsapp"
                      name="whatsapp"
                      value={testData.whatsapp}
                      onChange={handleInputChange}
                      type="text"
                    />
                  </div>
                </div>
                <div className="flex flex-wrap w-full">
                  <div className="p-2 flex flex-col items-start w-full md:w-1/2">
                    <label htmlFor="weight">الوزن بالكيلو جرام: </label>
                    <input
                      className="border outline-none p-2 w-full bg-gray-50 mt-1"
                      id="weight"
                      name="weight"
                      value={testData.weight}
                      onChange={handleInputChange}
                      type="text"
                      required
                    />
                  </div>
                  <div className="p-2 flex flex-col items-start w-full md:w-1/2">
                    <label htmlFor="height">الطول بالسنتي متر: </label>
                    <input
                      className="border outline-none p-2 w-full bg-gray-50 mt-1"
                      id="height"
                      name="height"
                      value={testData.height}
                      onChange={handleInputChange}
                      type="text"
                      required
                    />
                  </div>
                </div>
                <div className="p-2 flex flex-col items-start w-full">
                  <label htmlFor="disease">أمراض مزمنة (اختياري): </label>
                  <input
                    className="border outline-none w-full bg-gray-50 p-2 mt-1"
                    id="disease"
                    name="disease"
                    value={testData.disease}
                    onChange={handleInputChange}
                    type="text"
                    required
                  />
                </div>
                <div className="p-2 flex flex-col items-start w-full">
                  <label htmlFor="allergy">
                    طعام لديك حساسية ضده (اختياري):{" "}
                  </label>
                  <input
                    className="border outline-none w-full bg-gray-50 p-2 mt-1"
                    id="allergy"
                    name="allergy"
                    value={testData.allergy}
                    onChange={handleInputChange}
                    type="text"
                    required
                  />
                </div>
                <div className="p-2 flex flex-col items-start w-full">
                  <label htmlFor="injuries"> اصابات (اختياري): </label>
                  <input
                    className="border outline-none w-full bg-gray-50 p-2 mt-1"
                    id="injuries"
                    name="injuries"
                    value={testData.injuries}
                    onChange={handleInputChange}
                    type="text"
                    required
                  />
                </div>
                <div className="block md:flex justify-between">
                  <div className="p-2 flex flex-col items-start">
                    <label for="goal">المراد تحقيقه:</label>
                    <select
                      name="goal"
                      id="goal"
                      value={testData.goal}
                      onChange={handleInputChange}
                      className="border p-2 rounded-full mt-1"
                    >
                      <option value="أخري">أخري</option>
                      <option value="خسارة الوزن">خسارة الوزن</option>
                      <option value="اكتساب الوزن">اكتساب الوزن</option>
                      <option value="اكتساب لياقة">اكتساب لياقة</option>
                    </select>
                  </div>
                  <div className="p-2 flex flex-col items-start">
                    <label for="levelOfTraining">مستواك التدريبي:</label>
                    <select
                      className="border p-2 rounded-full mt-1"
                      name="levelOfTraining"
                      id="levelOfTraining"
                      value={testData.levelOfTraining}
                      onChange={handleInputChange}
                    >
                      <option value="أخري">أخري</option>
                      <option value=" مبتدئ">مبتدئ</option>
                      <option value="متوسط">متوسط</option>
                      <option value=" محترف">محترف</option>
                    </select>
                  </div>
                </div>
                <div className="block md:flex justify-between">
                  <div className="p-2 flex flex-col items-start">
                    <label htmlFor="trainingDays">عدد أيام التدريب: </label>
                    <select
                      value={testData.trainingDays}
                      onChange={handleInputChange}
                      className="border p-2 rounded-full mt-1"
                      name="trainingDays"
                      id="trainingDays"
                    >
                      <option value="1">1</option>
                      <option value="2">2</option>
                      <option value="3">3</option>
                      <option value="4">4</option>
                      <option value="5">5</option>
                      <option value="6">6</option>
                    </select>
                  </div>
                  <div className="p-2 flex flex-col items-start">
                    <label htmlFor="serviceNeeded">كيف تريد الخدمة: </label>
                    <select
                      value={testData.serviceNeeded}
                      onChange={handleInputChange}
                      className="border p-2 rounded-full mt-1"
                      name="serviceNeeded"
                      id="serviceNeeded"
                    >
                      <option value="تدريب">تدريب</option>
                      <option value="تغذية">تغذية</option>
                      <option value="تدريب و تغذية">تدريب و تغذية</option>
                    </select>{" "}
                    <label htmlFor="gender">الجنس: </label>
                    <select
                      value={gender}
                      onChange={handleGenderChange}
                      className="border p-2 rounded-md mt-1"
                      name="gender"
                      id="gender"
                    >
                      <option id="male" value="female">
                        ذكر
                      </option>
                      <option id="female" value="male">
                        أنثي
                      </option>
                    </select>
                  </div>
                </div>
                <div>
                  <button
                    onClick={() => handleSubmit(user.id)}
                    className="bg-blue-400 text-white p-2 px-4 text-lg"
                  >
                    ارسال
                  </button>
                </div>
              </form>
            )}*/}
                  <p className="p-8 m-8 text-4xl bg-gray-200 text-gray-400">working on it..........</p>

          </center>
        </div>
      ) : null}
    </div>
  );
};

export default ProfilePage;
