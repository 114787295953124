import tickSign from "./images/tick-sign.png";
import { useState, useEffect } from "react";
import Signup from "./Signup";
import axios from "axios";

const PackageCard = ({
  type,
  price,
  prem,
  signOne,
  signTwo,
  signThree,
  bg,
  shine,
}) => {
  // const [user, setUser] = useState(null);
  const [active, setActive] = useState(false);
  // const [loading, setLoading] = useState(false);
  // const [error, setError] = useState(null);

  // useEffect(() => {
  //   const fetchUser = async () => {
  //     setLoading(true);
  //     try {
  //       const response = await axios.get(`https://coach-mazen-server.vercel.app/api/getuser`, {
  //         withCredentials: true,
  //       });
  //       const userData = response.data.user;
  //       setUser(userData);
  //     } catch (error) {
  //       setError(error.response.data.message);
  //     } finally {
  //       setLoading(false);
  //     }
  //   };

  //   fetchUser();

  //   console.log(user)
  // }, []);

  const user = JSON.parse(localStorage.getItem('user'));


  const handleClick = () => {
    if (user) {
      window.location.href = "https://wa.me/201010686194?text=details";
    } else {
      window.location.href = "/register";
    }
  };

  return (
    <>
      <div
        className={`${bg} relative my-8 h-[720px] w-[300px] shadow-2xl shadow-black flex flex-col items-center`}
      >
        <img
          className="z-20 absolute left-[250px] top-[10px] w-[35px] diamond"
          src={prem}
          alt=""
        />
        <div className="flex rotate-[-45deg] absolute left-[-91px] top-[43px]">
          <div className=" relative left-[40px] w-[0px] h-[0px] border-l-[40px] border-r-[40px] border-b-[40px] border-transparent border-b-white"></div>
          <div className="z-30 text-center text-xl font-semibold text-[#660708] w-[150px] h-[40px] bg-white">
            {price}
          </div>
          <div className=" relative right-[40px] w-[0px] h-[0px] border-l-[40px] border-r-[40px] border-b-[40px] border-transparent border-b-white"></div>
        </div>
        <div className="w-full h-full absolute overflow-hidden">
          <div className="relative w-full h-full">
            <div
              className={`z-10 absolute top-[210px] right-[-130px] w-[750px] h-[150px] rotate-[-45deg] ${shine}`}
            ></div>{" "}
          </div>
        </div>
        <h1 className="z-20 text-4xl p-4 text-white w-full text-center mt-16">
          {type}
        </h1>
        <div className="flex flex-col p-4">
          <div className="z-20 flex items-center">
            <img
              className="w-[25px] relative top-3 mr-2"
              src={tickSign}
              alt="tick"
            />
            <p className="text-white mt-5">تحديث النظام الغذائي كل شهر</p>
          </div>
          <div className="z-20 flex items-center">
            <img
              className="w-[25px] relative top-3 mr-2"
              src={tickSign}
              alt="tick"
            />
            <p className="text-white mt-5">تحديد المكملات المناسبة</p>
          </div>{" "}
          <div className="z-20 flex items-center">
            <img
              className="w-[25px] relative top-3 mr-2"
              src={tickSign}
              alt="tick"
            />
            <p className="text-white mt-5">تمارين هوائية</p>
          </div>
          <div className="z-20 flex items-center">
            <img
              className="w-[25px] relative top-3 mr-2"
              src={tickSign}
              alt="tick"
            />
            <p className="text-white mt-5">نظام غذائي مخصوص ليك</p>
          </div>
          <div className="z-20 flex items-center">
            <img
              className="w-[25px] relative top-3 mr-2"
              src={tickSign}
              alt="tick"
            />
            <p className="text-white mt-5">نظام تمرين مناسب لمستواك</p>
          </div>
          <div className="z-20 flex items-center">
            <img
              className="w-[25px] relative top-3 mr-2"
              src={signOne}
              alt="tick"
            />
            <p className="text-white mt-5">التواصل كل أسبوع للتاكد من التقدم</p>
          </div>{" "}
          <div className="z-20 flex items-center">
            <img
              className="w-[25px] relative top-3 mr-2"
              src={signTwo}
              alt="tick"
            />
            <p className="text-white mt-5">
              التواصل يوميا واتس اب لاي اسئلة او تغير في التمرين او الاكل
            </p>
          </div>{" "}
          <div className="z-20 flex items-center">
            <img
              className="w-[25px] relative top-3 mr-2"
              src={signThree}
              alt="tick"
            />
            <p className="text-white mt-5">
              اتصال عبر الفيديو او مكالمة كل أسبوعين للحصول علي تقرير كامل
              ولاستفسار علي اي شئ
            </p>
          </div>
        </div>
        <button
          onClick={handleClick}
          className="z-20 cursor-pointer p-2 px-12 text-xl rounded-md bg-white mt-16 text-[#000000]"
        >
          اختيار
        </button>
      </div>
      <Signup isVisible={active} close={() => setActive(false)} />
    </>
  );
};

export default PackageCard;
