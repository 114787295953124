import React, { useState } from "react";
import { BreakFastMeals, LunchMeals, Snacks, DinnerMeals } from "../Meals";
import MealCard from "../components/MealCard";
import Header from "../Landing/Header";

const FullMealsPage = () => {
  const [fixed, setFixed] = useState(false);
  const [calorieFilter, setCalorieFilter] = useState({ from: 0, to: 1000 });
  const [filteredBreakfastMeals, setFilteredBreakfastMeals] =
    useState(BreakFastMeals);
  const [filteredLunchMeals, setFilteredLunchMeals] = useState(LunchMeals);
  const [filteredSnacks, setFilteredSnacks] = useState(Snacks);
  const [filteredDinnerMeals, setFilteredDinnerMeals] = useState(DinnerMeals);

  window.addEventListener("scroll", () => {
    const scrollY = window.scrollY;
  
    if (scrollY >= 200) {
      setFixed(true);
    } else {
      setFixed(false);
    }
  });
  
  const handleFilterChange = (e) => {
    const inputId = e.target.id;
    const value = parseInt(e.target.value);

    setCalorieFilter((prevFilter) => ({
      ...prevFilter,
      [inputId]: value,
    }));
  };

  // console.log(DinnerMeals)

  const handleSearch = () => {
    const filteredBreakfast = BreakFastMeals.filter((meal) => {
      const calories = meal.details.calories.props.children;
      return calories >= calorieFilter.from && calories <= calorieFilter.to;
    });

    const filteredLunch = LunchMeals.filter((meal) => {
      const calories = meal.details.calories.props.children;
      return calories >= calorieFilter.from && calories <= calorieFilter.to;
    });

    const filteredSnacks = Snacks.filter((meal) => {
      const calories = meal.details.calories.props.children;
      return calories >= calorieFilter.from && calories <= calorieFilter.to;
    });

    const filteredDinnerMeals = DinnerMeals.filter((meal) => {
      const calories = meal.details.calories.props.children;
      return calories >= calorieFilter.from && calories <= calorieFilter.to;
    });

    setFilteredBreakfastMeals(filteredBreakfast);
    setFilteredLunchMeals(filteredLunch);
    setFilteredSnacks(filteredSnacks);
    setFilteredDinnerMeals(filteredDinnerMeals);
  };

  return (
    <>
      <Header />
      <div className={`${fixed && "fixed"} top-0 w-full h-[50px] flex items-center justify-center bg-gray-200`}>
        <label htmlFor="from">From: </label>
        <input
          className="m-2 p-1 w-32 outline-none"
          type="number"
          id="from"
          value={calorieFilter.from}
          onChange={handleFilterChange}
        />
        <label htmlFor="to">To: </label>
        <input
          className="m-2 p-1 w-32 outline-none"
          type="number"
          id="to"
          value={calorieFilter.to}
          onChange={handleFilterChange}
        />
        <button
          className="border m-2 p-2 border-black rounded-lg"
          onClick={handleSearch}
        >
          Search
        </button>
      </div>
      <div className="meals-page mp flex flex-wrap min-h-[100vh] justify-around items-center">
        {filteredBreakfastMeals &&
          filteredBreakfastMeals.map((meal) => {
            return (
              <MealCard
                ingredientsNum={meal.ingred.length}
                ingred={meal.ingred}
                pro={meal.details.protein}
                cal={meal.details.calories}
                fats={meal.details.fats}
                carb={meal.details.carb}
                time="BREAKFAST"
              />
            );
          })}
          {filteredDinnerMeals &&
          filteredDinnerMeals.map((meal) => {
            return (
              <MealCard
                ingredientsNum={meal.ingred.length}
                ingred={meal.ingred}
                pro={meal.details.protein}
                cal={meal.details.calories}
                fats={meal.details.fats}
                carb={meal.details.carb}
                time="DINNER"
              />
            );
          })}
        {filteredLunchMeals &&
          filteredLunchMeals.map((meal) => {
            return (
              <MealCard
                ingredientsNum={meal.ingred.length}
                ingred={meal.ingred}
                pro={meal.details.protein}
                cal={meal.details.calories}
                fats={meal.details.fats}
                carb={meal.details.carb}
                time="LUNCH"
              />
            );
          })}
          {filteredSnacks &&
          filteredSnacks.map((meal) => {
            return (
              <MealCard
                ingredientsNum={meal.ingred.length}
                ingred={meal.ingred}
                pro={meal.details.protein}
                cal={meal.details.calories}
                fats={meal.details.fats}
                carb={meal.details.carb}
                time="SNACK"
              />
            );
          })}
      </div>
    </>
  );
};

export default FullMealsPage;
