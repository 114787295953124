import Header from "./Header";
import { useState } from "react";
import { Link } from "react-router-dom";
import cer1 from "./images/cer1.jpeg";
import cer2 from "./images/cer2.jpeg";
import cer3 from "./images/cer3.jpeg";
import cer4 from "./images/cer4.jpeg";
import one from "./images/1.jpeg";
import two from "./images/2.jpeg";
import three from "./images/3.jpeg";
import four from "./images/4.jpeg";
import five from "./images/5.jpeg";
import six from "./images/6.jpeg";
import seven from "./images/7.jpeg";
import eight from "./images/8.jpeg";
import icon from "../components/images/name.png";
import pfp from "../Landing/images/profile-pic.png";
import stamp from "./images/stamp.png";
import iconn from "../Landing/images/icon.png";

const AboutPage = () => {
  const images = [one, two, three, four, five, six, seven, eight];
  const [translation, setTranslation] = useState(0);
  const [value, setValue] = useState(0);
  const next = () => {
    if (value > 10) {
      setValue(value - 320);
      console.log(value);
    }
    setTranslation(value);
  };
  const back = () => {
    if (value < 1200) {
      setValue(value + 320);
    }
    setTranslation(value);
  };
  setTimeout(() => {
    document.getElementById(
      "slider"
    ).style.transform = `translateX(${translation}px)`;
  }, 100);

  return (
    <div
      dir="rtl"
      className="meals-xpage pt-2 w-full flex flex-col items-center overflow-hidden"
    >
      <div
        dir="rtl"
        className="rounded-lg min-w-[400px] w-full flex flex-col items-center justify-center w-full "
      >
        <div className=" min-w-[400px] z-[999] bg-gray-200 rounded-t-lg">
          <div className="flex w-full bg-white rounded-t-lg justify-between px-8">
            <img className="w-[100px] rounded-lg" src={icon} alt="" />{" "}
            <Link to="/">
              <button className="text-lg">رجوع &#8592;</button>
            </Link>
          </div>
          <div className="flex justify-center p-4 md:flex-row flex-col w-full">
            <div className="md:w-1/5 md:flex-col flex w-[50%]">
              <img className="p-4 md:w-full z-100" src={pfp} alt="" />
              <ul className="p-4 text-xl font-bold text-gray-800">
                <Link to="https://www.facebook.com/mazen.elshahawy20?mibextid=2JQ9oc">
                  {" "}
                  <li>Facebook</li>
                </Link>
                <Link to="https://www.instagram.com/invites/contact/?i=13ehnn3og8dwh&utm_content=10gunti">
                  {" "}
                  <li>Instagram</li>
                </Link>
              </ul>
            </div>
            <p className="md:w-1/2 w-full text-xl m-4">
              <h1 className="text-2xl">مازن الشهاوي</h1> <br /> لاعب كرة قدم
              ومعد بدني لفرق كرة القدم، مدرب خاص لتطوير وتنمية لاعبي كرة القدم،
              مدرب وأخصائي تغذية معتمد في مجال الصحة والغذاء واللياقة البدنية.
              هدفي تحقيق أهداف العملاء ومساعدتهم على تحقيق أهدافهم بغض النظر عن
              الإمكانيات والظروف. لدي العديد من الشهادات لتوجيه العملاء بأحدث
              الأساليب العلمية.
            </p>
          </div>
        </div>
        <div className="flex flex-wrap w-full items-center justify-around bg-gray-200 rounded-b-lg z-[999] p-12">
          <div className="z-[999] mx-4 my-2  w-[250px] h-[250px] flex justify-center items-center">
            <img
              className="h-full pointer hover:rotate-[15deg] hover:scale-[1.05] duration-100 cursor-pointer"
              src={cer1}
              alt=""
            />
          </div>
          <div className="z-[999] mx-4 my-2 w-[250px] h-[250px] flex justify-center items-center">
            <img
              className="h-full hover:rotate-[15deg] hover:scale-[1.05] duration-100 cursor-pointer"
              src={cer2}
              alt=""
            />
          </div>
          <div className="z-[999] mx-4 my-2 w-[250px] h-[250px] flex justify-center items-center">
            <img
              className="h-full hover:rotate-[15deg] hover:scale-[1.05] duration-100 cursor-pointer"
              src={cer3}
              alt=""
            />
          </div>
          <div className="z-[999] mx-4 my-2 w-[250px] md:h-[250px] flex justify-center items-center">
            <img
              className="md:h-full w-full hover:rotate-[15deg] hover:scale-[1.05] duration-100 cursor-pointer"
              src={cer4}
              alt=""
            />
          </div>
        </div>
      </div>

      <div className="flex flex-wrap text-lg bg-gray-400 z-[999] py-16 relative w-full">
        {/* <img
          onClick={next}
          src={next_arrow}
          className="absolute right-2 top-[50%] cursor-pointer w-16 z-[9999]"
          alt=""
        /> */}
        <div
          id="slider"
          className="scroll-smooth duration-200 ease-in-out flex flex-wrap items-center justify-center mx-4"
        >
          {images.map((img) => {
            return <img key={img} className="w-[200px] m-1" src={img} alt="" />;
          })}
        </div>
        {/* <img
          src={back_arrow}
          onClick={back}
          className="absolute left-2 top-[50%] w-16 cursor-pointer"
          alt=""
        /> */}
      </div>
      <div className="bg-gray-200 relative h-[80px] md:w-[85%] w-[95%] bg-gray-200 z-[9990] shadow-2xl">
        <div className="flex items-start items-center absolute left-[-32px] bottom-[-15px]">
          <div className="bg-gray-600 h-28 w-8 rounded-xl"></div>
          <div className="bg-gray-600 h-16 w-8 rounded-xl relative left-4"></div>
        </div>
        <div className="flex items-start items-center absolute right-[-32px] bottom-[-15px]">
          <div className="bg-gray-600 h-16 w-8 rounded-xl relative right-4"></div>
          <div className="bg-gray-600 h-28 w-8 rounded-xl"></div>
        </div>
      </div>
      <div className="bg-gray-200 relative z-[999] md:w-[80%] w-[85%] flex flex-wrap p-4 py-28 justify-center text-lg">
        <div className=" absolute top-4 right-4">
          <img className="w-[100px]" src={stamp} alt="" />
          <img className="w-[50px] absolute top-6 right-6" src={iconn} alt="" />
        </div>
        <p className="w-[400px] m-4 p-4 rounded-lg bg-gray-100 border-b-4 border-blue-400">
          اول يوم دخلت في الجيم مكنش في دماغي اي حاجه كنت رايح اتسلي بس هو غير
          تفكيري خلاني مهتم بطريقه غير عاديه اني اعمل جسم في اقصر فتره والتحفيز
          بتاعه جوا الجيم غير طبيعي تحس انك تقدر تشيل أي وزن ومعاك دايما طول
          اليوم وهيكون مهتم بالتفاصيل بتاعتك والنظام الغذائي فمتعه والله التمرين
          معاه
        </p>
        <p className="w-[400px] m-4 p-4 rounded-lg bg-gray-100 border-b-4 border-blue-400">
          {" "}
          كابتن مازن بشكرك جدا ع المجهود الي انته عامله معايا فارق كبير جدا
          والتمرين معاك بيدي حماس وطاقه بيخلي المود ف حته تانيه انا فاكر اول يوم
          داخلت اتمرن معاك كنت بقول انها تمرين وخلاص بس لما اتمرنت معاك حبيت
          التمرين ومع اهتمام وانك موجود معايا بقيت اليوم ف اي استفسار ونظام
          الاكل لا بجد شكرا ليك وعلي مجهودك معايا
        </p>
        <p className="w-[400px] m-4 p-4 rounded-lg bg-gray-100 border-b-4 border-blue-400">
          كنت بدأت ازيد فى الوزن و الهدوم مكنتش بتتظبط عليا و بدأت احسن بتعب كل
          مبطلع السلم لكن روحت لكابتن مازن بدأ يهتم بيا و يعملى نظام مخصص ليا بس
          و لقيته مركز فى كل التفاصيل من اكل و تمرين و نوم مشفتوش فى حته تانيه
          الصراحه شابوه كابتن مازن
        </p>
        <p className="w-[400px] m-4 p-4 rounded-lg bg-gray-100 border-b-4 border-blue-400">
          الواحد باامانه اول مافكرت العب رياضه في الجيم كان كل هدفي ان اخس حتى
          لو مش هلعب تاني بعد ما انزل في الوزن طبعا الكلام ده بعد ما اتعملت مع ك
          مازن تبين ان الرياضه اسلوب حياه باامانه اهتمامه معايا بنظام التمرين
          والغذاء شجعني ان رياضه الجيم مش مجرد ان اخس على قد ماهي تنظيم مش جسم
          وبس واسلوب حياه بجد استفد منه وربنا يوفقه ديما
        </p>
        <p className="w-[400px] m-4 p-4 rounded-lg bg-gray-100 border-b-4 border-blue-400">
          احسن كوتش اتمرنت معاه و الله و عملنا نتيجة هايلة في وقت قصير بالتوفيق
          ان شاء الله يا كوتش
        </p>
        <p className="w-[400px] m-4 p-4 rounded-lg bg-gray-100 border-b-4 border-blue-400">
          بداية التمرين مع مازن كانت مختلفة و تعامله الاحترافي في محاولة أنه
          يريحك ويخليك تحب تيجي التمرين و مع كل تغير بيحصل في يومك أو في حياتك
          عمتا بيغير من نظام التمرين علشان يناسبك اكتر ودا خلاني افهم يعني ايه
          اتقان عمل بجد ومهما قلت مش هقدر اوفي لا تعب ولا مجهود مازن معايا
        </p>
      </div>
    </div>
  );
};

export default AboutPage;
