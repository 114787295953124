import { BreakFastMeals, LunchMeals, Snacks } from "../Meals";
import MealCard from "../components/MealCard";
import Header from "../Landing/Header";
import { useState } from "react";

const MealsPage = () => {
  const [fixed, setFixed] = useState(false);
  window.addEventListener("scroll", () => {
    const scrollY = window.scrollY;
  
    if (scrollY >= 100) {
      setFixed(true);
    } else {
      setFixed(false);
    }
  });
  return (
    <>
    <Header fixed={fixed && true} />
    <div className="meals-page mp flex flex-wrap min-h-[100vh] justify-around items-center">
      <MealCard
        time="BREAKFAST"
        ingredientsNum={3}
        ingred={BreakFastMeals[0].ingred}
        pro={BreakFastMeals[0].details.protein}
        cal={BreakFastMeals[0].details.calories}
        fats={BreakFastMeals[0].details.fats}
        carb={BreakFastMeals[0].details.carb}
      />
      <MealCard
        time="LUNCH"
        ingredientsNum={3}
        ingred={LunchMeals[0].ingred}
        pro={LunchMeals[0].details.protein}
        cal={LunchMeals[0].details.calories}
        fats={LunchMeals[0].details.fats}
        carb={LunchMeals[0].details.carb}
      />
      <MealCard
        time="SNACK"
        ingredientsNum={1}
        ingred={Snacks[0].ingred}
        pro={Snacks[0].details.protein}
        cal={Snacks[0].details.calories}
        fats={Snacks[0].details.fats}
        carb={Snacks[0].details.carb}
      />
    </div>
    </>
  );
};

export default MealsPage;
