import VideoPage from "../Free/VideoPage";
import VideosPage from "../premium/VideoPage";
import { useState, useEffect } from "react";
import axios from "axios";

const Workouts = () => {
  // const [user, setUser] = useState(null);
  // var serverUrl = "https://coach-mazen-server.vercel.app";

  // const [loading, setLoading] = useState(false);
  // const [error, setError] = useState(null);

  // useEffect(() => {
  //   const fetchUser = async () => {
  //     setLoading(true);
  //     try {
  //       const response = await axios.get(`${serverUrl}/api/getuser`, {
  //         withCredentials: true,
  //       });
  //       const userData = response.data.user;
  //       setUser(userData);
  //     } catch (error) {
  //       setError(error.response.data.message);
  //     } finally {
  //       setLoading(false);
  //     }
  //   };

  //   fetchUser(); // Call fetchUser inside useEffect

  //   console.log(user); // This should be inside useEffect to avoid running on every render
  // }, []); // Pass an empty dependency array to run the effect only once
  const user = JSON.parse(localStorage.getItem('user'));

  return (
    <div className="">
      {user ? (
        user.paid === "no" ? (
          <VideoPage />
        ) : (
          <VideosPage />
        )
      ) : (
        <VideoPage />
      )}
    </div>
  );
};

export default Workouts;
