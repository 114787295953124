import { useState } from "react";
import Header from "./Header";
import HomePage from "./HomePage";
import AboutPage from "./AboutPage";
import Guarantees from "./Guarantees";
import TypePage from "./TypePage";
import Signup from "../components/Signup";
import MealsSection from "./MealsSection";

const LandingPage = () => {
  const [active, setActive] = useState(false);
  const [active2, setActive2] = useState(false);

  const toggleActive = () => {
    setActive(!active);
  };
  const toggleActive2 = () => {
    setActive2(!active2);
  };

  return (
    <div dir="rtl" lang="ar">
      <Header signClick={toggleActive} fixed={true} />
      <HomePage />
      <AboutPage />
      <Guarantees />
      <TypePage />
      <MealsSection />
      <Signup
        isVisible={active}
        close={toggleActive}
        change={() => {
          toggleActive();
          toggleActive2();
        }}
      />
    </div>
  );
};

export default LandingPage;
