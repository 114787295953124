import video0 from "./videos/video - Copy (18)/video.mp4";
import video1 from "./videos/video - Copy (19)/video.mp4";
import video2 from "./videos/video - Copy (20)/video.mp4";
import video3 from "./videos/video - Copy (21)/video.mp4";
import video4 from "./videos/video - Copy (22)/video.mp4";
import video5 from "./videos/video - Copy (23)/video.mp4";
import video6 from "./videos/video - Copy (24)/video.mp4";
import video7 from "./videos/video - Copy (25)/video.mp4";
import video8 from "./videos/video - Copy (26)/video.mp4";
import video9 from "./videos/video - Copy (27)/video.mp4";
import video10 from "./videos/video - Copy (28)/video.mp4";
import video11 from "./videos/video - Copy (29)/video.mp4";
import video12 from "./videos/video - Copy (30)/video.mp4";
import video13 from "./videos/video - Copy (31)/video.mp4";
import video14 from "./videos/video - Copy (32)/video.mp4";
import video15 from "./videos/video - Copy (33)/video.mp4";

export const shoulder = [
  {
    video: (
      <video
        className="min-h-[300px] max-h-[500px] max-w-[400px]"
        src={video0}
        muted
        loop
        autoPlay
      />
    ),
    Name: "Cable Reverse Fly",
    Notes: (
      <p>
       1- قف منتصباً والكابل موجود على جانبك

        <br />
        <br />
       2- أمسك مقبض البكرة بالذراع الأبعد عن البكرة.

        <br />
        <br /> 3- مدِّد ذراعك على جسمك ومرفقك مثني قليلا.

        <br /> 4- العودة إلى موقع البداية بطريقة منضبطة.
      </p>
    ),
  },
  {
    video: (
      <video
        className="min-h-[300px] max-h-[500px] max-w-[400px]"
        src={video1}
        muted
        loop
        autoPlay
      />
    ),
    Name: "Cable Face Pull",
    Notes: (
      <p>
        1- قف منتصباً

        <br />
        2- في وضع البدء، قم بتمديد ذراعيك بالكامل إلى الأمام في ارتفاع العين.

        <br /> 3- اسحب مقبض الحبل للخلف جالبا ظهر راحتيك إلى جانب رأسك.

        <br /> 4- ركّز على القيادة مع إرجاع مرفقيك، أبقيهما في نفس ارتفاع عينيك.

        <br />
        5-حافظ على وضع النهاية للحظة قبل العودة إلى وضع البداية بطريقة منضبطة.
      </p>
    ),
  },
  {
    video: (
      <video
        className="min-h-[300px] max-h-[500px] max-w-[400px]"
        src={video2}
        muted
        loop
        autoPlay
      />
    ),
    Name: "Dumbbell Bent Over Reverse Fly",
    Notes: (
      <p>
       1- لنفترض ان الجذع موازٍ للارض

        <br />
       2- في البداية، اجعلوا اذرعكم تتدلى تحت صدركم ومرفقاكم مستقيمان تقريبا.

        <br /> 3- ارفع مرفقيك الى الجانب بطريقة مضبوطة، على الاقل الى ان توازي ذراعاك الارض.

        <br />
       4- أخفضوا أسلحتكم بطريقة متحكم بها
      </p>
    ),
  },
  {
    video: (
      <video
        className="min-h-[300px] max-h-[500px] max-w-[400px]"
        src={video3}
        muted
        loop
        autoPlay
      />
    ),
    Name: "Rear Delt Machine",
    Notes: (
      <p>
       1- اجلس وصدرك مضغوط بقوة على الوسادة

        <br />
       2- ضع المقابض على ارتفاع الكتف

        <br />
       3- ضع ذراعيك على جانبيك، اتبع الطريق الذي تمليه الآلة.

        <br />
       4- اجلب مرفقيك خلف خط جسدك

        <br />
       5- احتفظ بموضع النهاية للحظة قبل العودة إلى موضع البداية بطريقة بطيئة ومضبوطة.
        <br />
       
      </p>
    ),
  },
  {
    video: (
      <video
        className="min-h-[300px] max-h-[500px] max-w-[400px]"
        src={video4}
        muted
        loop
        autoPlay
      />
    ),
    Name: "Standing Cable Front Raise",
    Notes: (
      <p>
        1- قفوا منتصبين مع ربط عضلاتكم، رباعياتكم وعضلاتكم.

        <br />
        2- عند نقطة البداية، اجعلوا المقبض معلق تقريبا عند ورككم ومرفقكم مقفولا.

        <br />
        3- ارفع ذراعيك على الأقل حتى تصل إلى ارتفاع الكتف.

        <br />
        4- تجنب تجاهل \ رفع كتفك لأعلى أو خلق لحظة بظهرك.

        <br />
        5- العودة إلى موقع البداية بطريقة منضبطة.

        <br />
        6- رفع الوزن الأقصى
      </p>
    ),
  },
  {
    video: (
      <video
        className="min-h-[300px] max-h-[500px] max-w-[400px]"
        src={video5}
        muted
        loop
        autoPlay
      />
    ),
    Name: "Standing Dumbbell Front Raise",
    Notes: (
      <p>
        1- قف منتصباً

        <br />
        2- عند نقطة البداية، اجعلوا الدمبيل تتدلى عند اريككم ومرفقاكم مقفولين.

        <br />  3- ارفع الدمبيل إلى الأمام على الأقل حتى تصل إلى ارتفاع الكتف.

        <br />
        4- تجنب تجاهل \ رفع كتفك لأعلى أو خلق لحظة بظهرك.

        <br />
        5- أخفض الأوزان بطريقة مضبوطة 
      </p>
    ),
  },
  {
    video: (
      <video
        className="min-h-[300px] max-h-[500px] max-w-[400px]"
        src={video6}
        muted
        loop
        autoPlay
      />
    ),
    Name: "Standing Barbell Front Raise",
    Notes: (
      <p>
        1- قفوا منتصبين مع ربط عضلاتكم، رباعياتكم وعضلاتكم.

        <br />
        2- عند نقطة البداية، اجعلوا القضيب يتدلى عند وركيكم ومرفقاكم مقفلان

        <br /> 3- ارفع الحاجز إلى الأمام على الأقل حتى يصل إلى ارتفاع الكتف.

        <br />
        4- تجنب تجاهل \ رفع كتفك لأعلى أو خلق لحظة بظهرك.

        <br />
        5- أنزل البار بطريقة متحكم بها
      </p>
    ),
  },
  {
    video: (
      <video
        className="min-h-[300px] max-h-[500px] max-w-[400px]"
        src={video7}
        muted
        loop
        autoPlay
      />
    ),
    Name: "Standing Dumbbell Lateral Raise",
    Notes: (
      <p>
        1- أديروا ذراعيكم قليلا، واضعين إبهامكم الى الاسفل وأصبعكم الخنصر يقود الحركة.

        <br />
        2- ارفع ذراعيك بزاوية 30 درجة من جذعك وعلى الأقل حتى تكون موازية للأرض.

        <br /> 3- العودة إلى موقع البداية.
      </p>
    ),
  },
  {
    video: (
      <video
        className="min-h-[300px] max-h-[500px] max-w-[400px]"
        src={video8}
        muted
        loop
        autoPlay
      />
    ),
    Name: "Single Arm Cable Lateral Raise",
    Notes: (
      <p>
        1- ١ أديروا ذراعكم قليلا، واضعين اصابع ابهامكم الى الاسفل وإصبعكم الخنصر يقود الحركة.

        <br />
        2- ٢ ارفع ذراعك بزاوية ٣٠ درجة من جذعك الى مقدِّمة خط العرض قليلا.

        <br /> 3- ٣ ارفع ذراعيك على الاقل حتى توازيا الارض.

        <br />
        4- العودة إلى مركز البداية.
      </p>
    ),



  },
  {
    video: (
      <video
        className="min-h-[300px] max-h-[500px] max-w-[400px]"
        src={video9}
        muted
        loop
        autoPlay
      />
    ),
    Name: "Single Arm  Lateral Raise",
    Notes: (
      <p>
        1-١ أديروا ذراعكم قليلا، واضعين اصابع ابهامكم الى الاسفل وإصبعكم الخنصر يقود الحركة.

        <br />
        2- ٢ ارفع ذراعك بزاوية ٣٠ درجة من جذعك الى مقدِّمة خط العرض قليلا.

        <br />
        3- ٣ ارفع ذراعيك على الاقل حتى توازيا الارض.
.
        <br />
        4-Return to the starting position.\\\\\\\\\\\\\\\\\\\
      </p>
    ),
  },
  {
    video: (
      <video
        className="min-h-[300px] max-h-[500px] max-w-[400px]"
        src={video10}
        muted
        loop
        autoPlay
      />
    ),
    Name: "Selector Overhead Press Machine",
    Notes: (
      <p>
        Narrow Notes Sit upright, with your back pressed against the pad.
        <br />
        Keep your elbows right below the handles, with your forearm perpedicular
        to the ground Keep your glutes and abs squeezed during the entire set.
        <br /> Press the handles upwards until your elbows are locked.
        <br />
        Avoid leaning back or arching your spine.
        <br />
        Descend in a controlled manner to the starting position.\\\\\\\\\\\\\\\\\
      </p>
    ),
  },
  {
    video: (
      <video
        className="min-h-[300px] max-h-[500px] max-w-[400px]"
        src={video11}
        muted
        loop
        autoPlay
      />
    ),
    Name: "Selector Overhead Press Machine, Wide",
    Notes: (
      <p>
        1- اجلس منتصباً وظهرك مضغوط على الوسادة

        <br />
        2- امسك المقابض في وضع واسع.

        <br /> 3- ٣ أبقِ عضلاتك وعضلاتك مضغوطتين طوال فترة التصوير.

        <br />
        4- اضغط المقابض لأعلى حتى يُقفل مرفقيك.

        <br />
        5- ٥ تجنَّب الميل الى الوراء او تقويس عمودك الفقري.
        <br />
        6- الهبوط بطريقة منضبطة إلى نقطة البداية.
      </p>
    ),
  },
  {
    video: (
      <video
        className="min-h-[300px] max-h-[500px] max-w-[400px]"
        src={video12}
        muted
        loop
        autoPlay
      />
    ),
    Name: "Seated Dumbbell Overhead Press",
    Notes: (
      <p>
        1-Sit upright, with your back pressed against the pad.
        <br />
        2-Hold up the dumbbells at the sides of your shoulders.
        <br /> 3-Keep your elbows right below the dumbbells, with your forearm
        perpedicular to the ground.
        <br />
        4-Press the dumbbells upwards keeping them in line with your body.
        <br />
        5-Avoid leaning back or arching your spine. Finish with your elbows
        locked and your 6arms in line with your ears. 7-Descend in a controlled
        manner to the starting position.\\\\\\\\\\\\\\\\\\
      </p>
    ),
  },
  {
    video: (
      <video
        className="min-h-[300px] max-h-[500px] max-w-[400px]"
        src={video13}
        muted
        loop
        autoPlay
      />
    ),
    Name: "Standing Dumbbell Shrugs",
    Notes: (
      <p>
       1- عجل أمسك الدمبل على جانبيك بينما تقف منحني للامام قليلا

        <br />
       2- تعاقد مع الفخاخ برفع كتفك إلى أذنيك.

        <br /> 3-  ارفع كتفيك لأعلى ما يمكنك، ثم امسك بالوضع العلوي للحظة قبل أن تعود إلى حالة الشنق.

        <br /> 4- لا تستخدم القوة الدافعة او الميل.
      </p>
    ),
  },
  {
    video: (
      <video
        className="min-h-[300px] max-h-[500px] max-w-[400px]"
        src={video14}
        muted
        loop
        autoPlay
      />
    ),
    Name: "Dumbbell Incline Prone Reverse Fly",
    Notes: (
      <p>
        1- أبقي صدرك مضغوطًا على المقعد وذراعيك مستقيمتان تقريبًا.

        <br />
        2- ارفع الأثقال على الأقل حتى تكون الدمبيل موازية للأرض.
        <br /> Lower your arms in a controlled manner.\\\\\\\\\\\\\\\\\\\\\\\\\\
      </p>
    ),
  },
  {
    video: (
      <video
        className="min-h-[300px] max-h-[500px] max-w-[400px]"
        src={video15}
        muted
        loop
        autoPlay
      />
    ),
    Name: "Single Arm  front raise",
    Notes: (
      <p>
        1- ارفع ذراعيك على الاقل حتى توازيا الارض.

        <br />
        2- العودة إلى مركز البداية.
      </p>
    ),
  },
];
