import { useState, useEffect } from "react";
import icon from "./images/icon.png";
import name from "./images/name.png";
import menu from "./images/menu.png";
import pfp from "./images/def_pfp.png"
import Signup from "../components/Signup";
import { Link } from "react-router-dom";
import axios from "axios";

const Header = ({ fixed }) => {
  const [active, setActive] = useState(false);
  const [popUp, setPopUp] = useState(false);
  const [mob, setMob] = useState(false);

  const toggleActive = () => {
    setActive(!active);
  };

  const user = JSON.parse(localStorage.getItem('user'));
  console.log(user)
  // const LogOut = localStorage.removeItem('user');
  // var serverUrl = "https://coach-mazen-server.vercel.app";

  // const logout = () => {
  //   window.open(`${serverUrl}/auth/logout`, "_self");
  // };

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  // const [user, setUser] = useState(null);

  // useEffect(() => {
  //   const fetchUser = async () => {
  //     setLoading(true);
  //     try {
  //       const response = await axios.get(`${serverUrl}/api/getuser`, {
  //         withCredentials: true,
  //       });
  //       const userData = response.data.user;
  //       setUser(userData);
  //     } catch (error) {
  //       setError(error.response.data.message);
  //     } finally {
  //       setLoading(false);
  //     }
  //   };

  //   fetchUser();

  //   console.log(user)
  // }, []);

    const handleScrollDown = () => {
    window.scrollTo({ top: 1300, behavior: 'smooth' });
  }
  const handleScrollDownMob = () => {
    window.scrollTo({ top: 2100, behavior: 'smooth' });
    setMob(false);
  }

  return (
    <div dir="rtl">
      <div
        className={`flex bg-stone-700 ${fixed && "fixed"
          } w-full overflow-hidden z-[30] justify-between p-2 px-4 lg:px-32`}
      >
        <Link to="/">
          <div className="flex items-center">
            <img className="w-[60px]" src={icon} alt="" />
            <img className="w-[130px]" src={name} alt="" />
          </div>
        </Link>
        {/* <button onClick={LogOut}>Log out</button> */}
        <ul className="lg:flex text-white items-center text-lg hidden">
          <Link to="/">
            <li className="cursor-pointer">الرئيسية</li>
          </Link>
          <Link to="/about">
            <li className="mr-6 cursor-pointer">من أنا</li>
          </Link>
          <Link to="/onlinecoaching">
            {" "}
            <li className="mr-6 cursor-pointer">التدريب الأونلاين</li>
          </Link>
            <li onClick={handleScrollDown} className="mr-6 cursor-pointer">التمارين</li>
          <Link to="/meals">
            <li className="mr-6">الوجبات</li>
          </Link>
          <Link to="/blog">
            <li className="mr-6 cursor-pointer">المقالات</li>
          </Link>
          <li
            onMouseEnter={() => setPopUp(true)}
            onMouseLeave={() => setPopUp(false)}
            className="mr-6"
          >
            {user ? (
              <Link to="/profile">
                <div className="cursor-pointer" dir="ltr">
                  <center> <img className="w-8" src={pfp} alt="" /> </center>
                  <div className="flex items-center">
                    <p>{user.name}</p>
                  </div>
                </div>
              </Link>
            ) : (
              <Link to="/register">
                <li
                  className="ml-6 cursor-pointer border bg-white text-black font-semibold p-2 rounded-lg"
                >
                  تسجيل الدخول
                </li>
              </Link>
            )}
          </li>
        </ul>
        <li
          onClick={() => setMob(true)}
          className="flex lg:hidden items-center"
        >
          <img className="w-[40px] cursor-pointer" src={menu} alt="" />{" "}
          {user && (
            <div>

            </div>
          )}
        </li>
      </div>
      {/* mobile */}
      <center
        className={`list-none fixed bg-white top-0 left-0 w-full h-full items-center justify-between lg:flex flex-col lg:hidden ${!mob && "hidden"
          } items-center text-xl z-[30]`}
      >
        <div
          onClick={() => setMob(false)}
          className="rounded-md text-gray-700 border-b hover:text-gray-500 absolute left-0 p-2 ml-4 relative bottom-2 cursor-pointer text-2xl font-semibold"
        >
          رجوع
        </div>
        <Link to='/'>
          <li className="cursor-pointer mt-16">الرئيسية</li>
        </Link>
        <Link to="/about">
          <li className=" mt-6 cursor-pointer mt-4">من أنا</li>
        </Link>
        <Link to="/onlinecoaching">
          {" "}
          <li className=" mt-6 cursor-pointer">التدريب الأونلاين</li>
        </Link>
          <li onClick={handleScrollDownMob} className=" mt-6 cursor-pointer">التمارين</li>
        <Link to="/meals">
          <li className="mt-6">الوجبات</li>
        </Link>
        <Link to="/blog">
          <li className="mt-6 cursor-pointer">المقالات</li>
        </Link>
        <div>
          <li className="">
            {user ? (
              <div className="absolute bottom-0 bg-stone-700 h-1/3 w-full">
                <div className="flex items-center justify-center bg-stone-700 p-4">
                   <center>
                     <Link to="/profile">
                    <p className="text-xl text-white relative bottom-4">
                      {user.name}
                    </p>
                    <p className="text-white text-lg relative bottom-6">
                      {user.paid ==="no" ? "Freemium" : user.paid}
                    </p>
                       </Link>
{/*                     <button
                      onClick={logout}
                      className="border border-white mt-2 p-2 text-white border-b rounded-lg"
                    >
                      {" "}
                      تسجيل الخروج
                    </button> */}
                  </center> 
                  <div
                    onMouseEnter={() => setPopUp(true)}
                    onMouseLeave={() => setPopUp(false)}
                    onClick={() => setMob(false)}
                    className={`${!popUp && "hidden"
                      } flex flex-col items-center fixed bg-stone-700 border-t border-black rounded-t-md p-8 absolute top-20 left-[57px]`}
                  ></div>
                </div>
              </div>
            ) : (
        <Link to="/register">
              <li
                className="w-[50%] text-white bg-stone-700 mt-8 cursor-pointer border text-black font-semibold p-4 rounded-lg"
              >
                تسجيل الدخول
              </li>
          </Link>
            )}
          </li>
        </div>
      </center>
      {/* <div onClick={()=>{localStorage.removeItem('user')}} className="p-2 bg-gray-200 fixed bottom-2 pointer">Logout</div> */}
    </div>
  );
};

export default Header;
