import legIcon from "./images/colored-leg.png";
import shoulderIcon from "./images/shoulder.png";
import chestIcon from "./images/chest.png";
import triIcon from "./images/triceps.png";
import biIcon from "./images/biceps.png";
import backIcon from "./images/back.png";
import { Link } from "react-router-dom";
import TypeCard from "../components/TypeCard";
const TypePage = () => {
  return (
    <div className="flex flex-col items-start p-12 ">
      <div className="w-full flex justify-between">
        <h1 className="text-2xl"> التمارين </h1>
      </div>
      <hr className="w-full mt-2 border-black"></hr>
      <div
        id="container"
        className="flex flex-wrap  justify-center md:justify-start w-full overflow-hidden flex-wrap"
      >
        <Link to="/workouts/leg">
          <TypeCard icon={legIcon} title="تمارين القدم" />
        </Link>
        <Link to="/workouts/shoulder">
          <TypeCard icon={shoulderIcon} title="تمارين الكتف" />
        </Link>
        <Link to="/workouts/chest">
          <TypeCard icon={chestIcon} title="تمارين الصدر" />
        </Link>
        <Link to="/workouts/biceps">
          <TypeCard icon={biIcon} title="تمارين عضلة الباي" />
        </Link>
        <Link to="/workouts/triceps">
          <TypeCard icon={triIcon} title="تمارين التراي" />
        </Link>
        <Link to="/workouts/back">
          <TypeCard icon={backIcon} title="تمارين الظهر" />
        </Link>
      </div>
    </div>
  );
};

export default TypePage;
