import video0 from "./videos/video - Copy (34)/video.mp4";
import video1 from "./videos/video - Copy (35)/video.mp4";
import video2 from "./videos/video - Copy (36)/video.mp4";
import video3 from "./videos/video - Copy (37)/video.mp4";
import video4 from "./videos/video - Copy (38)/video.mp4";
import video5 from "./videos/video - Copy (39)/video.mp4";
import video6 from "./videos/video - Copy (40)/video.mp4";
import video7 from "./videos/video - Copy (41)/video.mp4";
import video8 from "./videos/video - Copy (42)/video.mp4";
import video9 from "./videos/video - Copy (43)/video.mp4";
import video10 from "./videos/video - Copy (44)/video.mp4";
import video11 from "./videos/video - Copy (45)/video.mp4";
import video12 from "./videos/video - Copy (46)/video.mp4";
import video13 from "./videos/video - Copy (47)/video.mp4";

export const biceps = [
  {
    video: (
      <video
        className="min-h-[300px] max-h-[500px] max-w-[400px]"
        src={video0}
        muted
        loop
        autoPlay
      />
    ),
    Name: "EZ Bar Standing Barbell Curl, Wide Grip",
    Notes: (
      <p>
       1- الوقوف بشكل مستقيم.

        <br />
       2- أمسك البار بقبضة أعرض من عرض الكتفين.

        <br /> 3- في وضع البداية، أمسك الوزن في وضعية تعليق ميتة وذراعيك مستقيمتين.

        <br /> 4- بطريقة محكومة، قم بإجراء التجعيد عن طريق ثني مرفقيك حتى يصبح البار أمام رقبتك.
      </p>
    ),
  },
  {
    video: (
      <video
        className="min-h-[300px] max-h-[500px] max-w-[400px]"
        src={video1}
        muted
        loop
        autoPlay
      />
    ),
    Name: "EZ Bar Preacher Curl",
    Notes: (
      <p>
        1- اجلس على المقعد مع الضغط على الوسادة بقوة على ذراعك وصدرك والإبط.

        <br />
        2- أمسك البار بعرض الكتفين بقبضة سفلية.

        <br /> 3- في وضع البداية، أمسك الشريط بذراعيك مستقيمتين.

        <br /> 4- بطريقة محكومة، قم بإجراء التجعيد عن طريق ثني مرفقيك حتى يتم ثنيهما بالكامل ويكون الشريط قريبًا من رقبتك.

        <br />
        5- العودة إلى وضع البداية بطريقة تسيطر عليها.
      </p>
    ),
  },
  {
    video: (
      <video
        className="min-h-[300px] max-h-[500px] max-w-[400px]"
        src={video2}
        muted
        loop
        autoPlay
      />
    ),
    Name: "H Preacher Curl",
    Notes: (
      <p>
        1- اجلس على المقعد مع الضغط على الوسادة بقوة على ذراعك وصدرك والإبط.

        <br />
        2- أمسك الجهاز بعرض الكتفين بقبضة محايدة.

        <br /> 3- في وضع البداية، أمسك الشريط بذراعيك مستقيمتين.

        <br />
        4- بطريقة محكومة، قم بإجراء التجعيد عن طريق ثني مرفقيك حتى يتم ثنيهما بالكامل ويكون الشريط قريبًا من رقبتك.

        <br />
        5- العودة إلى وضع البداية بطريقة تسيطر عليها.
      </p>
    ),
  },
  {
    video: (
      <video
        className="min-h-[300px] max-h-[500px] max-w-[400px]"
        src={video3}
        muted
        loop
        autoPlay
      />
    ),
    Name: "Standing Dumbbell Curl",
    Notes: (
      <p>
        1- الوقوف بشكل مستقيم.

        <br />
        2- في وضع البداية، أمسك الوزن في وضعية تعليق مميتة وذراعيك مستقيمتين واستخدم قبضة اليد السفلية.

        <br />
        3- بطريقة محكومة، قم بإجراء التجعيد عن طريق ثني مرفقيك حتى يصبح الدمبل أمام رقبتك
      </p>
    ),
  },
  {
    video: (
      <video
        className="min-h-[300px] max-h-[500px] max-w-[400px]"
        src={video4}
        muted
        loop
        autoPlay
      />
    ),
    Name: "Standing Dumbbell Hammer Curl",
    Notes: (
      <p>
        1- الوقوف بشكل مستقيم.
        <br />
        2- في وضع البداية، أمسك الوزن في وضعية تعليق مميتة وذراعيك مستقيمتين، واستخدم قبضة محايدة.

        <br />
        3- بطريقة محكومة، قم بإجراء التجعيد عن طريق ثني مرفقيك حتى يصبح الدمبل أمام رقبتك.
      </p>
    ),
  },
  {
    video: (
      <video
        className="min-h-[300px] max-h-[500px] max-w-[400px]"
        src={video5}
        muted
        loop
        autoPlay
      />
    ),
    Name: "Seated Dumbbell Curl",
    Notes: (
      <p>
        1- اجلس مع ظهرك مضغوطًا على الوسادات.

        <br />
        2- في وضع البداية، أمسك الدمبلز على جانبيك وذراعيك مستقيمتين باستخدام قبضة اليد السفلية.

        <br /> 3- قم بإجراء التجعيد عن طريق ثني مرفقيك ورفع الدمبل حتى يصبحا أمام رقبتك.
        4- العودة إلى وضع البداية بطريقة بطيئة ومنضبطة.
      </p>
    ),
  },
  {
    video: (
      <video
        className="min-h-[300px] max-h-[500px] max-w-[400px]"
        src={video6}
        muted
        loop
        autoPlay
      />
    ),
    Name: "Concentration Curl",
    Notes: (
      <p>
       1- أثناء جلوسك على مقعد، انحنِ إلى الأمام وثبت ذراعك عن طريق الضغط بمرفقك على الجزء الداخلي من فخذك.

        <br />
       2- في وضع البداية، اجعل الدمبل معلقًا أسفل كتفك مع تثبيت مرفقك.

        <br /> 3- قم بثني الوزن عن طريق ثني مرفقك حتى يلمس الدمبل كتفك.

        <br />
       4- العودة إلى وضع البداية بطريقة بطيئة ومنضبطة.
      </p>
    ),
  },
  {
    video: (
      <video
        className="min-h-[300px] max-h-[500px] max-w-[400px]"
        src={video7}
        muted
        loop
        autoPlay
      />
    ),
    Name: "Standing Cable Curl",
    Notes: (
      <p>
        1- قف بشكل مستقيم مع عضلات المؤخرة والعضلات الرباعية وعضلات البطن.

        <br />
        2- في وضع البداية، أمسك المقبض في وضعية تعليق  وذراعيك مستقيمتين باستخدام قبضة سفلية.

        <br /> 3- بطريقة محكومة، قم بإجراء التجعيد عن طريق ثني مرفقيك حتى يصبح المقبض أمام رقبتك.
      </p>
    ),
  },
  {
    video: (
      <video
        className="min-h-[300px] max-h-[500px] max-w-[400px]"
        src={video8}
        muted
        loop
        autoPlay
      />
    ),
    Name: "Standing Cable hammer Curl",
    Notes: (
      <p>
       1- قف بشكل مستقيم مع عضلات المؤخرة والعضلات الرباعية وعضلات البطن.

        <br />
       2- في وضع البداية، أمسك الحبل في وضعية تعليق  وذراعيك مستقيمتين باستخدام قبضة سفلية.

        <br /> 3- بطريقة محكومة، قم بإجراء التجعيد عن طريق ثني مرفقيك حتى يصبح المقبض أمام رقبتك.
      </p>
    ),
  },
  {
    video: (
      <video
        className="min-h-[300px] max-h-[500px] max-w-[400px]"
        src={video9}
        muted
        loop
        autoPlay
      />
    ),
    Name: "Standing Cable Curl overhand grip",
    Notes: (
      <p>
       1- قف بشكل مستقيم مع عضلات المؤخرة والعضلات الرباعية وعضلات البطن.

        <br />
       2- في وضع البداية، أمسك المقبض بالقبضة المعكوسة في وضعية تعليق  وذراعيك مستقيمتين باستخدام قبضة سفلية.

        <br /> 3- بطريقة محكومة، قم بإجراء التجعيد عن طريق ثني مرفقيك حتى يصبح المقبض أمام رقبتك.
        <br />
      </p>
    ),
  },
  {
    video: (
      <video
        className="min-h-[300px] max-h-[500px] max-w-[400px]"
        src={video10}
        muted
        loop
        autoPlay
      />
    ),
    Name: "Standing Cable Curl, Single Arm ",
    Notes: (
      <p>
        1- قف بشكل مستقيم مع عضلات المؤخرة والعضلات الرباعية وعضلات البطن.

        <br />
        2- في وضع البداية، أمسك المقبض بيد واحده في وضعية تعليق  وذراعك مستقيمة، باستخدام قبضة سفلية.

        <br />
        3- بطريقة محكومة، قم بإجراء التجعيد عن طريق ثني مرفقك حتى يصبح المقبض أمام رقبتك.
      </p>
    ),
  },
  {
    video: (
      <video
        className="min-h-[300px] max-h-[500px] max-w-[400px]"
        src={video11}
        muted
        loop
        autoPlay
      />
    ),
    Name: "Preacher Curl Machine",
    Notes: (
      <p>
        1- اجلس على المقعد مع الضغط على الوسادة بقوة على ذراعك وصدرك والإبط.

        <br />
        2- أمسك المقبض بعرض الكتفين بقبضة سفلية.

        <br />
        3- في وضع البداية، حافظ على ذراعيك مستقيمتين.

        <br />
        4- بطريقة مسيطر عليها، قم بإجراء التجعيد عن طريق ثني مرفقيك حتى يتم ثنيهما بالكامل وتكون المقابض قريبة من رقبتك.

        <br />
        5- العودة إلى وضع البداية بطريقة مسيطر عليها.
      </p>
    ),
  },
  {
    video: (
      <video
        className="min-h-[300px] max-h-[500px] max-w-[400px]"
        src={video12}
        muted
        loop
        autoPlay
      />
    ),
    Name: "Standing Cable Curl, Single Arm",
    Notes: (
      <p>
        1- قف بشكل مستقيم مع عضلات المؤخرة والعضلات الرباعية وعضلات البطن.

        <br />
        2- في وضع البداية، أمسك المقبض بيد واحده في وضعية تعليق  وذراعك مستقيمة، باستخدام قبضة سفلية.

        <br /> 3- بطريقة محكومة، قم بإجراء التجعيد عن طريق ثني مرفقك حتى يصبح المقبض أمام رقبتك.
      </p>
    ),
  },
  {
    video: (
      <video
        className="min-h-[300px] max-h-[500px] max-w-[400px]"
        src={video13}
        muted
        loop
        autoPlay
      />
    ),
    Name: "Standing Cable Curl, Single Arm",
    Notes: (
      <p>
        1-  قف بشكل مستقيم مع عضلات المؤخرة والعضلات الرباعية وعضلات البطن.

        <br />
        2- في وضع البداية، أمسك المقبض بيد واحده في وضعية تعليق  وذراعك مستقيمة

        <br /> 3- بطريقة محكومة، قم بإجراء التجعيد عن طريق ثني مرفقك
      </p>
    ),
  },
];
