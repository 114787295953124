import React, { useState } from 'react';
// import { useNavigate } from 'react-router-dom';

const Register = () => {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    password: '',
    password2: ''
  });

  const [errors, setErrors] = useState({});
  const [error, setError] = useState();
  const [msg, setMsg] = useState();

  const { name, email, password, password2 } = formData;

  const onChange = e => setFormData({ ...formData, [e.target.name]: e.target.value });

  const validateForm = () => {
    const errors = {};
    if (!name) errors.name = 'Name is required';
    if (!email) errors.email = 'Email is required';
    if (!password) errors.password = 'Password is required';
    if (password !== password2) errors.password2 = 'Passwords do not match';
    return errors;
  };

  const onSubmit = async e => {
    e.preventDefault();
    const formErrors = validateForm();
  if (formErrors) { // Check if formErrors exists before using it
    if (Object.keys(formErrors).length > 0) {
      setErrors(formErrors);
      return;
    }
  }  
  setErrors({});
// http://localhost:3006
    try {
      const response = await fetch('https://api.coachmazen.com/auth/register', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(formData)
      });

      if (!response.ok) {
        const errorData = await response.json();
        setError(response.data.errors[0].msg);
      } else {
        setMsg("تم ارسال رسالة التأكيد, برجاء مراجعة بريدك الالكتروني")
        setError('');
      }
    } catch (error) {
      setErrors({ submit: 'Registration failed. Please try again later.' });
    }


//     if (response.ok) {
//       setMsg("تم ارسال رسالة التأكيد, برجاء مراجعة بريدك الالكتروني")
//       setError('');
//       console.log(response.data.msg);
//   } else {
//       // Set error message
//       setError(response.data.errors[0].msg);
//   }
// }
//  catch (error) {
//   setError('حدث خطأ ما, حاول استخدام بريد الكتروني أو اسم مختلف');
//   setMsg('')
// }
    
  };

  return (
    <div className="flex flex-col justify-center items-center h-screen  my-auto">
          <form onSubmit={onSubmit} className="flex flex-col items-center max-w-[600px] shadow shadow-2 p-4 bg-white w-[500px] border">
            <div className="w-3/4 flex flex-col my-1">
              <label htmlFor="name">Name</label>
              <input
                type="text"
                id="name"
                name="name"
                className={`form-control ${errors.name ? 'is-invalid' : ''} p-2 border`}
                placeholder="Enter Name"
                value={name}
                onChange={onChange}
              />
              {errors.name && <div className="invalid-feedback text-red-600 bg-red-200 p-1 my-1">{errors.name}</div>}
            </div>
            <div className="w-3/4 flex flex-col m">
              <label htmlFor="email">Email</label>
              <input
                type="email"
                id="email"
                name="email"
                className={`form-control ${errors.email ? 'is-invalid' : ''} p-2 border`}
                placeholder="Enter Email"
                value={email}
                onChange={onChange}
              />
              {errors.email && <div className="invalid-feedbacktext-red-600 bg-red-200 p-1 my-1">{errors.email}</div>}
            </div>
            <div className="w-3/4 flex flex-col m">
              <label htmlFor="password">Password</label>
              <input
                type="password"
                id="password"
                name="password"
                className={`form-control ${errors.password ? 'is-invalid' : ''} p-2 border`}
                placeholder="Enter Password"
                value={password}
                onChange={onChange}
              />
              {errors.password && <div className="invalid-feedback text-red-600 bg-red-200 p-1 my-1">{errors.password}</div>}
            </div>
            <div className="w-3/4 flex flex-col m">
              <label htmlFor="password2">Confirm Password</label>
              <input
                type="password"
                id="password2"
                name="password2"
                className={`form-control ${errors.password2 ? 'is-invalid' : ''} p-2 border`}
                placeholder="Confirm Password"
                value={password2}
                onChange={onChange}
              />
              {errors.password2 && <div className="invalid-feedback text-red-600 bg-red-200 p-1 my-1">{errors.password2}</div>}
            </div>
            <button type="submit" className="border m-4 px-4 py-2 cursor-pointer bg-cyan-400 text-white">
              Register
            </button>
          </form>
          <p className="mt-4">
            لديك حساب بالفعل؟ <a href="/login">تسجيل الدخول</a>
          </p>
<div className='m-2 bg-green-200 text-green-800'>{msg}</div>
<div className='m-2 bg-red-200 text-red-800'>{error}</div>
        </div>
  );
};

export default Register;


// import { Link } from "react-router-dom";
// import { useState } from 'react';
// import axios from 'axios';


// const RegisterPage = () => {

//     const [formData, setFormData] = useState({
//         name: '',
//         email: '',
//         password: '',
//     });
    
//     const [activationToken, setActivationToken] = useState('');
//     const [message, setMessage] = useState('');
//     const [error, setError] = useState('');
    
//     const handleInputChange = (e) => {
//         setFormData({
//             ...formData,
//             [e.target.name]: e.target.value,
//         });
//     };
    
//     const handleRegistrationSubmit = async (e) => {
//         e.preventDefault();
    
//         try {
//             // Send registration data to the backend
//             const response = await axios.post('https://coach-mazen-server.vercel.app/api/create-user', formData);
//             setMessage(`افحص بريدك الالكتروني ${formData.email} لتأكيد الحساب`);
//         } catch (error) {
//             setError("البريد الالكتروني أو اسم المستخدم مستخدمون بالفعل");
//         }
//     };
    

//     return (
//         <div className="flex flex-col justify-center items-center h-screen  my-auto">
//             <form onSubmit={handleRegistrationSubmit} className="flex flex-col items-center max-w-[600px] shadow shadow-2 p-4 bg-white w-[500px] border" action="">
//                 <div className="w-3/4 flex flex-col">
//                     <label htmlFor="name">Username: </label>
//                     <input onChange={handleInputChange} className="border outline-none p-2" type="text" id="name" name="name" required />
//                 </div>
//                 <br />
//                 <div className="w-3/4 flex flex-col">
//                     <label htmlFor="email">Email</label>
//                     <input onChange={handleInputChange} className="border p-2 outline-none" type="text" id="email" name="email" required />
//                 </div>
//                 <br />
//                 <div className="w-3/4 flex flex-col">
//                     <label htmlFor="password">Password: </label>
//                     <input onChange={handleInputChange} required className="border p-2 outline-none" type="password" id="password" name="password" />
//                 </div>
//                 <input type="submit" className="border m-4 px-4 py-2 cursor-pointer bg-cyan-400 text-white" />
//                 <div> Do you have an account already?
//                     <Link className="hover:text-cyan-400 font-semibold" to="/login"> Login </Link>
//                 </div>
//             </form>
//             {message && <p className="text-green-700 bg-green-200 px-4 py-2 absolute bottom-2">{message}</p>}
//             {error && <p className="text-red-700 bg-red-200 px-4 py-2 absolute bottom-2">{error}</p>}
//         </div>)
// }

// export default RegisterPage;
