import axios from "axios";
import React, { useEffect } from "react";
import { useState } from "react";
import { useParams } from "react-router-dom";
import { Link } from "react-router-dom";

const ActivationPage = () => {
    const { activation_token } = useParams();
    const [error, setError] = useState(false);
    console.log(activation_token)

    useEffect(() => {
        axios.get(`https://coach-mazen-server.vercel.app/auth/activate/${activation_token}`)
            .then((response) => {
                if (response.data.success) {
                    alert('Account activated successfully!');
                } else {
                    setError('Activation failed, invalid or expired token.');
                }
            })
            .catch((error) => {
                console.error('Error:', error);
                setError('Activation failed, invalid or expired token.');
            });
    }, [activation_token]);

    return (
        <div
            style={{
                width: "100%",
                height: "100vh",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
            }}
        >
            {error ? (
                <p>Your token is expired!</p>
            ) : (
                <div>
                    <p>Your account has been created suceessfully!</p>
                    <Link to="/login"><button className="p-2 bg-cyan-400 text-white">Go to login page</button></Link>
                </div>
            )}
        </div>
    );
};

export default ActivationPage;
